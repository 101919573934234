import React, { useState, useEffect } from "react";
import SliderItem from "../../home/slider/sliderItem";

const Hero = ({ featuredProjects }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (featuredProjects && featuredProjects.length > 0) {
      setProjects(featuredProjects.reverse());
    }
  }, [featuredProjects]);

  const renderSliderItems = () => {
    return projects.map((project) => {
      return <SliderItem key={project.id} project={project} />;
    });
  };

  return <>{projects.length > 0 && renderSliderItems()}</>;
};

export default Hero;
