import { Link } from "react-router-dom";
import "../../../../src/components/zeal-digital/zeal-digital.css";
import Form from "../../../components/contact-us/form";

export default function LuxuryKitchens() {
  const AttractionsData = [
    {
      title: "Unwavering Dedication to Quality",
      desc: (
        <p className="font-proximanova-light">
          We are artisans who use only the finest materials – think premium
          hardwoods, exquisite stone benchtops and top-of-the-line hardware.
          Every cooking space is carefully crafted with time-tested techniques,
          ensuring a result that is not only breathtakingly beautiful but also
          incredibly durable. Your high-end kitchen is an investment and our
          unwavering dedication to quality ensures it will endure for years to
          come, remaining a source of pride and enjoyment in your home.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64px"
          height="64px"
          viewBox="0 0 36 36"
        >
          <path
            fill="currentColor"
            d="M9 17.41V27h9.59l-2-2H11v-5.59z"
            className="clr-i-outline clr-i-outline-path-1"
          />
          <path
            fill="currentColor"
            d="M34.87 32.29L32 29.38V32H4v-4.15h2v-1.6H4V19.6h2V18H4v-6.4h2V10H4V4.41l15.94 15.85v-2.82L3.71 1.29A1 1 0 0 0 2 2v31a1 1 0 0 0 1 1h31.16a1 1 0 0 0 .71-1.71"
            className="clr-i-outline clr-i-outline-path-2"
          />
          <path
            fill="currentColor"
            d="M24 30h4a2 2 0 0 0 2-2V8.7l-2.3-4.23a2 2 0 0 0-1.76-1a2 2 0 0 0-1.76 1.08L22 8.72V28a2 2 0 0 0 2 2m0-20.8l1.94-3.77L28 9.21V24h-4Zm0 16.43h4v2.44h-4Z"
            className="clr-i-outline clr-i-outline-path-3"
          />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
      ),
    },
    {
      title: "Collaborative Design Process",
      desc: (
        <p className="font-proximanova-light">
          Our design team doesn't just listen, they collaborate. We take the
          time to understand your vision, lifestyle and culinary aspirations.
          Whether you dream of a chef's haven for elaborate creations or a warm
          and inviting space for family meals, we translate your ideas into
          detailed plans. The result? A luxury kitchen that seamlessly
          integrates with your existing space while reflecting your unique style
          and personality.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m9.925 13.277l.779-2.58l-2.05-1.505h2.562L12 6.585l.785 2.607h2.561l-2.056 1.504l.78 2.581L12 11.675zM7 21.462v-6.59q-.95-.935-1.475-2.19Q5 11.43 5 10q0-2.927 2.036-4.963T12 3t4.964 2.036T19 10q0 1.429-.525 2.683T17 14.87v6.59l-5-1.5zM12 16q2.5 0 4.25-1.75T18 10t-1.75-4.25T12 4T7.75 5.75T6 10t1.75 4.25T12 16"
          />
        </svg>
      ),
    },
    {
      title: "Unmatched Expertise",
      desc: (
        <p className="font-proximanova-light">
          Our team is composed of highly-skilled craftsmen who are not only
          passionate but possess an unparalleled depth of knowledge. They
          understand the intricacies of design principles, the properties of
          various materials and the latest trends in kitchens.This unmatched
          expertise ensures that your cooking space is not just
          aesthetically-pleasing but also functional, incorporating innovative
          features and space-saving solutions to improve your cooking
          experience.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M7.22 19.385q-.585 0-1.037-.383q-.454-.382-.554-.965l-1.287-7.702q-.063-.38.172-.665t.622-.286h13.728q.388 0 .622.286q.235.286.172.664l-1.287 7.702q-.1.584-.553.966q-.454.383-1.037.383zm-1.82-9l1.198 7.48q.039.231.202.376q.164.144.394.144h9.612q.23 0 .394-.145q.164-.144.202-.374l1.198-7.481zm4.6 3.5h4q.213 0 .356-.144q.144-.144.144-.357t-.144-.356t-.356-.144h-4q-.213 0-.356.145t-.144.356t.144.356t.356.143M6 8q-.213 0-.356-.144T5.5 7.499t.144-.356T6 7h12q.213 0 .356.144t.144.357t-.144.356T18 8zm2-2.384q-.213 0-.356-.144T7.5 5.115t.144-.356q.143-.144.356-.144h8q.213 0 .356.144t.144.357t-.144.356t-.356.144zM6.598 18.385h10.804z"
          />
        </svg>
      ),
    },
    {
      title: "Seamless Project Management",
      desc: (
        <p className="font-proximanova-light">
          From the very first design consultation to the final flawless
          installation, we handle every aspect of your luxury kitchen project
          with meticulous care. Our experienced team acts as your single point
          of contact, keeping you informed and involved throughout the process.
          Topcab Kitchens & Joinery pride ourselves on meticulous planning and
          clear communication, ensuring a smooth and stress-free experience that
          allows you to focus on the excitement of creating your dream cooking
          space.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M13.274 6.949a1.28 1.28 0 0 0-1.168-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .074.369h2.44a1.3 1.3 0 0 0 .074-.369m4.397-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .075.369h2.439a1.3 1.3 0 0 0 .074-.369a1.28 1.28 0 0 0-1.168-1.268M7.634 7.318a1.3 1.3 0 0 0 .075-.369a1.28 1.28 0 0 0-1.168-1.268V2h-.252v3.681a1.28 1.28 0 0 0-1.168 1.268a1.3 1.3 0 0 0 .074.369Zm1.976 5.629h-2.6a.9.9 0 0 0-.914.882v3.483a.88.88 0 0 0 .755.851v2.583h.312v-2.552h2.4v2.552h.312v-2.6a.88.88 0 0 0 .648-.83v-3.487a.9.9 0 0 0-.913-.882m7.996 0H15a.9.9 0 0 0-.913.882v3.483a.88.88 0 0 0 .755.851v2.583h.312v-2.552h2.4v2.552h.311v-2.6a.875.875 0 0 0 .648-.83v-3.487a.9.9 0 0 0-.907-.882M2.598 15.345h.541v5.367h.684v-5.367h2.019v-.865H2.598Zm8.175-.865h3.066v.865h-3.066zm7.997 0v.865h1.443v5.367h.684v-5.367h.505v-.865Z"
          />
        </svg>
      ),
    },
    {
      title: "Exceptional Customer Service",
      desc: (
        <p className="font-proximanova-light">
          We understand that building your dream cooking space is a significant
          investment, and we treat it as such. Exceptional customer service is
          paramount to our philosophy. Our dedicated team prioritises open
          communication and collaboration throughout the entire project. We are
          readily available to address any questions or concerns you may have,
          ensuring your complete satisfaction from the initial design stages to
          the final handover of your dream high-end kitchen.
        </p>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          >
            <path d="M17 21v-2a1 1 0 0 1-1-1v-1a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1" />
            <path d="M19 15V6.5a1 1 0 0 0-7 0v11a1 1 0 0 1-7 0V9m16 12v-2h-4M3 5h4V3" />
            <path d="M7 5a1 1 0 0 1 1 1v1a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a1 1 0 0 1 1-1V3" />
          </g>
        </svg>
      ),
    },
  ];

  const craftsmenData = [
    {
      title: "High-End Materials",
      desc: (
        <p className="font-proximanova-light">
          From premium woods and natural stone countertops to top-of-the-line
          appliances and hardware, we utilise the finest materials for
          exceptional durability, beauty and lasting value.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-1.jpg",
    },
    {
      title: "Unmatched Craftsmanship",
      desc: (
        <p className="font-proximanova-light">
          Expert{" "}
          <Link to="https://topcabjoinery.com.au/detailed-joinery">
            detailed joinery
          </Link>{" "}
          and meticulous attention to detail ensure a flawless finish and
          seamless integration of all elements.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-2.jpg",
    },
    {
      title: "Innovative Design",
      desc: (
        <p className="font-proximanova-light">
          We incorporate cutting-edge design trends, featuring sleek lines,
          integrated appliances and clever storage solutions to maximise space
          and functionality.
        </p>
      ),
      imgRef: "/zeal-digital/reach-us-2.png",
    },
    {
      title: "Personalised Touches",
      desc: (
        <p className="font-proximanova-light">
          Luxury cooking spaces are not one-size-fits-all. We work closely with
          you to understand your style and integrate bespoke elements that
          reflect your unique taste.
        </p>
      ),
      imgRef: "/zeal-digital/tv-unit-4.jpg",
    },
  ];
  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="text-white container min-vh-100 d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 align-items-center align-items-md-start justify-content-center">
            <h1 className="display-6 font-miracle text-center text-md-start">
              Get Your Luxury Kitchen Designed By Topcab Kitchens & Joinery
            </h1>
            <p className="font-proximanova-light zeal-digital text-center text-md-start">
              A luxury kitchen transcends mere functionality. It is a statement
              piece, a reflection of your discerning taste and a space designed
              to inspire culinary creativity. High-end kitchens seamlessly blend
              high-end aesthetics with exceptional functionality, creating a
              haven for both passionate home cooks and those who love to
              entertain. Topcab Kitchens & Joinery is the premier expert when it
              comes to luxury kitchens, transforming your vision into a
              masterpiece that elevates your home and enhances your lifestyle.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-2"
            >
              Call Now
            </a>
          </div>
          <div className="">
            <img
              src="/zeal-digital/joinery.png"
              className="bg-dark rounded-3"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      {/* <section className="zeal-digital">
        <div className="container">
          <div className="text-white row h-100 gy-4 align-items-stretch">
            <div
              className="col-lg-4 col-md-6 d-grid align-items-center justify-content-center bg-dark rounded-3"
              style={{ minHeight: "200px" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h2 className="font-miracle display-5">
                Attractions of A Luxury TV Unit
              </h2>
            </div>
            {AttractionsData.map((elem) => {
              return (
                <div
                  key={elem.title}
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="service-item d-flex flex-column gap-2 justify-content-center position-relative">
                    <div>{elem.icon}</div>
                    <h3 className="font-proximanova-bold">{elem.title}</h3>
                    {elem.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}

      <section className="zeal-digital container text-white">
        <h2 className="font-miracle display-5 mb-4">
          What Makes Topcab Kitchens & Joinery The Ideal Choice For Your Luxury
          Kitchen Project?
        </h2>
        <p className="font-proximanova-light">
          Several factors solidify{" "}
          <Link to="https://topcabjoinery.com.au/">
            Topcab Kitchens & Joinery
          </Link>{" "}
          as the undisputed leader in crafting bespoke high-end kitchen:
        </p>
        {AttractionsData.map((elem) => {
          return (
            <div key={elem.title}>
              <div className="service-item d-flex flex-column gap-2 justify-content-center position-relative">
                <h3 className="font-proximanova-light">{elem.title}</h3>
                {elem.desc}
              </div>
            </div>
          );
        })}
      </section>

      <section
        className="container text-white zeal-digital"
        style={{ marginTop: "5rem", marginBottom: "2rem" }}
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            The Essence Of Luxury Kitchens
          </h2>
          <p className="font-proximanova-light">
            A luxury kitchen is more than just beautiful; it is meticulously
            designed to be incredibly functional. Here's what defines a true
            luxury kitchen:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h3> {elem.title} </h3> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section
        style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        className="text-white container d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 align-items-center align-items-md-start justify-content-center">
            <h2 className="display-6 font-miracle text-center text-md-start">
              Invest In A Culinary Oasis: Contact Topcab Kitchens & Joinery
              Today
            </h2>
            <p className="font-proximanova-light zeal-digital text-center text-md-start">
              Don't settle for an ordinary cooking space! Your home deserves a
              space that inspires, excites and reflects your passion for living
              well. Topcab Kitchens & Joinery is here to create a high-end
              kitchen that surpasses your expectations. Contact us today at{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or email at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              to schedule a free consultation and discuss your vision. Browse
              our portfolio on our website to witness the exceptional quality
              and creative designs we deliver for our clients.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-2"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/reach-us-2.png"
              className="bg-dark rounded-3 ms-auto"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <Form />
    </section>
  );
}
