import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

function Title() {
  const isMobile = useWindowSize();
  const paddingTop = isMobile ? "15rem" : "20rem";
  return (
    <div id="contactUs-title" style={{ paddingTop: paddingTop }}>
      <div id="contact-us" className="container d-flex">
        <div className="col">
          <div className="row d-flex justify-content-center">
            <h1 className="h1 font-miracle color-white text-uppercase display-3 w-auto">
              Contact Us
            </h1>
          </div>
          <div className="row">
            <p className="p color-grey font-proximanova-semibold text-center">
              To get a quote. To start a project. To ask any question you have.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
