import React, { useState, useEffect } from "react";
import preloaderGif from "../../../assets/Top Cab Logo Animation/Black/B_TopCab_Logo_GIF_Loop.gif";
// import { useLocation } from "react-router-dom";
import "./preloader.css";

const Preloader = () => {
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  // const isHomePage =
  //   location.pathname === "/home" ||
  //   location.pathname === "/" ||
  //   location.pathname === "/portfolio";

  return (
    <div className={`preloader ${isLoading ? "show" : "hide"}`}>
      {FullPageLoader()}
    </div>
  );
};

const FullPageLoader = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center w-100 h-100 bg-white"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
    >
      <img
        src={preloaderGif}
        alt="Loading..."
        style={{ width: "70%", height: "auto", maxWidth: "200px" }}
      />
    </div>
  );
};

// const smallPreloader = () => {
//   return (
//     <div className="d-flex align-items-center justify-content-center w-100 h-100 bg-transparent">
//       <img src={preloaderGif} alt="Loading..." width={"auto"} height={"auto"} />
//     </div>
//   );
// };

export default Preloader;
