import React, { useEffect } from "react";
import Modal from "react-swipe-to-close-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function InstaModalMobile({
  isOpen,
  onRequestClose,
  selectedPost,
  closeModal,
}) {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);
  return (
    <Modal
      swipeOnDesktop={true}
      close={onRequestClose}
      isOpen={isOpen}
      contentLabel="Post Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: 0,
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      {selectedPost && (
        <>
          <div
            style={{
              backgroundColor: "black",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            {selectedPost.media_type === "CAROUSEL_ALBUM" ? (
              <Carousel
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                interval={3000}
                centerMode={true}
                centerSlidePercentage={100}
                width="100%"
                height="100%"
                dynamicHeight={true}
                emulateTouch={true}
                swipeable={true}
              >
                {selectedPost.children.data.map((child) => (
                  <div key={child.id}>
                    <img
                      src={child.media_url}
                      alt={selectedPost.caption}
                      className="img-fluid"
                      style={{ width: "100%", maxHeight: "auto" }}
                    />
                  </div>
                ))}
              </Carousel>
            ) : selectedPost.media_type === "IMAGE" ? (
              <img
                src={selectedPost.media_url}
                alt={selectedPost.caption}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            ) : selectedPost.media_type === "VIDEO" ? (
              <video
                autoPlay
                muted
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={selectedPost.media_url} type="video/mp4" />
                <p className="color-black1">
                  Your browser does not support the video tag.
                </p>
              </video>
            ) : null}
          </div>
        </>
      )}
      <button
        onClick={closeModal}
        style={{
          backgroundColor: "transparent",
          border: "none",
          position: "absolute",
          top: "10px",
          right: "5%",
          cursor: "pointer",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </Modal>
  );
}

export default InstaModalMobile;
