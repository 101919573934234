import React, { useState, useEffect } from "react";
import ProjectsGrid1 from "../commons/grids/projectGrid1";
import ProjectsGrid2 from "../commons/grids/projectGrid2";
import Preloader from "../commons/preloader/preloader";
import { Container, Col, Row } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";
import locationImage from "../../assets/images/utils/location.png";
import { useNavigate } from "react-router-dom";
import { useApiUrl } from "../../hooks/useApiUrl";

function Main() {
  return useWindowSize() ? <MainMobile /> : <MainDesktop />;
}

function MainDesktop() {
  const [projects, setProjects] = useState([]);
  const apiUrls = useApiUrl();
  const currentProjectUrl = apiUrls.getCurrentProjects;
  useEffect(() => {
    fetch(currentProjectUrl)
      .then((res) => res.json())
      .then((data) => {
        setProjects(data.reverse());
      });
  }, [currentProjectUrl]);

  const renderGrids = () => {
    const grids = [];
    for (let i = 0; i < projects.length; i += 3) {
      const slicedProjects = projects.slice(i, i + 3);
      if (i % 6 === 0) {
        grids.push(<ProjectsGrid1 key={i} projects={slicedProjects} />);
      } else {
        grids.push(<ProjectsGrid2 key={i} projects={slicedProjects} />);
      }
    }
    return grids;
  };
  return (
    <div id="portfolio">
      <div class="container" style={{ marginTop: "20rem" }}>
        <div class="d-flex align-items-center justify-content-center border border-black border-top-0 border-start-0 border-end-0 pb-3 mb-4">
          <h1 className="h1 color-black1 font-miracle text-uppercase display-1">
            portfolio
          </h1>
        </div>
      </div>
      <div className="container d-grid align-items-center justify-content-center my-5">
        {projects.length > 0 ? renderGrids() : <Preloader />}
      </div>
    </div>
  );
}

function MainMobile() {
  const [projects, setProjects] = useState([]);
  const [isGrid, setIsGrid] = useState(false);
  const [isFlex, setIsFlex] = useState(true);
  const apiUrls = useApiUrl();
  const currentProjectUrl = apiUrls.getCurrentProjects;
  const { REACT_APP_ASSETS_PROJECTS_URL: assetsProjectsUrl } = process.env;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(currentProjectUrl)
      .then((res) => res.json())
      .then((data) => {
        setProjects(data.reverse());
      });
  }, [currentProjectUrl]);

  const renderGrids = () => {
    const grids = [];
    for (let i = 0; i < projects.length; i += 2) {
      const slicedProjects = projects.slice(i, i + 2);
      grids.push(
        <Row key={i} className="my-3">
          {slicedProjects.map((project, index) => (
            <Col key={index} className="d-flex flex-column">
              {renderProjectCard(project)}
            </Col>
          ))}
        </Row>
      );
    }
    return grids;
  };

  const renderFlex = () => {
    return projects.map((project, index) => (
      <Row key={index} className="d-flex justify-content-center my-3">
        <Col>{renderProjectCard(project)}</Col>
      </Row>
    ));
  };

  const renderProjectCard = (project) => {
    return (
      <div>
        <img
          src={assetsProjectsUrl + project.portfolioThumbnail.url}
          onClick={() => navigate(`/portfolio/${project.id}`)}
          className="img-fluid"
          loading="lazy"
          alt={project.portfolioThumbnail.id}
          style={{
            height: isGrid ? "210px" : "440px",
            objectFit: "cover",
            width: "100%",
          }}
        />

        <h3 className="font-proximanova-semibold color-black1 mt-3">
          {project.name}
        </h3>
        <div className="d-flex">
          <img
            src={locationImage}
            alt="location"
            className="w-auto me-2"
            style={{ width: "12px", height: "17px" }}
          />
          <p className="font-proximanova-light color-grey p">
            {project.location ? project.location : "No location"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Container id="portfolio">
      <div className="container" style={{ marginTop: "10rem" }}>
        <div className="d-flex align-items-center justify-content-center border border-black border-top-0 border-start-0 border-end-0 pb-3 mb-4">
          <h1 className="h1 color-black1 font-miracle text-uppercase display-1">
            portfolio
          </h1>
        </div>
        <Row>
          <Col className="d-flex justify-content-end gap-3">
            <button
              className="btn p-0"
              onClick={() => {
                setIsGrid(true);
                setIsFlex(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
              >
                <g
                  id="Group_777"
                  data-name="Group 777"
                  transform="translate(-345 -277)"
                >
                  <rect
                    id="Rectangle_81"
                    data-name="Rectangle 81"
                    width="4"
                    height="11"
                    transform="translate(345 277)"
                    fill={isGrid ? "#000" : "#bbb"}
                  />
                  <rect
                    id="Rectangle_82"
                    data-name="Rectangle 82"
                    width="4"
                    height="11"
                    transform="translate(352 277)"
                    fill={isGrid ? "#000" : "#bbb"}
                  />
                </g>
              </svg>
            </button>
            <button
              className="btn p-0"
              onClick={() => {
                setIsGrid(false);
                setIsFlex(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
              >
                <rect
                  id="Rectangle_80"
                  data-name="Rectangle 80"
                  width="11"
                  height="11"
                  fill={isFlex ? "#000" : "#bbb"}
                />
              </svg>
            </button>
          </Col>
        </Row>
      </div>
      <Container className="container d-grid align-items-center justify-content-center mt-1 mb-5">
        {projects.length > 0 ? (
          isGrid ? (
            renderGrids()
          ) : isFlex ? (
            renderFlex()
          ) : (
            <ProjectsGrid1 projects={projects} />
          )
        ) : (
          <Preloader />
        )}
      </Container>
    </Container>
  );
}

export default Main;
