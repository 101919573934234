import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomJoineryNorthernBeaches() {
  const areasWeServe = [
    {
      title: "surry hills",
      href: "/custom-joinery-surry-hills",
    },
    // {
    //   title: "northern beaches",
    //   href: "/custom-joinery-northern-beaches",
    // },
    { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    { title: "double bay", href: "/custom-joinery-double-bay" },
    { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Topcab Kitchens & Joinery: The Best Services For Custom Joinery
                On The Northern Beaches
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                <Link to="https://topcabjoinery.com.au/">
                  Topcab Kitchens & Joinery
                </Link>{" "}
                is your go-to destination for premium custom joinery and custom
                wardrobes around the Northern Beaches. We specialise in
                transforming your spaces with our expert craftsmanship, bespoke
                designs and dedication to quality. Whether you're looking for
                custom joinery, kitchen joinery or custom wardrobes, our team is
                here to bring your vision to life on the Northern Beaches.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212436.5404273066!2d151.1202387540339!3d-33.70064326608096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d54fe57ff8d69%3A0x8394225c7c9a5aaa!2sNorthern%20Beaches%2C%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012398027!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section
          className="container text-white zeal-digital"
          style={{ marginTop: "5rem", marginBottom: "2rem" }}
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3">
              Delivering Excellence
            </h2>
            <p className="font-proximanova-light zeal-digital text-center">
              Since 2020, we pride ourselves on delivering unparalleled services
              for custom joinery on the Northern Beaches. Our team of skilled
              craftsmen brings years of experience and a passion for excellence
              to every project. We understand that your home is a reflection of
              your personality and lifestyle and we are dedicated to creating
              spaces that are both functional and beautiful.
            </p>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Our Services Around The Northern Beaches
          </h2>
          <h3> Custom Joinery </h3>{" "}
          <p>
            Custom joinery is at the heart of what we do. We believe that every
            piece of furniture and every fixture in your home should be a
            perfect fit for your needs and style. From bespoke cabinetry to
            unique storage solutions, our custom joinery is designed to enhance
            your living spaces with precision and creativity. We collaborate
            closely with you to fully grasp your needs and preferences, ensuring
            that the finished result fulfils your every expectation for your
            Northern Beaches home.
          </p>
          <h3> Kitchen Joinery </h3>{" "}
          <p>
            The kitchen is the place where a good day starts. At Topcab Kitchens
            & Joinery, we specialise in creating kitchens across the Northern
            Beaches that are not only functional but also a joy to cook and
            entertain in. Our kitchen joinery is tailored to meet your specific
            needs, whether you're looking for a complete kitchen makeover or
            just a few custom pieces. We use the highest quality materials and
            the latest design trends to create kitchens on the Northern Beaches
            that are both stylish and practical.
          </p>
          <h3 className="h3"> Custom Wardrobes </h3>{" "}
          <p>
            Your home can be significantly improved by a well-designed wardrobe.
            Our custom wardrobes on the Northern Beaches are designed to
            maximise your storage space while reflecting your personal style.
            Whether you need a walk in wardrobe, built-in wardrobes or sliding
            door wardrobes, we have the expertise to create solutions that are
            tailored to your needs. Not just that; even if you're looking for a
            sleek modern design or a more traditional style, we have the perfect
            wardrobe solution for you. Our custom wardrobes around the Northern
            Beaches are expertly crafted with the finest materials, paying close
            attention to every last detail, to provide you with a stylish and
            useful storage option.
          </p>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center order-1">
              <h2 className="font-miracle mb-3">
                Why Topcab Kitchens & Joinery?
              </h2>
              <ul className="font-proximanova-light d-flex flex-column gap-2 zeal-digital">
                <li>
                  <strong>Experience</strong>: With years of experience in the joinery industry,
                  we have the knowledge and expertise to handle any project
                  anywhere in Sydney whether in{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-vaucluse">
                    Vaucluse
                  </Link>{" "}
                  or in{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                    Double Bay
                  </Link>
                  .
                </li>
                <li>
                  <strong>Quality</strong>: We use only the finest materials and craftsmanship to
                  ensure that every piece of joinery is built to last.
                </li>
                <li>
                  <strong>Customisation</strong>: Every project is tailored to your specific
                  needs and preferences, ensuring a perfect fit for your home.
                  We have completed over 50 unique projects till date!
                </li>
                <li>
                  <strong>Customer Service</strong>: Great customer service is what we ensure to
                  deliver, starting with the first consultation to the last
                  installation.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center order-0">
              <img
                src="/zeal-digital/wardrobe-coogee.jpg"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle displa mb-3 text-center">Our Process</h2>
          <p className=" font-proximanova-light">
            Topcab Kitchens & Joinery believes that every project is a
            collaboration between our team and our clients. Our process is
            designed to ensure that you are involved at every stage, from the
            initial consultation to the final installation. Here's how our
            process goes
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Consultation</strong>: We begin with a thorough consultation to determine
              your needs, preferences and budget. This enables us to design a
              plan specifically tailored to your needs.
            </li>
            <li>
              <strong>Design</strong>: Our team of designers will create detailed plans and 3D
              renderings of your project, allowing you to visualise the final
              product. We work closely with you to make any necessary
              adjustments and ensure that the design is perfect.
            </li>
            <li>
              <strong>Production</strong>: Once the design is approved, our skilled craftsmen get
              to work, using the highest quality materials and the latest
              techniques to create your custom joinery on the Northern Beaches.
            </li>
            <li>
              <strong>Installation</strong>: Our installation team will ensure that your new
              joinery is fitted perfectly, with minimal disruption to your home.
              We take care of every detail, leaving you with a finished product
              that exceeds your expectations.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">Reach Out To Us</h2>
              <p className="font-proximanova-light zeal-digital">
                Are you prepared to make the most of custom joinery to transform
                your Northern Beaches home? To arrange a consultation, give
                Topcab Kitchens & Joinery a call right now at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                . Together, we will design stunning, useful rooms that you will
                like for many years to come.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Topcab Kitchens & Joinery: The Best Services For Custom Joinery
                On The Northern Beaches
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                <Link to="https://topcabjoinery.com.au/">
                  Topcab Kitchens & Joinery
                </Link>{" "}
                is your go-to destination for premium custom joinery and custom
                wardrobes around the Northern Beaches. We specialise in
                transforming your spaces with our expert craftsmanship, bespoke
                designs and dedication to quality. Whether you're looking for
                custom joinery, kitchen joinery or custom wardrobes, our team is
                here to bring your vision to life on the Northern Beaches.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212436.5404273066!2d151.1202387540339!3d-33.70064326608096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d54fe57ff8d69%3A0x8394225c7c9a5aaa!2sNorthern%20Beaches%2C%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012398027!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
        <section
          className="container text-white zeal-digital"
          style={{ marginTop: "5rem", marginBottom: "2rem" }}
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3">
              Delivering Excellence
            </h2>
            <p className="font-proximanova-light zeal-digital text-center">
              Since 2020, we pride ourselves on delivering unparalleled services
              for custom joinery on the Northern Beaches. Our team of skilled
              craftsmen brings years of experience and a passion for excellence
              to every project. We understand that your home is a reflection of
              your personality and lifestyle and we are dedicated to creating
              spaces that are both functional and beautiful.
            </p>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Our Services Around The Northern Beaches
          </h2>
          <h3> Custom Joinery </h3>{" "}
          <p>
            Custom joinery is at the heart of what we do. We believe that every
            piece of furniture and every fixture in your home should be a
            perfect fit for your needs and style. From bespoke cabinetry to
            unique storage solutions, our custom joinery is designed to enhance
            your living spaces with precision and creativity. We collaborate
            closely with you to fully grasp your needs and preferences, ensuring
            that the finished result fulfils your every expectation for your
            Northern Beaches home.
          </p>
          <h3> Kitchen Joinery </h3>{" "}
          <p>
            The kitchen is the place where a good day starts. At Topcab Kitchens
            & Joinery, we specialise in creating kitchens across the Northern
            Beaches that are not only functional but also a joy to cook and
            entertain in. Our kitchen joinery is tailored to meet your specific
            needs, whether you're looking for a complete kitchen makeover or
            just a few custom pieces. We use the highest quality materials and
            the latest design trends to create kitchens on the Northern Beaches
            that are both stylish and practical.
          </p>
          <h3 className="h3"> Custom Wardrobes </h3>{" "}
          <p>
            Your home can be significantly improved by a well-designed wardrobe.
            Our custom wardrobes on the Northern Beaches are designed to
            maximise your storage space while reflecting your personal style.
            Whether you need a walk in wardrobe, built-in wardrobes or sliding
            door wardrobes, we have the expertise to create solutions that are
            tailored to your needs. Not just that; even if you're looking for a
            sleek modern design or a more traditional style, we have the perfect
            wardrobe solution for you. Our custom wardrobes around the Northern
            Beaches are expertly crafted with the finest materials, paying close
            attention to every last detail, to provide you with a stylish and
            useful storage option.
          </p>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center order-1">
              <h2 className="font-miracle mb-3 text-center">
                Why Topcab Kitchens & Joinery?
              </h2>
              <ul className="font-proximanova-light d-flex flex-column gap-2 zeal-digital">
                <li>
                  <strong>Experience</strong>: With years of experience in the joinery industry,
                  we have the knowledge and expertise to handle any project
                  anywhere in Sydney whether in{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-vaucluse">
                    Vaucluse
                  </Link>{" "}
                  or in{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                    Double Bay
                  </Link>
                  .
                </li>
                <li>
                  <strong>Quality</strong>: We use only the finest materials and craftsmanship to
                  ensure that every piece of joinery is built to last.
                </li>
                <li>
                  <strong>Customisation</strong>: Every project is tailored to your specific
                  needs and preferences, ensuring a perfect fit for your home.
                  We have completed over 50 unique projects till date!
                </li>
                <li>
                  <strong>Customer Service</strong>: Great customer service is what we ensure to
                  deliver, starting with the first consultation to the last
                  installation.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center mx-auto call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center order-0">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle displa mb-3 text-center">Our Process</h2>
          <p className=" font-proximanova-light">
            Topcab Kitchens & Joinery believes that every project is a
            collaboration between our team and our clients. Our process is
            designed to ensure that you are involved at every stage, from the
            initial consultation to the final installation. Here's how our
            process goes
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Consultation</strong>: We begin with a thorough consultation to determine
              your needs, preferences and budget. This enables us to design a
              plan specifically tailored to your needs.
            </li>
            <li>
              <strong>Design</strong>: Our team of designers will create detailed plans and 3D
              renderings of your project, allowing you to visualise the final
              product. We work closely with you to make any necessary
              adjustments and ensure that the design is perfect.
            </li>
            <li>
              <strong>Production</strong>: Once the design is approved, our skilled craftsmen get
              to work, using the highest quality materials and the latest
              techniques to create your custom joinery on the Northern Beaches.
            </li>
            <li>
              <strong>Installation</strong>: Our installation team will ensure that your new
              joinery is fitted perfectly, with minimal disruption to your home.
              We take care of every detail, leaving you with a finished product
              that exceeds your expectations.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Reach Out To Us
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
              Are you prepared to make the most of custom joinery to transform
                your Northern Beaches home? To arrange a consultation, give
                Topcab Kitchens & Joinery a call right now at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                . Together, we will design stunning, useful rooms that you will
                like for many years to come.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
