import React from "react";
import Title from "../components/contact-us/title";
import Form from "../components/contact-us/form";

const ContactUs = () => {
  return (
    <>
      <Title />
      <Form />
    </>
  );
};

export default ContactUs;
