import React, { useEffect, useState } from "react";
import Logo from "../assets/Top Cab Logo Animation/White/W_TopCab_Logo_GIF_Loop.gif";
import "../styles/landingPage.css";

const LandingPage = () => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`landing-page ${fadeOut ? "fade-out" : ""}`}>
      <img src={Logo} alt="Logo" className="landing-logo" />
    </div>
  );
};

export default LandingPage;
