import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function OGTags() {
  const location = useLocation();
  const curPath = location.pathname;
  switch (curPath) {
    case "/": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Topcab Joinery | Custom Kitchens & Joinery Services In Sydney
          </title>
          <meta
            name="description"
            content="Discover premium custom joinery solutions at Topcab Kitchens & Joinery in Sydney. Our expert craftsmen specialise in bespoke kitchens, & joinery services. Contact us!"
          />
          <meta
            property="og:title"
            content="Topcab Joinery | Custom Kitchens & Joinery Services In Sydney"
          />
          <meta
            property="og:description"
            content="Discover premium custom joinery solutions at Topcab Kitchens & Joinery in Sydney. Our expert craftsmen specialise in bespoke kitchens, & joinery services. Contact us!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Topcab Joinery | Custom Kitchens & Joinery Services In Sydney"
          />
          <meta
            name="twitter:description"
            content="Discover premium custom joinery solutions at Topcab Kitchens & Joinery in Sydney. Our expert craftsmen specialise in bespoke kitchens, & joinery services. Contact us!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/contact-us": {
      return (
        <Helmet>
          <meta
            property="og:url"
            content="https://topcabjoinery.com.au/contact-us"
          />
          <meta property="og:type" content="website" />
          <title>Contact Us | Get Expert Assistance | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Contact Us | Get Expert Assistance | Topcab Joinery"
          />
          <meta
            name="description"
            content="Contact us today to transform your interiors to new heights with Topcab Joinery. We provide expert end-to-end assistance in home development fittings."
          />
          <meta
            property="og:description"
            content="Contact us today to transform your interiors to new heights with Topcab Joinery. We provide expert end-to-end assistance in home development fittings."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta
            property="twitter:url"
            content="https://topcabjoinery.com.au/contact-us"
          />
          <meta
            name="twitter:title"
            content="Contact Us | Get Expert Assistance | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Contact us today to transform your interiors to new heights with Topcab Joinery. We provide expert end-to-end assistance in home development fittings."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/portfolio": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Check Our Portfolio | Work We Have Done | Topcab Joinery
          </title>
          <meta
            name="description"
            content="Check out our portfolio for the latest work we have done at Topcab Joinery. Transform your interiors with superior-quality fittings. Call us now."
          />
          <meta
            property="og:title"
            content="Check Our Portfolio | Work We Have Done | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Check out our portfolio for the latest work we have done at Topcab Joinery. Transform your interiors with superior-quality fittings. Call us now."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Check Our Portfolio | Work We Have Done | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Check out our portfolio for the latest work we have done at Topcab Joinery. Transform your interiors with superior-quality fittings. Call us now."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/about-us": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>About Us | What You Should Know | Topcab Joinery</title>
          <meta
            name="description"
            content="Topcab Joinery is the trusted firm for carefully-handcrafted fittings for your home. Check out the About Us page for insights into our journey & our mission."
          />
          <meta
            property="og:title"
            content="About Us | What You Should Know | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Topcab Joinery is the trusted firm for carefully-handcrafted fittings for your home. Check out the About Us page for insights into our journey & our mission."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="About Us | What You Should Know | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Topcab Joinery is the trusted firm for carefully-handcrafted fittings for your home. Check out the About Us page for insights into our journey & our mission."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/portfolio/30": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Our Best Work At Burns Bay Residence | Topcab Joinery</title>
          <meta
            name="description"
            content="Find our best work at Burns Bay Residence project. Take a quick peek into the intricate spaces of our satisfied customer. Visit us today to know more."
          />
          <meta
            property="og:title"
            content="Our Best Work At Burns Bay Residence | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Find our best work at Burns Bay Residence project. Take a quick peek into the intricate spaces of our satisfied customer. Visit us today to know more."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Our Best Work At Burns Bay Residence | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Find our best work at Burns Bay Residence project. Take a quick peek into the intricate spaces of our satisfied customer. Visit us today to know more."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/portfolio/29": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Creative Showcase Of Kyle Bay Residence | Topcab Joinery
          </title>
          <meta
            name="description"
            content="Check the creative showcase of our Kyle Bay Residence project. Find the top creations from skilled artisans at Topcab Joinery. Contact us today."
          />
          <meta
            property="og:title"
            content="Creative Showcase Of Kyle Bay Residence | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Check the creative showcase of our Kyle Bay Residence project. Find the top creations from skilled artisans at Topcab Joinery. Contact us today."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Creative Showcase Of Kyle Bay Residence | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Check the creative showcase of our Kyle Bay Residence project. Find the top creations from skilled artisans at Topcab Joinery. Contact us today."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/portfolio/28": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Work Highlights Of Gennet Street Residence | Topcab Joinery
          </title>
          <meta
            name="description"
            content="Explore the portfolio of Topcab Joinery where we highlight our Gennet Street Residence project. Call us now for posh furnishings under your budget."
          />
          <meta
            property="og:title"
            content="Work Highlights Of Gennet Street Residence | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Explore the portfolio of Topcab Joinery where we highlight our Gennet Street Residence project. Call us now for posh furnishings under your budget."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Work Highlights Of Gennet Street Residence | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Explore the portfolio of Topcab Joinery where we highlight our Gennet Street Residence project. Call us now for posh furnishings under your budget."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/services": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Services Provided | What Do We Offer | Topcab Joinery</title>
          <meta
            name="description"
            content="Browse our services to find out what do we offer at Topcab Joinery. Get in touch with us to see how we can transform your home with our many services."
          />
          <meta
            property="og:title"
            content="Services Provided | What Do We Offer | Topcab Joinery"
          />
          <meta
            property="og:description"
            content="Browse our services to find out what do we offer at Topcab Joinery. Get in touch with us to see how we can transform your home with our many services."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Services Provided | What Do We Offer | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Browse our services to find out what do we offer at Topcab Joinery. Get in touch with us to see how we can transform your home with our many services."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/privacy-policy": {
      return (
        <Helmet>
          <meta
            property="og:url"
            content="https://topcabjoinery.com.au/privacy-policy"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Topcab Joinery" />
          <meta property="og:description" content="Topcab Joinery" />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta
            property="twitter:url"
            content="https://topcabjoinery.com.au/privacy-policy"
          />
          <meta name="twitter:title" content="Topcab Joinery" />
          <meta name="twitter:description" content="Topcab Joinery" />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/terms-and-conditions": {
      return (
        <Helmet>
          <meta
            property="og:url"
            content="https://topcabjoinery.com.au/terms-and-conditions"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Topcab Joinery" />
          <meta property="og:description" content="Topcab Joinery" />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta
            property="twitter:url"
            content="https://topcabjoinery.com.au/terms-and-conditions"
          />
          <meta name="twitter:title" content="Topcab Joinery" />
          <meta name="twitter:description" content="Topcab Joinery" />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/service-areas": {
      return (
        <Helmet>
          <meta
            property="og:url"
            content="https://topcabjoinery.com.au/terms-and-conditions"
          />
          <meta property="og:type" content="website" />
          <title>Service Area | We are Near You | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Service Area | We are Near You | Topcab Joinery"
          />
          <meta
            name="description"
            content="Enjoy the vast services of Topcab Joinery near your location. We are expanding our service areas throughout the country. Yes, we are near you, so call us today!"
          />
          <meta
            property="og:description"
            content="Enjoy the vast services of Topcab Joinery near your location. We are expanding our service areas throughout the country. Yes, we are near you, so call us today!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta
            property="twitter:url"
            content="https://topcabjoinery.com.au/terms-and-conditions"
          />
          <meta
            name="twitter:title"
            content="Service Area | We are Near You | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Enjoy the vast services of Topcab Joinery near your location. We are expanding our service areas throughout the country. Yes, we are near you, so call us today!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-eastern-suburbs": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Custom Joinery eastern Suburbs | Shop Fittings in Eastern Suburbs
          </title>
          <meta
            property="og:title"
            content="Custom Joinery eastern Suburbs | Shop Fittings in Eastern Suburbs"
          />
          <meta
            name="description"
            content="Need custom cabinets in the Eastern Suburbs? Get your shop fittings around the Eastern Suburbs from Topcab Joinery.  Get custom joinery & bathroom vanities."
          />
          <meta
            property="og:description"
            content="Need custom cabinets in the Eastern Suburbs? Get your shop fittings around the Eastern Suburbs from Topcab Joinery.  Get custom joinery & bathroom vanities."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Custom Joinery eastern Suburbs | Shop Fittings in Eastern Suburbs"
          />
          <meta
            name="twitter:description"
            content="Need custom cabinets in the Eastern Suburbs? Get your shop fittings around the Eastern Suburbs from Topcab Joinery.  Get custom joinery & bathroom vanities."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-brookvale": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Wardrobes And Bathroom Vanity In Brookvale | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Wardrobes And Bathroom Vanity In Brookvale | Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for a stylish bathroom vanity in Brookvale? Visit Topcab Joinery and browse premium vanity designs and decorated wardrobes in Brookvale. Call us now!"
          />
          <meta
            property="og:description"
            content="Looking for a stylish bathroom vanity in Brookvale? Visit Topcab Joinery and browse premium vanity designs and decorated wardrobes in Brookvale. Call us now!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Wardrobes And Bathroom Vanity In Brookvale | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for a stylish bathroom vanity in Brookvale? Visit Topcab Joinery and browse premium vanity designs and decorated wardrobes in Brookvale. Call us now!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-double-bay": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Bathroom Vanity In Double Bay | Shop Fittings In Double Bay
          </title>
          <meta
            property="og:title"
            content="Bathroom Vanity In Double Bay | Shop Fittings In Double Bay"
          />
          <meta
            name="description"
            content="Get Shop fittings in Double Bay from our experts at Topcab Joinery. We provide premium bathroom vanities in Double Bay. Checkout our wardrobes. Call us."
          />
          <meta
            property="og:description"
            content="Get Shop fittings in Double Bay from our experts at Topcab Joinery. We provide premium bathroom vanities in Double Bay. Checkout our wardrobes. Call us."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Bathroom Vanity In Double Bay | Shop Fittings In Double Bay"
          />
          <meta
            name="twitter:description"
            content="Get Shop fittings in Double Bay from our experts at Topcab Joinery. We provide premium bathroom vanities in Double Bay. Checkout our wardrobes. Call us."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-surry-hills": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Bathroom Vanity Around Surry Hills | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Bathroom Vanity Around Surry Hills | Topcab Joinery"
          />
          <meta
            name="description"
            content="In search for space-saving wardrobes in Surry Hills? Choose Topcab Joinery for best quality bathroom vanities or shop fittings around Surry Hills. Call us."
          />
          <meta
            property="og:description"
            content="In search for space-saving wardrobes in Surry Hills? Choose Topcab Joinery for best quality bathroom vanities or shop fittings around Surry Hills. Call us."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Bathroom Vanity Around Surry Hills | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="In search for space-saving wardrobes in Surry Hills? Choose Topcab Joinery for best quality bathroom vanities or shop fittings around Surry Hills. Call us."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-northern-beaches": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Kitchen Joinery In Northern Beaches | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Kitchen Joinery In Northern Beaches | Topcab Joinery"
          />
          <meta
            name="description"
            content="Build quality custom wardrobes on the Northern Beaches with Topcab Joinery. We provide top tier custom & kitchen joinery near you. Contact us today!"
          />
          <meta
            property="og:description"
            content="Build quality custom wardrobes on the Northern Beaches with Topcab Joinery. We provide top tier custom & kitchen joinery near you. Contact us today!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Kitchen Joinery In Northern Beaches | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Build quality custom wardrobes on the Northern Beaches with Topcab Joinery. We provide top tier custom & kitchen joinery near you. Contact us today!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/custom-joinery-vaucluse": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Custom Joinery In Vaucluse | Wardrobes Vaucluse | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Custom Joinery In Vaucluse | Wardrobes Vaucluse | Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for walk-in robes & custom joinery in Vaucluse? Visit Topcab Joinery for wardrobes in Vaucluse created by our experts. Contact us today."
          />
          <meta
            property="og:description"
            content="Looking for walk-in robes & custom joinery in Vaucluse? Visit Topcab Joinery for wardrobes in Vaucluse created by our experts. Contact us today."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Custom Joinery In Vaucluse | Wardrobes Vaucluse | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for walk-in robes & custom joinery in Vaucluse? Visit Topcab Joinery for wardrobes in Vaucluse created by our experts. Contact us today."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/tv-units": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Luxury TV Unit Online | Entertainment Units | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Luxury TV Unit Online | Entertainment Units | Topcab Joinery"
          />
          <meta
            name="description"
            content="Buy our feature-loaded luxury TV units online at affordable prices. Visit Topcab Joinery for designer units built with premium materials only. Call us today."
          />
          <meta
            property="og:description"
            content="Buy our feature-loaded luxury TV units online at affordable prices. Visit Topcab Joinery for designer units built with premium materials only. Call us today."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Luxury TV Unit Online | Entertainment Units | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Buy our feature-loaded luxury TV units online at affordable prices. Visit Topcab Joinery for designer units built with premium materials only. Call us today."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/detailed-joinery": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Professional Detailed Joinery | Visit Us At Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Professional Detailed Joinery | Visit Us At Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for professional detailed joinery solutions near you? Visit Topcab Joinery for quality crafted pieces at affordable rates. Contact us to learn more!"
          />
          <meta
            property="og:description"
            content="Looking for professional detailed joinery solutions near you? Visit Topcab Joinery for quality crafted pieces at affordable rates. Contact us to learn more!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Professional Detailed Joinery | Visit Us At Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for professional detailed joinery solutions near you? Visit Topcab Joinery for quality crafted pieces at affordable rates. Contact us to learn more!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/luxury-kitchens": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Luxury Kitchens | High End Kitchen Designs | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Luxury Kitchens | High End Kitchen Designs | Topcab Joinery"
          />
          <meta
            name="description"
            content="Build a high end kitchen on a budget without compromising elegance. Visit Topcab Joinery and let us build your dream luxury kitchen. Call us for a quote."
          />
          <meta
            property="og:description"
            content="Build a high end kitchen on a budget without compromising elegance. Visit Topcab Joinery and let us build your dream luxury kitchen. Call us for a quote."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Luxury Kitchens | High End Kitchen Designs | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Build a high end kitchen on a budget without compromising elegance. Visit Topcab Joinery and let us build your dream luxury kitchen. Call us for a quote."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/wardrobes-rose-bay": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Quality Wardrobes In Rose Bay | Visit Topcab Joinery</title>
          <meta
            property="og:title"
            content="Quality Wardrobes In Rose Bay | Visit Topcab Joinery"
          />
          <meta
            name="description"
            content="Select carefully-crafted quality wardrobes in Rose Bay that are built to last. Visit Topcab Joinery - we turn high-end materials into art. Contact us."
          />
          <meta
            property="og:description"
            content="Select carefully-crafted quality wardrobes in Rose Bay that are built to last. Visit Topcab Joinery - we turn high-end materials into art. Contact us."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Quality Wardrobes In Rose Bay | Visit Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Select carefully-crafted quality wardrobes in Rose Bay that are built to last. Visit Topcab Joinery - we turn high-end materials into art. Contact us."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/wardrobes-coogee": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Luxurious Wardrobes Around Coogee | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Luxurious Wardrobes Around Coogee | Topcab Joinery"
          />
          <meta
            name="description"
            content="In search of sturdy and luxurious wardrobes around Coogee? Visit Topcab Joinery for functional handcrafted wardrobes within your desired budget. Contact us!"
          />
          <meta
            property="og:description"
            content="In search of sturdy and luxurious wardrobes around Coogee? Visit Topcab Joinery for functional handcrafted wardrobes within your desired budget. Contact us!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Luxurious Wardrobes Around Coogee | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="In search of sturdy and luxurious wardrobes around Coogee? Visit Topcab Joinery for functional handcrafted wardrobes within your desired budget. Contact us!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/wardrobes-maroubra": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Versatile Range Of Wardrobes In Maroubra | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Versatile Range Of Wardrobes In Maroubra | Topcab Joinery"
          />
          <meta
            name="description"
            content="Find a versatile range of wardrobes in Maroubra to beautify your home. Call us now and begin transforming your living spaces with Topcab Joinery."
          />
          <meta
            property="og:description"
            content="Find a versatile range of wardrobes in Maroubra to beautify your home. Call us now and begin transforming your living spaces with Topcab Joinery."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Versatile Range Of Wardrobes In Maroubra | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Find a versatile range of wardrobes in Maroubra to beautify your home. Call us now and begin transforming your living spaces with Topcab Joinery."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/wardrobes-bellevue-hill": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Premium Wardrobes In Bellevue Hill | Visit Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Premium Wardrobes In Bellevue Hill | Visit Topcab Joinery"
          />
          <meta
            name="description"
            content="Choose custom-crafted wardrobes in Bellevue Hill from Topcab Joinery at affordable rates. We offer premier wardrobes that enhance your home. Call us."
          />
          <meta
            property="og:description"
            content="Choose custom-crafted wardrobes in Bellevue Hill from Topcab Joinery at affordable rates. We offer premier wardrobes that enhance your home. Call us."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Premium Wardrobes In Bellevue Hill | Visit Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Choose custom-crafted wardrobes in Bellevue Hill from Topcab Joinery at affordable rates. We offer premier wardrobes that enhance your home. Call us."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/wardrobes-wetherill-park": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Designer Wardrobes In Wetherill Park | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Designer Wardrobes In Wetherill Park | Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for designer wardrobes in Wetherill Park? Get in touch with Topcab Joinery for bespoke home improvement in an affordable price range."
          />
          <meta
            property="og:description"
            content="Looking for designer wardrobes in Wetherill Park? Get in touch with Topcab Joinery for bespoke home improvement in an affordable price range."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Designer Wardrobes In Wetherill Park | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for designer wardrobes in Wetherill Park? Get in touch with Topcab Joinery for bespoke home improvement in an affordable price range."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/cabinets-mona-vale": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Best Quality Cabinet Makers In Mona Vale | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Best Quality Cabinet Makers In Mona Vale | Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for the best quality cabinet makers in Mona Vale? Visit Topcab Joinery for professionally-crafted fittings. Get in touch with us today for a quote."
          />
          <meta
            property="og:description"
            content="Looking for the best quality cabinet makers in Mona Vale? Visit Topcab Joinery for professionally-crafted fittings. Get in touch with us today for a quote."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Best Quality Cabinet Makers In Mona Vale | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for the best quality cabinet makers in Mona Vale? Visit Topcab Joinery for professionally-crafted fittings. Get in touch with us today for a quote."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/kitchens-renovations-woollahra": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Cabinet Makers In Woollahra | Kitchen Companies In Woollahra
          </title>
          <meta
            property="og:title"
            content="Cabinet Makers In Woollahra | Kitchen Companies In Woollahra"
          />
          <meta
            name="description"
            content="Searching for quality cabinet makers in Woollahra? Topcab Joinery is among the best cabinet & kitchen companies in Woollahra. Explore our exclusive designs."
          />
          <meta
            property="og:description"
            content="Searching for quality cabinet makers in Woollahra? Topcab Joinery is among the best cabinet & kitchen companies in Woollahra. Explore our exclusive designs."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Cabinet Makers In Woollahra | Kitchen Companies In Woollahra"
          />
          <meta
            name="twitter:description"
            content="Searching for quality cabinet makers in Woollahra? Topcab Joinery is among the best cabinet & kitchen companies in Woollahra. Explore our exclusive designs."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/joinery-bondi": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>
            Joinery In Bondi | Get Wardrobes In Bondi | Topcab Joinery
          </title>
          <meta
            property="og:title"
            content="Joinery In Bondi | Get Wardrobes In Bondi | Topcab Joinery"
          />
          <meta
            name="description"
            content="Buy quality crafted wardrobes near Bondi from the experts. Visit Topcab Joinery today & browse handcrafted woodwork for your home from our joinery in Bondi."
          />
          <meta
            property="og:description"
            content="Buy quality crafted wardrobes near Bondi from the experts. Visit Topcab Joinery today & browse handcrafted woodwork for your home from our joinery in Bondi."
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Joinery In Bondi | Get Wardrobes In Bondi | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Buy quality crafted wardrobes near Bondi from the experts. Visit Topcab Joinery today & browse handcrafted woodwork for your home from our joinery in Bondi."
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/joinery-dover-heights": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Joinery In Dover Heights | Wardrobes In Dover Heights</title>
          <meta
            property="og:title"
            content="Joinery In Dover Heights | Wardrobes In Dover Heights"
          />
          <meta
            name="description"
            content="Searching for wardrobes near Dover Heights? Buy decorated wardrobes from Topcab Joinery. Our amazing joinery will enhance your home. Call us now!"
          />
          <meta
            property="og:description"
            content="Searching for wardrobes near Dover Heights? Buy decorated wardrobes from Topcab Joinery. Our amazing joinery will enhance your home. Call us now!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Joinery In Dover Heights | Wardrobes In Dover Heights"
          />
          <meta
            name="twitter:description"
            content="Searching for wardrobes near Dover Heights? Buy decorated wardrobes from Topcab Joinery. Our amazing joinery will enhance your home. Call us now!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    case "/kitchen-renovations-randwick": {
      return (
        <Helmet>
          <meta property="og:url" content="https://topcabjoinery.com.au" />
          <meta property="og:type" content="website" />
          <title>Expert Kitchen Renovations In Randwick | Topcab Joinery</title>
          <meta
            property="og:title"
            content="Expert Kitchen Renovations In Randwick | Topcab Joinery"
          />
          <meta
            name="description"
            content="Looking for expert yet affordable kitchen renovations in Randwick? Skilled artisans are ready to bring your ideas to life at Topcab Joinery. Contact us now!"
          />
          <meta
            property="og:description"
            content="Looking for expert yet affordable kitchen renovations in Randwick? Skilled artisans are ready to bring your ideas to life at Topcab Joinery. Contact us now!"
          />
          <meta
            property="og:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="topcabjoinery.com.au" />
          <meta property="twitter:url" content="https://topcabjoinery.com.au" />
          <meta
            name="twitter:title"
            content="Expert Kitchen Renovations In Randwick | Topcab Joinery"
          />
          <meta
            name="twitter:description"
            content="Looking for expert yet affordable kitchen renovations in Randwick? Skilled artisans are ready to bring your ideas to life at Topcab Joinery. Contact us now!"
          />
          <meta
            name="twitter:image"
            content="https://topcabjoinery.com.au/static/media/logo-black.119817b34c6d40ff22627d961e91dec3.svg"
          />
        </Helmet>
      );
    }
    default:
      return null;
  }
}
