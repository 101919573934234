import React, { useState } from "react";
import FormField from "./formField";
import CustomBtn from "../buttons/customBtn";
import ReCAPTCHA from "react-google-recaptcha";
import "./formComponent.css";
import useWindowSize from "../../../hooks/useWindowSize";
import { Container, Row, Col } from "react-bootstrap";

const fetchWithTimeout = (url, options, timeout = 15000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), timeout)
    ),
  ]);
};

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    address: "",
    email: "",
    message: "",
    referral: "",
    subscribe: true,
  });

  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`Field: ${name}, Value: ${value}`);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("Form submitted", formData);
    e.preventDefault();
    setIsLoading(true);

    if (!captchaToken) {
      alert("Please complete the reCAPTCHA");
      setIsLoading(false);
      return;
    }

    const { name, mobile, address, email, message, referral, subscribe } =
      formData;
    const apiKey = process.env.REACT_APP_VBOUT_API_KEY;
    const listId = 138577;
    const status = "active";
    const subscriptionStatus = subscribe ? "Yes" : "No";

    const url = `https://api.vbout.com/1/emailmarketing/addcontact.json?key=${apiKey}&status=${status}&email=${email}&listid=${listId}&fields[928395]=${name}&fields[928399]=${mobile}&fields[928401]=${address}&fields[928614]=${message}&fields[928617]=${subscriptionStatus}&fields[928613]=${referral}`;

    try {
      const response = await fetchWithTimeout(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Contact added successfully");
        resetForm();
        window.location.assign("/thank-you");
        // displayThankYouMessage();
      } else {
        const errorText = await response.text();
        console.error(
          "Failed to add contact:",
          response.status,
          response.statusText,
          errorText
        );
        alert("Failed to submit form. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error.message, error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      mobile: "",
      address: "",
      email: "",
      message: "",
      referral: "",
      subscribe: true,
    });
    setCaptchaToken(null);
  };

  const displayThankYouMessage = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setIsSubmitted(false);
        setFadeOut(false);
      }, 1000);
    }, 9000);
  };

  const onCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const renderFormDesktop = () => {
    return (
      <div>
        {isSubmitted ? (
          <div className={`thank-you-message ${fadeOut ? "fade-out" : ""}`}>
            <h2>Thank You!</h2>
            <p>
              Your submission has been received. We will get back to you soon.
            </p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            style={{ display: "grid", gap: "3rem" }}
            className="my-5 ms-5"
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "5rem",
              }}
            >
              <FormField
                type="text"
                name="name"
                label="Name"
                onChange={(e) => handleChange(e)}
                value={formData.name}
                id="name"
              />
              <FormField
                type="tel"
                name="mobile"
                label="Mobile"
                onChange={(e) => handleChange(e)}
                value={formData.mobile}
                id="mobile"
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "5rem",
              }}
            >
              <FormField
                type="text"
                name="address"
                label="Address"
                onChange={(e) => handleChange(e)}
                value={formData.address}
                id="address"
              />
              <FormField
                type="email"
                name="email"
                label="Email"
                onChange={(e) => handleChange(e)}
                value={formData.email}
                id="email"
              />
            </div>
            <div>
              <FormField
                type="select"
                name="referral"
                label="How did you hear about us?"
                onChange={(e) => handleChange(e)}
                value={formData.referral}
                id="referral"
                options={[
                  "Google",
                  "Social Media (Facebook, Instagram, Etc.)",
                  "Word of Mouth/Referral",
                  "Other",
                ]}
              />
            </div>
            <div>
              <FormField
                type="text"
                name="message"
                label="Message"
                onChange={(e) => handleChange(e)}
                value={formData.message}
                id="message"
              />
            </div>
            <div className="d-flex gap-3 align-items-center">
              <input
                type="checkbox"
                name="subscribe"
                id="subscribe"
                checked={formData.subscribe}
                onChange={(e) => handleChange(e)}
                style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
              />
              <label
                htmlFor="subscribe"
                className="p font-proximanova-semibold color-grey"
              >
                Check if you want to subscribe to our newsletter
              </label>
            </div>
            <div>
              <ReCAPTCHA
                sitekey="6Lf2O90pAAAAACxYj97rQSeFIlS3aG49WJ9xefUG"
                onChange={onCaptchaChange}
                className="mb-3"
              />
              {isLoading ? (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <CustomBtn
                  text="Submit"
                  initColor="white"
                  onHoverBgColor="black"
                  onHoverTxtColor="white"
                  width={"300px"}
                  type="submit"
                />
              )}
              <p className="p font-proximanova-semibold color-grey mt-3">
                This site is protected by reCAPTCHA and the Google Privacy
                Policy and Terms of Service apply.
              </p>
            </div>
          </form>
        )}
      </div>
    );
  };

  const renderFormMobile = () => {
    return (
      <Container className="d-flex flex-column gap-5 mt-5">
        {isSubmitted ? (
          <div className={`thank-you-message ${fadeOut ? "fade-out" : ""}`}>
            <h2>Thank You!</h2>
            <p>
              Your submission has been received. We will get back to you soon.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
            <Row>
              <Col>
                <FormField
                  type="text"
                  name="name"
                  label="Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.name}
                  id="name"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type="tel"
                  name="mobile"
                  label="Mobile"
                  onChange={(e) => handleChange(e)}
                  value={formData.mobile}
                  id="mobile"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type="text"
                  name="address"
                  label="Address"
                  onChange={(e) => handleChange(e)}
                  value={formData.address}
                  id="address"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type="email"
                  name="email"
                  label="Email"
                  onChange={(e) => handleChange(e)}
                  value={formData.email}
                  id="email"
                />
              </Col>
            </Row>
            <Row>
              <FormField
                type="select"
                name="referral"
                label="How did you hear about us?"
                onChange={(e) => handleChange(e)}
                value={formData.referral}
                id="referral"
                options={[
                  "Google",
                  "Social Media (Facebook, Instagram, Etc.)",
                  "Word of Mouth/Referral",
                  "Other",
                ]}
              />
            </Row>
            <Row>
              <Col>
                <FormField
                  type="text"
                  name="message"
                  label="Message"
                  onChange={(e) => handleChange(e)}
                  value={formData.message}
                  id="message"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex gap-3 align-items-center">
                  <input
                    type="checkbox"
                    name="subscribe"
                    id="subscribe"
                    checked={formData.subscribe}
                    onChange={(e) => handleChange(e)}
                    style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
                  />
                  <label
                    htmlFor="subscribe"
                    className="p font-proximanova-semibold color-grey"
                  >
                    Check if you want to subscribe to our newsletter
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReCAPTCHA
                  sitekey="6Lf2O90pAAAAACxYj97rQSeFIlS3aG49WJ9xefUG"
                  onChange={onCaptchaChange}
                  className="mb-3"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {isLoading ? (
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <CustomBtn
                    text="Submit"
                    initColor="white"
                    onHoverBgColor="black"
                    onHoverTxtColor="white"
                    width={"300px"}
                    type="submit"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p font-proximanova-semibold color-grey mt-3">
                  This site is protected by reCAPTCHA and the Google Privacy
                  Policy and Terms of Service apply.
                </p>
              </Col>
            </Row>
          </form>
        )}
      </Container>
    );
  };

  return useWindowSize() ? renderFormMobile() : renderFormDesktop();
};

export default FormComponent;
