import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CabinetsMonaVale() {
  const craftsmenData = [
    {
      title: "Unwavering Dedication to Quality",
      desc: <p className="font-proximanova-light"> We are uncompromising in our pursuit of quality. Every project utilises only the finest materials, meticulously crafted with joinery techniques that stand the test of time. This unwavering dedication ensures your custom cabinets are not just stunning additions to your home but incredibly durable pieces built to last a lifetime.</p>,
    },
    {
      title: "Collaborative Design Process",
      desc: <p className="font-proximanova-light">We believe your vision is the foundation for a perfect outcome. Our experienced cabinet makers across Mona Vale will work hand-in-hand with you to understand your unique design goals, space limitations and personal preferences. We translate your ideas into detailed plans, ensuring the final product seamlessly complements your existing décor and reflects your style.</p>,
    },
    {
      title: "Budget and Timeline",
      desc: <p className="font-proximanova-light">Transparency and clear communication are at the heart of our process. We will work diligently to stay within your established budget and adhere to the agreed-upon timeline. Throughout the project, we'll keep you informed of progress and address any questions you may have, ensuring a smooth and stress-free experience.</p>,
    },
    {
      title: "Exceptional Customer Service",
      desc: <p className="font-proximanova-light">Our dedicated team prioritises your satisfaction. We are readily available to answer your questions, address any concerns you may have and ensure you're completely satisfied with the entire process. From initial consultation to final installation, exceptional customer service is our promise to you.</p>,
    },
    {
      title: "A Range of Joinery Solutions",
      desc: <p className="font-proximanova-light">While custom cabinetry is our specialty, our expertise extends far beyond kitchens. We offer a comprehensive suite of joinery services, including wardrobes, vanities, laundries, <Link to="/tv-units">luxury TV units</Link> and living area solutions. This allows us to create a cohesive and stylish look throughout your entire Mona Vale home, ensuring a unified aesthetic that reflects your taste and elevates your living space.</p>,
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="container min-vh-100 d-grid justify-content-center "
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Bespoke Cabinetry In Mona Vale: Topcab Kitchens & Joinery
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              The cabinets in your home play a crucial role in both aesthetics
              and functionality. They provide valuable storage space, define the
              visual style of a room and influence the overall flow and
              utilisation of the area. But when cabinets are generic or poorly
              designed, they can hinder functionality and detract from the
              beauty of your space. This is where{" "}
              <Link to="/">Topcab Kitchens & Joinery professional</Link> cabinet
              makers in Mona Vale can be of service.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26561.870760901904!2d151.28030188616026!3d-33.67700862752493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d54977b5e3239%3A0x5017d681632c190!2sMona%20Vale%20NSW%202103%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012339417!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center">
          <h2 className="font-miracle display-5 mb-3">
            What Makes Topcab Kitchens & Joinery Special?
          </h2>
          <p className="font-proximanova-light">
            Here's what sets us apart as cabinet makers in Mona Vale:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h5> {elem.title} </h5> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="font-miracle mb-3 text-center">
          Transformative Power Of Our Cabinet Makers In Mona Vale
        </h2>
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/cabinets.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <p className="font-proximanova-light my-0">
              Custom cabinets, meticulously crafted by our skilled cabinet
              makers around Mona Vale, offer a world of advantage over
              off-the-shelf options. Here's how custom cabinetry by Topcab
              Kitchens & Joinery can elevate your home:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start">
              <li>
                <strong>Perfect Fit and Function</strong>: Custom cabinets are
                designed to seamlessly integrate into your existing space,
                maximising storage capacity and eliminating wasted space. No
                more squeezing belongings into ill-fitting drawers or struggling
                to reach items on high shelves.
              </li>
              <li>
                <strong>Enhanced Aesthetics</strong>: Custom cabinets allow you
                to choose materials, finishes and styles that perfectly
                complement your existing décor. Imagine cabinets that not only
                hold your belongings but also become a stunning focal point in
                your room.
              </li>
              <li>
                <strong>Improved Organisation</strong>: The beauty of custom
                cabinetry lies in its ability to cater to your specific needs.
                Work with our experienced cabinet makers across Mona Vale to
                design cabinets with features like pull-out shelves, built-in
                dividers and customised compartments, ensuring everything has
                its designated place.
              </li>
              <li>
                <strong>Increased Value</strong>: Investing in high-quality,
                custom cabinetry adds significant value to your property. The
                craftsmanship and functionality of bespoke cabinets are highly
                sought-after by potential buyers, boosting your home's resale
                value.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">
              Reimagine Your Space With Topcab Kitchens & Joinery
            </h2>
            <p className="font-proximanova-light zeal-digital">
              Are you ready to transform your home with bespoke cabinetry that
              seamlessly blends functionality and beauty? Contact Topcab
              Kitchens & Joinery today at{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or email at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>
              .
            </p>
            <p className="font-proximanova-light zeal-digital">
              Our team of skilled cabinet makers around Mona Vale is eager to
              discuss your vision and create custom cabinetry solutions that
              meet your needs and exceed your expectations. Browse our website
              to view our extensive portfolio and get a glimpse of the
              exceptional craftsmanship we bring to every project.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <Form />
    </section>
  );
}
