import { createElement, useEffect } from "react"
import { useLocation } from "react-router-dom"
import MetaDataJson from "../../json/meta-tags.json"

const usePageSEO = () => {
    const location = useLocation();
    const curPath = location.pathname;

    useEffect(() => {

        //Canonical Tag
        let canonicalTag = document.querySelector(`link[rel="canonical"]`)
        if (!canonicalTag) {
            canonicalTag = document.createElement("link");
            canonicalTag.setAttribute("rel", "canonical");
            document.head.append(canonicalTag);
        }
        // console.log("pathname :" + window.location.pathname + (window.location.pathname === "/"))
        canonicalTag.setAttribute("href", "https://topcabjoinery.com.au" + (window.location.pathname === "/" ? "" : window.location.pathname))

        //Language Tag
        let alternateTag = document.querySelector(`link[rel="alternate"]`)
        if (!alternateTag) {
            alternateTag = document.createElement("link");
            alternateTag.setAttribute("rel", "alternate");
            alternateTag.setAttribute("hreflang", "en-AU");
            document.head.append(alternateTag);
        }
        alternateTag.setAttribute("href", "https://topcabjoinery.com.au" + (window.location.pathname === "/" ? "" : window.location.pathname))

        //Meta title
        if (MetaDataJson.find((elem) => { return elem.url === curPath || elem.url + "/" === curPath })) {
            const metaTitle = MetaDataJson.find((elem) => { return elem.url === curPath || elem.url + "/" === curPath })["meta-title"]
            let titleTag = document.querySelector("title");
            if (!titleTag) {
                titleTag = createElement("title");
            }
            titleTag.innerText = metaTitle;
        }

        //Meta description Tag
        if (MetaDataJson.find((elem) => { return elem.url === curPath || elem.url + "/" === curPath })) {
            const metaDescription = MetaDataJson.find((elem) => { return elem.url === curPath || elem.url + "/" === curPath })["meta-description"]
            let metaDescriptionTag = document.querySelector(`meta[name="description"]`)
            if (!metaDescriptionTag) {
                metaDescriptionTag = document.createElement("meta");
                alternateTag.setAttribute("name", "description");
            }
            metaDescriptionTag.setAttribute("content", metaDescription)
        }

    }, [])
}

export default usePageSEO;