import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function WardrobesMaroubra() {
  const areasWeServe = [
    // { title: "maroubra", href: "/wardrobes-maroubra" },
    {
      title: "wetherill park",
      href: "/wardrobes-wetherill-park",
    },
    { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
    { title: "coogee", href: "/wardrobes-coogee" },
    { title: "rose bay", href: "/wardrobes-rose-bay" },
  ];

  const craftsmenData = [
    {
      title: "Expert Craftsmanship",
      desc: "Our team consists of highly-skilled joiners with years of experience in custom cabinetry.",
    },
    {
      title: "Quality Assurance",
      desc: "We adhere to strict quality standards, ensuring each wardrobe is built to last.",
    },
    {
      title: "Customer-Centric Service",
      desc: "We work closely with you to understand your needs and preferences, delivering solutions that exceed expectations.",
    },
    {
      title: "Competitive Pricing",
      desc: "We offer premium quality at competitive prices, providing great value for your investment.",
    },
    {
      title: "Local Expertise",
      desc: " As an Australian-based company, we understand the local market and design trends, offering solutions that fit your lifestyle.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
        className="container min-vh-100 d-grid justify-content-center "
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Topcab Kitchens & Joinery Offer Quality Wardrobes Around Maroubra
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Welcome to <Link to="/">Topcab Kitchens & Joinery</Link>, your
              trusted partner in bespoke joinery solutions in Maroubra. Since
              our establishment in 2020, we have been dedicated to delivering
              high-quality, custom-made cabinets and wardrobes around Maroubra
              to enhance the beauty and functionality of your home. Our skilled
              craftsmen use premium materials and innovative techniques to
              create storage solutions that perfectly match your lifestyle and
              aesthetic preferences.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26478.675765235006!2d151.2256437871881!3d-33.94538528278281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b3ce176e4569%3A0x5017d681632c000!2sMaroubra%20NSW%202035%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012532244!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center pb-5 container">
          <h2 className="font-miracle display-5 mb-3">
            Why Custom Wardrobes In Maroubra?
          </h2>
          <p className="font-proximanova-light">
            Investing in custom wardrobes for your home offers numerous
            advantages. Unlike standard, off-the-shelf options, custom wardrobes
            are designed to fit your space perfectly, making the most of every
            inch available. This means no more awkward gaps or wasted areas, as
            each piece is tailored to your specific needs. Additionally, custom
            wardrobes allow you to choose the materials, finishes and features
            that best suit your taste and requirements, ensuring a cohesive look
            that complements your home&apos;s interior design.
          </p>
          <p className="font-proximanova-light">
            Custom wardrobes also provide enhanced functionality. With the
            ability to include features such as built-in lighting, specialised
            compartments and adjustable shelving, you can create a storage
            solution that is both practical and stylish. This level of
            personalisation ensures that your wardrobe not only looks great but
            also meets your everyday needs, making your life more organised and
            efficient.
          </p>
        </div>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/detailed-joinery.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h2 className="display-6 font-miracle">
              Varieties Of Wardrobes We Offer
            </h2>
            <p className="font-proximanova-light zeal-digital my-0">
              Topcab Kitchens & Joinery offers a variety of wardrobe styles to
              suit different tastes and needs:
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-2 my-0 text-start">
              <li>
                <strong>Built-in Wardrobes</strong>: Seamlessly integrated into your room, these
                wardrobes offer a sleek and modern look.
              </li>
              <li>
                <strong>Walk-in Wardrobes</strong>: Luxurious and spacious, perfect for those who
                need extensive storage and a dressing area.
              </li>
              <li>
                <strong>Sliding Door Wardrobes</strong>: Ideal for rooms with limited space,
                providing easy access and a contemporary aesthetic.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital text-center uni-padding-y">
        <h2 className="font-miracle mb-5 display-6">
          Why Choose Professionals To Install Your Wardrobe?
        </h2>
        <p>
          Hiring professionals to install your bespoke wardrobe in Maroubra is
          critical to ensuring a flawless finish and long-term durability. Our
          skilled team handles every detail, from precise measurements to
          attentive installation, ensuring that your wardrobe fits seamlessly
          within your space. Professional installation reduces the possibility
          of errors and damage, giving you peace of mind and a wardrobe that
          will last for many years.
        </p>
        <p>
          Our team's knowledge ensures that every aspect of your wardrobe, from
          sliding doors to interior fittings, runs smoothly and efficiently. We
          employ industry-leading tools and procedures to provide a seamless fit
          and finish, which improves the appearance and performance of your
          clothing. By leaving your wardrobe installation to our specialists,
          you can be certain of its quality and dependability.
        </p>
      </section>

      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div
            className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start order-1 order-lg-0"
            style={{ fontSize: "14px" }}
          >
            <div>
              <h2 className="display-6 font-miracle">
                Our Joinery Services Offered In Maroubra
              </h2>
              <p className="font-proximanova-light zeal-digital my-0">
                At Topcab Kitchens & Joinery, we offer a comprehensive range of
                services to meet all your joinery needs. Our expert team
                specialises in designing, crafting, and installing custom
                wardrobes in Maroubra that elevate the style and functionality
                of your home. Our services include:
              </p>
              <ul className="font-proximanova-light d-flex flex-column gap-2 my-0 text-start">
                <li>Custom wardrobe design and consultation</li>
                <li>Precision crafting using high-quality materials</li>
                <li>Professional installation and finishing </li>
                <li>Tailored storage solutions for every room</li>
              </ul>
              <p className="font-proximanova-light zeal-digital my-0">
                Whether you need a sleek, modern wardrobe for your bedroom or a
                spacious walk-in closet, Topcab Kitchens & Joinery has the
                skills and experience to bring your vision to life.
              </p>
            </div>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/maroubra-wardrobes-1.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>

      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            Why You Can Count On Us
          </h2>
          <p className="font-proximanova-light">
            Choosing Topcab Kitchens & Joinery means opting for excellence,
            reliability and a personalised approach. Here&apos;s why our clients
            trust us for the best wardrobes near Maroubra:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h5> {elem.title} </h5> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <h2 className="font-miracle mb-3 display-5 text-center">
          Advantages Of Our Wardrobes
        </h2>
        <p className="font-proximanova-light">
          Choosing a wardrobe from Topcab Kitchens & Joinery comes with numerous
          benefits:
        </p>
        <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li>
            <strong>Perfect Fit</strong>: Our wardrobes are designed to fit your space precisely,
            maximising storage and minimising clutter.
          </li>
          <li>
            <strong>Personalised Design</strong>: Customise every aspect, from materials and
            colours to internal configurations, to match your style.
          </li>
          <li>
            <strong>High-Quality Materials</strong>: We use only the finest materials for our {" "}
            <Link to="/detailed-joinery">detailed joinery</Link>, ensuring durability and longevity.
          </li>
          <li>
            <strong>Enhanced Functionality</strong>: Add features like integrated lighting,
            pull-out racks and adjustable shelving for optimal convenience.
          </li>
          <li>
            <strong>Increased Home Value</strong>: A well-designed wardrobe can enhance the
            appeal and value of your property.
          </li>
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">Call Us Today</h2>
            <p className="font-proximanova-light zeal-digital">
              Contact us today at{" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> to schedule a
              consultation in Maroubra and in{" "}
              <Link to="https://topcabjoinery.com.au/wardrobes-bellevue-hill">
                Bellevue Hill
              </Link>{" "}
              and{" "}
              <Link to="https://topcabjoinery.com.au/wardrobes-coogee">
                Coogee
              </Link>
              . Take the first step towards a more organised and stylish living
              space. You can also drop an email at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              to get connected. Let our experts bring your vision to life with
              bespoke wardrobes that perfectly fits your needs.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Areas we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
