import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function JoineryBondi() {
  const areasWeServe = [
    // { title: "bondi", href: "/joinery-bondi" },
    { title: "dover heights", href: "/joinery-dover-heights" },
  ];
  const craftsmenData = [
    {
      title: "Unwavering Dedication to Quality",
      desc: "We are uncompromising in our pursuit of quality. Every project utilises only the finest materials, meticulously crafted with joinery techniques that stand the test of time. This unwavering dedication ensures your custom cabinets are not just stunning additions to your home but incredibly durable pieces built to last a lifetime.",
    },
    {
      title: "Collaborative Design Process",
      desc: "We believe your vision is the foundation for a perfect outcome. Our experienced cabinet makers across Mona Vale will work hand-in-hand with you to understand your unique design goals, space limitations and personal preferences. We translate your ideas into detailed plans, ensuring the final product seamlessly complements your existing décor and reflects your style.",
    },
    {
      title: "Budget and Timeline",
      desc: "Transparency and clear communication are at the heart of our process. We will work diligently to stay within your established budget and adhere to the agreed-upon timeline. Throughout the project, we'll keep you informed of progress and address any questions you may have, ensuring a smooth and stress-free experience.",
    },
    {
      title: "Exceptional Customer Service",
      desc: "Our dedicated team prioritises your satisfaction. We are readily available to answer your questions, address any concerns you may have and ensure you're completely satisfied with the entire process. From initial consultation to final installation, exceptional customer service is our promise to you.",
    },
    {
      title: "A Range of Joinery Solutions",
      desc: "While custom cabinetry is our specialty, our expertise extends far beyond kitchens. We offer a comprehensive suite of joinery services, including wardrobes, vanities, laundries, luxury TV units and living area solutions. This allows us to create a cohesive and stylish look throughout your entire Mona Vale home, ensuring a unified aesthetic that reflects your taste and elevates your living space.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="container min-vh-100 d-grid justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Leading And Trusted Experts Of Wardrobes And Joinery In Bondi
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Welcome to <Link to="/">Topcab Kitchens & Joinery</Link>, the go-to place for premium
              joinery services in Bondi. With years of expertise and a
              dedication to quality, we are experts in making custom wardrobes
              around Bondi that flawlessly combine practicality and style.
              Topcab Kitchens & Joinery can realise your dream whether you're
              searching for walk-in closets, sliding door wardrobes or custom
              wardrobes in Bondi.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13247.470090193467!2d151.25665254294304!3d-33.89306555976384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b2764633189f%3A0x5017d681632aad0!2sBondi%20NSW%202026%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012462334!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center pb-5 container">
          <h2 className="font-miracle display-5 mb-3">
            What Makes Topcab Kitchens & Joinery The Best?
          </h2>
          <p className="font-proximanova-light">
            We are aware that your home reflects your individuality and sense of
            style. Our expert craftsmen are dedicated to delivering joinery
            solutions that not only meet but exceed your expectations. We take
            pride in our meticulous attention to detail, innovative designs and
            superior craftsmanship. When you choose Topcab Kitchens & Joinery,
            you're choosing a company that values quality, reliability and
            customer satisfaction.
          </p>
        </div>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="font-miracle mb-3 text-center">
          Get Affordable And Stylish Wardrobes In Bondi
        </h2>
        <p className="font-proximanova-light text-center">
          Every home is beautiful and has its own charm and so are the storage
          needs of its inhabitants. For this reason, we provide bespoke
          wardrobes made to meet your unique needs. Our custom wardrobes are
          designed to maximise your space while providing a seamless blend with
          your existing decor.
        </p>
        <div className="hero-section-grid">
          <div className="order-0 order-lg-1 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/wardrobe-coogee.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 order-lg-0 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h3 className="font-miracle mb-3">
              Features Of Our Custom Wardrobes
            </h3>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start">
              <li>
                <strong>Personalised Design</strong>: Our design team works closely with you to
                understand your needs and preferences, ensuring that your
                wardrobe is a perfect match for your home.
              </li>
              <li>
                <strong>Quality Materials</strong>: To ensure endurance and durability, we
                exclusively utilise the best materials.
              </li>
              <li>
                <strong>Optimised Storage</strong>: Our custom wardrobes feature smart storage
                solutions such as adjustable shelves, hanging rods and drawers
                to keep your belongings organised.
              </li>
            </ul>
            <p className="font-proximanova-light">
              Whether you have a small alcove or a spacious room, our custom
              wardrobes are designed to fit any space, making them the perfect
              addition to your Bondi home.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          Premium Joinery In Bondi And Nearby Areas
        </h2>
        <p>
          We specialise in designing custom joinery solutions that transform
          areas into beautiful, practical settings. Our talented artisans are
          dedicated to providing great quality and unique design.
        </p>
        <strong>Custom Cabinetry </strong>{" "}
        <p>
          Our custom cabinetry solutions are tailored to your specifications,
          enhancing any area while combining style and functionality.
        </p>
        <strong>Kitchen Joinery</strong>{" "}
        <p>
          From modern simplicity to traditional elegance, we design and install
          kitchens that are both attractive and useful.
        </p>
        <strong> Bathroom Vanities</strong>{" "}
        <p>
          Our bespoke vanities provide a touch of luxury to your bathroom,
          designed to fit properly in your space.
        </p>
        <strong> Wardrobes </strong>{" "}
        <p>
          Our custom-built wardrobes in Bondi and in{" "}
          <Link to="https://topcabjoinery.com.au/joinery-dover-heights">
            Dover Heights
          </Link>{" "}
          are tailored to your unique needs, allowing you to maximise your
          storage.
        </p>
        <strong>Commercial Joinery </strong>{" "}
        <p>
          Our expert joinery solutions in Bondi, ranging from welcome desks to
          office fit-outs, will enhance your business environment.
        </p>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <h2 className="font-miracle mb-3 text-center">
          Efficient Walk-In Wardrobes
        </h2>
        <p className="font-proximanova-light text-center">
          Imagine walking into a beautifully-organised space where all your
          clothes, shoes and accessories are easily accessible. Our walk-in
          wardrobes offer luxury and convenience, transforming your daily
          routine into a delightful experience.
        </p>
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/walk-in-wardrobes.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h3 className="font-miracle mb-3">Benefits Of Walk-In Wardrobes</h3>
            <ul className="font-proximanova-light d-flex flex-column gap-0 text-start">
              <li>
                <strong>Spacious Designs</strong>: Enjoy ample space to store and display your
                wardrobe essentials.
              </li>
              <li>
                <strong>Enhanced Organisation</strong>: With dedicated sections for different
                types of clothing and accessories, you'll never have to hunt for
                what you need.
              </li>
              <li>
                <strong>Customisation Options</strong>: Choose from a variety of finishes,
                fittings and lighting options to create a wardrobe that reflects
                your style.
              </li>
            </ul>
            <p className="font-proximanova-light">
              Our walk-in wardrobes are designed to add a touch of elegance to
              your home, providing a practical yet stylish storage solution.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center">
          <h2 className="font-miracle display-5 mb-3">Our Joinery Technique</h2>
          <p className="font-proximanova-light">
            At Topcab Kitchens & Joinery, we're dedicated to giving our
            customers a smooth, trouble-free experience. Our procedure is made
            to ensure that your wardrobe project is finished to the best
            possible quality.
          </p>
        </div>
        <ul className="font-proximanova-light">
          <li>
            <strong>Consultation</strong>: To fully grasp your requirements, preferences and
            budget, we start with a thorough consultation.
          </li>
          <li>
            <strong>Design</strong>: Our skilled design team helps you picture your new wardrobe
            by producing intricate blueprints and 3D models.
          </li>
          <li>
            <strong>Material Selection</strong>: Choose from a wide range of materials, finishes
            and fittings to customise your wardrobe.
          </li>
          <li>
            <strong>Craftsmanship</strong>: Our skilled craftsmen bring your design to life,
            ensuring every detail is perfect.
          </li>
          <li>
            <strong>Installation</strong>: Your new wardrobe will be installed carefully and
            precisely thanks to the expertise of our team of installers.
          </li>
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">Connect With Us Today</h2>
            <p className="font-proximanova-light zeal-digital">
              To arrange a consultation, give Topcab Kitchens & Joinery a call
              right now at {" "}
              <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>
              . Our helpful staff is available to walk you through the process
              and respond to any questions you may have. Find out why Bondi
              residents and business owners trust us with their joinery needs.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Area we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
