import React, { useRef, useState } from "react";
import "./sliderItem.css";

function SliderItem({ project }) {
  const slideTextRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const { REACT_APP_ASSETS_PROJECTS_URL } = process.env;

  const styles = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0)), url(${
      REACT_APP_ASSETS_PROJECTS_URL + project.mainImage.url
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="fullscreen d-flex justify-content-center align-items-center flex-column">
      <a
        href={`/portfolio/${project.id}`}
        className="fixed-wrap fixed"
        data-clear-cookies=""
      >
        <div className="slide-image object-cover" style={styles}></div>
        <div ref={slideTextRef} className="slide-text over-image">
          <div className="wrap text-wrap align-center d-flex flex-column">
            <h2 className="font-proximanova-semibold color-white h1">
              {project.name}
            </h2>
            <p className="align-self-center font-proximanova-semibold color-white">
              {project.location}
            </p>
            <button
              tabIndex="-1"
              className={`hero-btn align-self-center ${
                isHovered
                  ? "bg-white color-black"
                  : "bg-transparent color-white"
              } p-3  font-proximanova-light text-uppercase border border-white d-flex justify-content-center align-items-center`}
              style={{
                width: "170px",
                height: "42px",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onTouchStart={() => setIsHovered(true)}
              onTouchEnd={() => setIsHovered(false)}
            >
              view more
            </button>
          </div>
        </div>
      </a>
    </div>
  );
}

export default SliderItem;
