import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomeJoineryBrookvale() {
  const areasWeServe = [
    {
      title: "surry hills",
      href: "/custom-joinery-surry-hills",
    },
    {
      title: "northern beaches",
      href: "/custom-joinery-northern-beaches",
    },
    { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    { title: "double bay", href: "/custom-joinery-double-bay" },
    // { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Modern Wardrobes And Bathroom Vanity In Brookvale From Topcab
                Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                Are you looking for a custom-made wardrobe or a bathroom vanity in Brookvale? Do you want to turn your ordinary bathroom and bedroom into a more stylish and functional one? In that you can choose us!  <Link to="/">Topcab Joinery</Link> offers premium quality custom-made bathroom vanities and wardrobes which will elevate the beauty of your home. We first carefully listen to you to know about your style and requirements and then build custom solutions. So, if you want premium quality furniture, increase the storage and appearance of your home interiors, all within your budget, then contact Topcab Kitchens & Joinery today.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13267.686647193257!2d151.2693327!3d-33.76269015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12aa60c415d069%3A0x5017d681632ae70!2sBrookvale%20NSW%202100%2C%20Australia!5e0!3m2!1sen!2sin!4v1723011718665!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Premium Custom Bathroom Vanities In Brookvale
          </h2>
          <p>
            The vanity is an essential part of your bathroom. Elevate the space
            in your bathroom by getting quality vanity installed from Topcab
            Kitchens & Joinery. Our bathroom vanity will enhance your space in
            the following ways:
          </p>
          <h3> Optimise Functionality </h3>{" "}
          <p>
            A clutter-free bathroom is ensured by ample storage for towels,
            linens and toiletries. We include cabinets, shelves and drawers in
            the bathroom vanity at your Brookvale home, all customised to meet
            your unique requirements.
          </p>
          <h3> Boost Aesthetics </h3>{" "}
          <p>
            We provide a broad selection of styles, coatings and materials to
            match your current bathroom aesthetics, from modern and sleek to
            classic and timeless. Imagine a vanity that complements your unique
            style and improves the atmosphere of your bathroom.
          </p>
          <h3 className="h3"> Maximise Space </h3>{" "}
          <p>
            Even in small bathrooms, our talented designers can build vanities
            that make the most of every square inch of available space.
            Together, we'll optimise functionality without sacrificing style.
          </p>
          <h3 className="h3"> Seamless Integration </h3>{" "}
          <p>
            Our craftsmen build vanities that blend perfectly with your
            bathroom's design, giving it a polished and unified appearance.
          </p>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="">
              <img
                src="/zeal-digital/wardrobe-coogee.jpg"
                className="bg-dark rounded-3 h-full object-fit-cover"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">
                Premium Wardrobes Around Brookvale Designed By Us
              </h2>
              <p className="font-proximanova-light zeal-digital m-0">
                Topcab Kitchens & Joinery is your premier choice for world class
                wardrobes in Brookvale home, matching any budget. As the top
                service providers for{" "}
                <Link to="https://topcabjoinery.com.au/">detailed joinery</Link>
                , we can assist you in the following:
              </p>
              <ul className="font-proximanova-light d-flex flex-column gap-2 m-0">
                <li>
                  <strong>Walk-in wardrobes:</strong> Are you searching for the
                  best wardrobes in Brookvale? Let Topcab Kitchens & Joinery
                  transform your spare room or create a luxurious space to store
                  your clothes and accessories in a custom-designed walk-in
                  closet.
                </li>
                <li>
                  <strong>Built-in Wardrobes:</strong> We can create a smooth,
                  unified aesthetic in your bedroom by incorporating wardrobes
                  into your walls. A space-saving option that maximises your
                  bedroom's arrangement and enhances the overall style is
                  built-in wardrobes at your Brookvale home.
                </li>
                <li>
                  <strong>Sliding Door Wardrobes:</strong> We can also help
                  create sliding door wardrobes in your small bedroom, which
                  maximises space. Their functionality, without compromising
                  design, has made them a very popular choice among many
                  homeowners in Brookvale.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">
            Reasons To Choose Us For Wardrobes And Bathroom Vanities In
            Brookvale
          </h2>
          <p>
            Below are some compelling reasons why Topcab Kitchens & Joinery is
            the top choice for wardrobes and bathroom vanities in Brookvale and
            in{" "}
            <Link to="https://topcabjoinery.com.au/custom-joinery-surry-hills">
              Surry Hills
            </Link>{" "}
            and{" "}
            <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
              Double Bay
            </Link>
            :
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Unwavering Dedication to Quality</strong>: Our highly-trained craftsmen are
              dedicated to precise workmanship and we only use the best
              materials made in Australia. Your vanity and wardrobe's
              long-lasting construction ensures value and style.
            </li>
            <li>
              <strong>Collaborative process</strong>: We work closely with our clients to provide
              quality vanities and wardrobes in Brookvale. With open
              communication, we try to understand your requirements and
              preferences. We will create the best custom wardrobe and bathroom
              vanity for your Brookvale home.{" "}
            </li>
            <li>
              <strong>Competitive Rates</strong>: Another reason to choose us for bathroom
              vanities and wardrobes in Brookvale is because we offer
              competitive prices for our work. By doing so, we ensure that you
              get the best value for your investment.{" "}
            </li>
            <li>
              <strong>Hassle-free installation</strong>: As one of the top joinery service
              providers near Brookvale, we ensure a smooth and hassle-free
              installation without disrupting your home.{" "}
            </li>
            <li>
              <strong>Customer service</strong>: If you have any questions about our work or
              anything in general, you can contact us. Our friendly and
              professional team is dedicated to offering you exceptional
              customer service from the initial consultation to completing the
              task.{" "}
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">Reach Us Anytime</h2>
              <p className="font-proximanova-light zeal-digital">
                Let our craftsmen at Topcab Kitchens & Joinery make your vision
                of a functioning bathroom and bedroom a reality with our
                high-quality services. If you have suggestions or queries
                regarding our bathroom vanities and wardrobes in Brookvale,
                please contact us. You can Call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to explore our range of services and speak with our experts.
                Experience the <span>Topcab Kitchens & Joinery</span> difference
                – where quality, craftsmanship and customer satisfaction combine
                to create exceptional custom joinery solutions.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/custom-joinery-brookvale-1.jpg"
                className="bg-dark rounded-3 ms-auto object-fit-cover"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Modern Wardrobes And Bathroom Vanity In Brookvale From Topcab
                Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                Are you looking for a custom-made wardrobe or a bathroom vanity in Brookvale? Do you want to turn your ordinary bathroom and bedroom into a more stylish and functional one? In that you can choose us!  <Link to="">Topcab Joinery</Link> offers premium quality custom-made bathroom vanities and wardrobes which will elevate the beauty of your home. We first carefully listen to you to know about your style and requirements and then build custom solutions. So, if you want premium quality furniture, increase the storage and appearance of your home interiors, all within your budget, then contact Topcab Kitchens & Joinery today.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105949.80504856004!2d151.22383795!3d-33.9171649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b18db5d79889%3A0xa061091923af585a!2sEastern%20Suburbs%2C%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1721884552761!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Premium Custom Bathroom Vanities In Brookvale
          </h2>
          <p>
            The vanity is an essential part of your bathroom. Elevate the space
            in your bathroom by getting quality vanity installed from Topcab
            Kitchens & Joinery. Our bathroom vanity will enhance your space in
            the following ways:
          </p>
          <h3> Optimise Functionality </h3>{" "}
          <p>
            A clutter-free bathroom is ensured by ample storage for towels,
            linens and toiletries. We include cabinets, shelves and drawers in
            the bathroom vanity at your Brookvale home, all customised to meet
            your unique requirements.
          </p>
          <h3>Boost Aesthetics </h3>{" "}
          <p>
            We provide a broad selection of styles, coatings and materials to
            match your current bathroom aesthetics, from modern and sleek to
            classic and timeless. Imagine a vanity that complements your unique
            style and improves the atmosphere of your bathroom.
          </p>
          <h3 className="h3"> Maximise Space </h3>{" "}
          <p>
            Even in small bathrooms, our talented designers can build vanities
            that make the most of every square inch of available space.
            Together, we'll optimise functionality without sacrificing style.
          </p>
          <h3 className="h3"> Seamless Integration </h3>{" "}
          <p>
            Our craftsmen build vanities that blend perfectly with your
            bathroom's design, giving it a polished and unified appearance.
          </p>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="order-0 order-lg-1">
              <img
                src="/zeal-digital/wardrobe-coogee.jpg"
                className="bg-dark rounded-3 d-block mx-auto object-fit-cover"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center order-1 order-lg-0">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Premium Wardrobes Around Brookvale Designed By Us
              </h2>
              <p className="font-proximanova-light h5 zeal-digital text-center text-lg-start">
                Topcab Kitchens & Joinery is your premier choice for world class
                wardrobes in Brookvale home, matching any budget. As the top
                service providers for{" "}
                <Link to="https://topcabjoinery.com.au/">detailed joinery</Link>
                , we can assist you in the following:
              </p>
              <ul className="font-proximanova-light d-flex flex-column gap-2">
                <li>
                  <strong>Walk-in wardrobes:</strong> Are you searching for the
                  best wardrobes in Brookvale? Let Topcab Kitchens & Joinery
                  transform your spare room or create a luxurious space to store
                  your clothes and accessories in a custom-designed walk-in
                  closet.
                </li>
                <li>
                  <strong>Built-in Wardrobes:</strong> We can create a smooth,
                  unified aesthetic in your bedroom by incorporating wardrobes
                  into your walls. A space-saving option that maximises your
                  bedroom's arrangement and enhances the overall style is
                  built-in wardrobes at your Brookvale home.
                </li>
                <li>
                  <strong>Sliding Door Wardrobes:</strong> We can also help
                  create sliding door wardrobes in your small bedroom, which
                  maximises space. Their functionality, without compromising
                  design, has made them a very popular choice among many
                  homeowners in Brookvale.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">
            Reasons To Choose Us For Wardrobes And Bathroom Vanities In
            Brookvale
          </h2>
          <p>
            Below are some compelling reasons why Topcab Kitchens & Joinery is
            the top choice for wardrobes and bathroom vanities in Brookvale and
            in{" "}
            <Link to="https://topcabjoinery.com.au/custom-joinery-surry-hills">
              Surry Hills
            </Link>{" "}
            and{" "}
            <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
              Double Bay
            </Link>
            :
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              Unwavering Dedication to Quality: Our highly-trained craftsmen are
              dedicated to precise workmanship and we only use the best
              materials made in Australia. Your vanity and wardrobe's
              long-lasting construction ensures value and style.
            </li>
            <li>
              Collaborative process: We work closely with our clients to provide
              quality vanities and wardrobes in Brookvale. With open
              communication, we try to understand your requirements and
              preferences. We will create the best custom wardrobe and bathroom
              vanity for your Brookvale home.{" "}
            </li>
            <li>
              Competitive Rates: Another reason to choose us for bathroom
              vanities and wardrobes in Brookvale is because we offer
              competitive prices for our work. By doing so, we ensure that you
              get the best value for your investment.{" "}
            </li>
            <li>
              Hassle-free installation: As one of the top joinery service
              providers near Brookvale, we ensure a smooth and hassle-free
              installation without disrupting your home.{" "}
            </li>
            <li>
              Customer service: If you have any questions about our work or
              anything in general, you can contact us. Our friendly and
              professional team is dedicated to offering you exceptional
              customer service from the initial consultation to completing the
              task.{" "}
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Reach Us Anytime
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Let our craftsmen at Topcab Kitchens & Joinery make your vision
                of a functioning bathroom and bedroom a reality with our
                high-quality services. If you have suggestions or queries
                regarding our bathroom vanities and wardrobes in Brookvale,
                please contact us. You can Call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to explore our range of services and speak with our experts.
                Experience the <span>Topcab Kitchens & Joinery</span> difference
                – where quality, craftsmanship and customer satisfaction combine
                to create exceptional custom joinery solutions.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/custom-joinery-brookvale-1.jpg"
                className="bg-dark rounded-3 mx-auto d-block object-fit-cover"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
