import React, { useState } from "react";
import "./projectGrid.css";
import { useNavigate } from "react-router-dom";
import locationImage from "../../../assets/images/utils/location.png";
import CustomCursor from "../customCursor/customCursor";

const ProjectsGrid2 = ({ projects }) => {
  const navigate = useNavigate();
  const [overImage, setOverImage] = useState(false);
  const { REACT_APP_ASSETS_PROJECTS_URL } = process.env;

  const handleMouseEnter = () => {
    setOverImage(true);
    document.body.style.cursor = "none";
  };

  const handleMouseLeave = () => {
    setOverImage(false);
    document.body.style.cursor = "auto";
  };

  return (
    <div className="portfolio__body py-4">
      {projects.map((item, index) => (
        <div key={item.id} className={`portfolio__item grid2__item${++index}`}>
          {item.portfolioThumbnail?.url && (
            <React.Fragment>
              {overImage && <CustomCursor />}
              <img
                src={
                  REACT_APP_ASSETS_PROJECTS_URL + item.portfolioThumbnail.url
                }
                alt={item.name}
                className="w-100 h-100 img-fluid portfolio__img overflow-none"
                onClick={() => navigate(`/portfolio/${item.id}`)}
                style={{ cursor: "none" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </React.Fragment>
          )}
          <span className="title color-black">{item.name}</span>
          <span className="font-proximanova-light color-grey text-align-start p">
            <img
              src={locationImage}
              alt="location"
              className="w-auto h-auto me-2"
            />
            {item.location ? item.location : "No location"}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProjectsGrid2;
