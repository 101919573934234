import bg from "../../assets/images/bg.jpg";
import "./thank-you.css";

export default function ThankYouComponent() {
  return (
    <section
      style={{
        display: "grid",
        backgroundImage: `url(${bg})`,
        backgroundSize: "center",
        placeItems: "center",
      }}
      className="thank-you-section"
    >
      <div
        style={{
          backgroundColor: "#ffffff10",
          padding: "16px",
          border: "1px solid gray",
          backdropFilter: "blur(8px)",
          maxWidth: "80vw",
        }}
      >
        <div style={{ margin: "0px auto 24px auto", width: "fit-content" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8em"
            height="8em"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6c-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95c44.509 1.001 75.06 39.94 177.802 39.94c7.22 0 15.22.01 22.22.01c77.117 0 111.986-39.423 112.94-95.33c13.319-18.425 20.299-43.122 17.34-66.99c9.854-18.452 13.664-40.343 8.99-62.99m-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57c7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46c28.36-28.36 18.91-75.63 37.82-94.54c47.27 0 47.27 32.98 47.27 56.73c0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82c.09 18.9-12.82 37.81-22.27 37.81c13.489 14.555 16.371 45.236-5.21 65.62M88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24s10.745-24 24-24s24 10.745 24 24"
            ></path>
          </svg>
        </div>
        <p id="thank-you-msg" style={{ textAlign: "center", fontSize: "20px" }}>
          Your form has been successfully submitted. <br /> We will contact you
          as soon as we review your message.
        </p>
        <a href="/" target="" className="back-to-home-btn">
          Back to Home
        </a>
      </div>
    </section>
  );
}
