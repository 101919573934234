import React, { useEffect } from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import profilePic from "../../../assets/images/logos/instaProfileLogo.jpg";
import useWindowSize from "../../../hooks/useWindowSize";

const InstaModal = ({
  isOpen,
  onRequestClose,
  selectedPost,
  comments,
  closeModal,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const renderDesktopModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Post Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            height: "auto",
            maxWidth: "80%",
            maxHeight: "80%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            padding: 0,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        }}
      >
        {selectedPost && (
          <>
            <div
              style={{
                flex: "0 0 60%",
                backgroundColor: "black",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              {selectedPost.media_type === "CAROUSEL_ALBUM" ? (
                <Carousel
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={3000}
                  centerMode={true}
                  centerSlidePercentage={100}
                  width="100%"
                  height="100%"
                  dynamicHeight={true}
                  emulateTouch={true}
                >
                  {selectedPost.children.data.map((child) => (
                    <div key={child.id}>
                      <img
                        src={child.media_url}
                        alt={selectedPost.caption}
                        className="img-fluid"
                        style={{ width: "100%", maxHeight: "auto" }}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : selectedPost.media_type === "IMAGE" ? (
                <img
                  src={selectedPost.media_url}
                  alt={selectedPost.caption}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : selectedPost.media_type === "VIDEO" ? (
                <video
                  autoPlay
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <source src={selectedPost.media_url} type="video/mp4" />
                  <p className="color-black1">
                    Your browser does not support the video tag.
                  </p>
                </video>
              ) : null}
            </div>
            <div
              style={{ flex: "1", display: "flex", flexDirection: "column" }}
            >
              <div
                className="d-flex border border-start-0 border-end-0 border-top-0 border-grey rounded-top align-items-center p-3"
                style={{ width: "100%" }}
              >
                <img
                  src={profilePic}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  alt="Profile Pic"
                />
                <div className="ms-3">
                  <p className="font-proximanova-bold color-black mb-0">
                    {selectedPost.username}
                  </p>
                  <p className="font-proximanova-bold color-black mb-0">
                    {formatTimestamp(selectedPost.timestamp)}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column p-3">
                <p className="font-proximanova-light color-black mb-0">
                  {selectedPost.caption ? selectedPost.caption : "No caption"}
                </p>
                <div className="d-flex flex-column mt-3">
                  {comments &&
                    comments.map((comment) => (
                      <div
                        key={comment.id}
                        className="d-flex align-items-center"
                        style={{ gap: "1rem" }}
                      >
                        <img
                          src={profilePic}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                          alt="Profile Pic"
                        />
                        <p className="font-proximanova-light color-black mb-0">
                          <span className="font-proximanova-bold">
                            {comment.username}
                          </span>{" "}
                          {comment.text}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "transparent",
            border: "none",
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </Modal>
    );
  };

  const renderMobileModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Post Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        }}
      >
        {selectedPost && (
          <>
            <div
              style={{
                backgroundColor: "black",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              {selectedPost.media_type === "CAROUSEL_ALBUM" ? (
                <Carousel
                  showArrows={true}
                  showStatus={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  interval={3000}
                  centerMode={true}
                  centerSlidePercentage={100}
                  width="100%"
                  height="100%"
                  dynamicHeight={true}
                  emulateTouch={true}
                  swipeable={true}
                >
                  {selectedPost.children.data.map((child) => (
                    <div key={child.id}>
                      <img
                        src={child.media_url}
                        alt={selectedPost.caption}
                        className="img-fluid"
                        style={{ width: "100%", maxHeight: "auto" }}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : selectedPost.media_type === "IMAGE" ? (
                <img
                  src={selectedPost.media_url}
                  alt={selectedPost.caption}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : selectedPost.media_type === "VIDEO" ? (
                <video
                  autoPlay
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <source src={selectedPost.media_url} type="video/mp4" />
                  <p className="color-black1">
                    Your browser does not support the video tag.
                  </p>
                </video>
              ) : null}
            </div>
          </>
        )}
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "transparent",
            border: "none",
            position: "absolute",
            top: "10px",
            right: "5%",
            cursor: "pointer",
            color: "white",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </Modal>
    );
  };

  return useWindowSize() ? renderMobileModal() : renderDesktopModal();
};

export default InstaModal;
