import React, { useState } from "react";
import servicesImage from "../../../assets/images/services.jpeg";
import arrowUp from "../../../assets/images/utils/arrowup.png";
import arrowDown from "../../../assets/images/utils/arrowdown.png";
import CustomBtn from "../../commons/buttons/customBtn";
import "./services.css";
import useWindowSize from "../../../hooks/useWindowSize";
import { Container, Col, Row } from "react-bootstrap";

function Services() {
  return useWindowSize() ? <MobileServices /> : <DesktopServices />;
}
const MobileServices = () => {
  const [activeServiceId, setActiveServiceId] = useState(null);
  const services = [
    {
      id: 1,
      number: "01",
      UrlID: "kitchens",
      title: "kitchens",
      description:
        "Discover Topcab's kitchen collection: from traditional to contemporary, basic to custom-made, each design invites simplicity and meticulous attention, crafted just for you.",
    },
    {
      id: 2,
      number: "02",
      UrlID: "wardrobes",
      title: "wardrobes",
      description:
        "Explore our wardrobe section, where elegance meets functionality with custom designs tailored to your storage and style preferences.",
    },
    {
      id: 3,
      number: "03",
      UrlID: "vanities",
      title: "vanities",
      description:
        "Check out our Line of vanities offering a fusion of style and practicality with bespoke options designed to enhance your bathroom's aesthetic and efficiency.",
    },
    {
      id: 4,
      number: "04",
      UrlID: "tv-units",
      title: "tv units",
      description:
        "Browse our TV units range, where sophisticated design meets everyday functionality, tailored to fit your living space and style.",
    },
  ];

  const handleServiceClick = (serviceId) => {
    window.location.href = `/services#${serviceId}`;
  };

  const handleMouseEnter = (serviceId) => {
    setActiveServiceId(serviceId);
  };

  const handleMouseLeave = () => {
    setActiveServiceId(null);
  };

  return (
    <Container
      id="services"
      style={{ marginTop: "10rem", marginBottom: "10rem" }}
    >
      <Row>
        <Col>
          <div className="services__header pb-5">
            <h2 className="display-1 text-uppercase font-miracle">services</h2>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="d-flex justify-content-center align-items-center">
          <img src={servicesImage} className="img-fluid" alt="img" />
        </Col>
      </Row>
      <Row className="d-flex flex-column">
        {services.map((service, index) => (
          <Col key={service.id}>
            <div
              className={`service-mob h-100 w-100 d-dlex justify-content-center align-items-center ${
                index === 0
                  ? " border-top-1 border-bottom-1"
                  : "border-bottom-1"
              }`}
              onClick={() => handleServiceClick(service.UrlID)}
              onMouseEnter={() => handleMouseEnter(service.id)}
              onMouseLeave={handleMouseLeave}
              onTouchStart={() => handleMouseEnter(service.id)}
              onTouchEnd={handleMouseLeave}
              style={{
                cursor: "pointer",
                transition: "background-color 0.5s",
              }}
            >
              <Row
                className={`${activeServiceId === service.id ? "d-none" : ""}`}
              >
                <Col className="d-flex flex-column pt-4">
                  <Row>
                    <Col
                      xs={2}
                      className="d-flex align-items-start justify-content-center h-100"
                    >
                      <h3
                        className="color-grey font-miracle"
                        style={{ paddingTop: "2px", paddingLeft: "1px" }}
                      >
                        {service.number}
                      </h3>
                    </Col>
                    <Col>
                      <h3 className="font-proximanova-semibold color-grey text-uppercase display-5">
                        {service.title}
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-3">
                      <p className="color-grey font-proximanova-light px-2">
                        {service.description}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {activeServiceId === service.id && (
                <Row className="p-4">
                  <Col>
                    <Row>
                      <Col xs={2}></Col>
                      <Col>
                        <h2 className="font-proximanova-semibold color-black1 text-uppercase display-5">
                          {service.title}
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={2}
                        className="d-flex align-items-center justify-content-center h-100"
                      >
                        <h3 className="color-black1 font-miracle display-3">
                          {service.number}
                        </h3>
                      </Col>
                      <Col>
                        <p className="color-black1 font-proximanova-light">
                          {service.description}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const DesktopServices = () => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);

  const handleServiceClick = (serviceId) => {
    window.location.href = `/services#${serviceId}`;
  };

  return (
    <div id="services" className="container my-10">
      <div className="row">
        <div className="services__header pb-5">
          <h2 className="services__title">services</h2>
        </div>
        <div className="services__content">
          <div className="services__left">
            <img src={servicesImage} alt="img" />
          </div>
          <div className="services__right">
            <div
              className="service"
              onClick={() => handleServiceClick("kitchens")}
              onMouseEnter={() => {
                setActive1(true);
              }}
              onMouseLeave={() => {
                setActive1(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`service__container ${
                  active1 ? "d-none" : "d-grid"
                }`}
                // onMouseEnter={() => {
                //   setActive1(true);
                // }}
                // onMouseLeave={() => {
                //   setActive1(false);
                // }}
              >
                <div className="service__up">
                  <span className="service__num">
                    <sup>01</sup>
                  </span>
                  <span className="service__title">kitchens</span>
                </div>
                <div className="service__down">
                  <span className="service__description">
                    Discover Topcab's kitchen collection: from traditional to
                    contemporary, basic to custom-made, each design invites
                    simplicity and meticulous attention, crafted just for you.
                  </span>
                </div>
                <div className="service__img">
                  <img src={arrowUp} alt="Arrow" />
                </div>
              </div>
              <div
                className={`service__container__active ${
                  active1 ? "d-grid" : "d-none"
                }`}
              >
                <div className="service__num__active">
                  <span>01</span>
                </div>
                <div className="service__title__active">
                  <span>kitchens</span>
                </div>
                <div className="service__desc__active">
                  <span>
                    Discover Topcab's kitchen collection: from traditional to
                    contemporary, basic to custom-made, each design invites
                    simplicity and meticulous attention, crafted just for you.
                  </span>
                </div>
                <div className="service__img__active">
                  <img src={arrowDown} alt="Arrow" />
                </div>
              </div>
            </div>
            <div
              className="service"
              onClick={() => handleServiceClick("wardrobes")}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => {
                setActive2(true);
              }}
              onMouseLeave={() => {
                setActive2(false);
              }}
            >
              <div
                className={`service__container ${
                  active2 ? "d-none" : "d-grid"
                }`}
                // onMouseEnter={() => {
                //   setActive2(true);
                // }}
                // onMouseLeave={() => {
                //   setActive2(false);
                // }}
              >
                <div className="service__up">
                  <span className="service__num">
                    <sup>02</sup>
                  </span>
                  <span className="service__title">wardrobes</span>
                </div>
                <div className="service__down">
                  <span className="service__description">
                    Explore our wardrobe section, where elegance meets
                    functionality with custom designs tailored to your storage
                    and style preferences.
                  </span>
                </div>
                <div className="service__img">
                  <img src={arrowUp} alt="Arrow" />
                </div>
              </div>
              <div
                className={`service__container__active ${
                  active2 ? "d-grid" : "d-none"
                }`}
              >
                <div className="service__num__active">
                  <span>02</span>
                </div>
                <div className="service__title__active">
                  <span>wardrobes</span>
                </div>
                <div className="service__desc__active">
                  <span>
                    Explore our wardrobe section, where elegance meets
                    functionality with custom designs tailored to your storage
                    and style preferences.
                  </span>
                </div>
                <div className="service__img__active">
                  <img src={arrowDown} alt="Arrow" />
                </div>
              </div>
            </div>
            <div
              className="service"
              onClick={() => handleServiceClick("vanities")}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => {
                setActive3(true);
              }}
              onMouseLeave={() => {
                setActive3(false);
              }}
            >
              <div
                className={`service__container ${
                  active3 ? "d-none" : "d-grid"
                }`}
                // onMouseEnter={() => {
                //   setActive3(true);
                // }}
                // onMouseLeave={() => {
                //   setActive3(false);
                // }}
              >
                <div className="service__up">
                  <span className="service__num">
                    <sup>03</sup>
                  </span>
                  <span className="service__title">vanities</span>
                </div>
                <div className="service__down">
                  <span className="service__description">
                    Check out our Line of vanities offering a fusion of style
                    and practicality with bespoke options designed to enhance
                    your bathroom's aesthetic and efficiency.
                  </span>
                </div>
                <div className="service__img">
                  <img src={arrowUp} alt="Arrow" />
                </div>
              </div>
              <div
                className={`service__container__active ${
                  active3 ? "d-grid" : "d-none"
                }`}
              >
                <div className="service__num__active">
                  <span>03</span>
                </div>
                <div className="service__title__active">
                  <span>vanities</span>
                </div>
                <div className="service__desc__active">
                  <span>
                    Check out our Line of vanities offering a fusion of style
                    and practicality with bespoke options designed to enhance
                    your bathroom's aesthetic and efficiency.
                  </span>
                </div>
                <div className="service__img__active">
                  <img src={arrowDown} alt="Arrow" />
                </div>
              </div>
            </div>
            <div
              className="service"
              onClick={() => handleServiceClick("tv-units")}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => {
                setActive4(true);
              }}
              onMouseLeave={() => {
                setActive4(false);
              }}
            >
              <div
                className={`service__container ${
                  active4 ? "d-none" : "d-grid"
                }`}
                // onMouseEnter={() => {
                //   setActive4(true);
                // }}
                // onMouseLeave={() => {
                //   setActive4(false);
                // }}
              >
                <div className="service__up">
                  <span className="service__num">
                    <sup>04</sup>
                  </span>
                  <span className="service__title">tv units</span>
                </div>
                <div className="service__down">
                  <span className="service__description">
                    Browse our TV units range, where sophisticated design meets
                    everyday functionality, tailored to fit your iving space and
                    style.
                  </span>
                </div>
                <div className="service__img">
                  <img src={arrowUp} alt="Arrow" />
                </div>
              </div>
              <div
                className={`service__container__active ${
                  active4 ? "d-grid" : "d-none"
                }`}
              >
                <div className="service__num__active">
                  <span>04</span>
                </div>
                <div className="service__title__active">
                  <span>tv units</span>
                </div>
                <div className="service__desc__active">
                  <span>
                    Browse our TV units range, where sophisticated design meets
                    everyday functionality, tailored to fit your iving space and
                    style.
                  </span>
                </div>
                <div className="service__img__active">
                  <img src={arrowDown} alt="Arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row mt-5 d-flex justify-content-center align-items-center"
        style={{ marginRight: "1rem" }}
      >
        <CustomBtn
          text="view more"
          initColor="black"
          onHoverBgColor="white"
          onHoverTxtColor="var(--color-grey)"
          width={"300px"}
          onClick={() => {
            window.location.href = "/services#main";
          }}
        />
      </div>
    </div>
  );
};

export default Services;
