import React from "react";
import ThankYouComponent from "../components/thank-you/thankYou";

const ThankYouPage = () => {
  return (
    <>
      <ThankYouComponent />
    </>
  );
};

export default ThankYouPage;
