import React, { useState } from "react";
import Quotes from "../../assets/images/utils/quotes.svg";
// import SmallArrow from "../../assets/images/utils/smallArrow.svg";
import "./testimonials.css";
import { Container, Row, Col } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";

function Testimonials() {
  return useWindowSize() ? <TestimonialsMobile /> : <TestimonialsDesktop />;
}

function TestimonialsDesktop() {
  const [isPrevHovered, setIsPrevHovered] = useState(false);
  const [isNextHovered, setIsNextHovered] = useState(false);

  const testimonials = [
    {
      quote:
        "Topcab did an amazing job with our kitchen and wardrobe. The quality is top-notch and everything looks perfect. They really listened to what we wanted and delivered beautifully.",
      author: "Cheryl and Tony",
      role: "Home Owners",
    },
    {
      quote:
        "We were very impressed with the quality of work and the professionalism of the team. We would definitely recommend Topcab to anyone looking for a reliable and professional carpentry service.",
      author: "Charbel Tamer",
      role: "HEXCODE CEO",
    },
    {
      quote:
        "We are very happy with the work done by Topcab. They were very professional and the quality of work was excellent. We would definitely recommend them to anyone looking for a reliable carpentry service.",
      author: "John and Mary",
      role: "Landlords",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div id="testimonials" style={{marginTop:"10rem", marginBottom:"5rem"}}>
      <div className="container position-relative d-flex align-items-center justify-content-center h-100">
        <p className="color-white font-proximanova-light position-absolute top-0 start-0">
          OUR CLIENTS SAY
        </p>
        <div
          className="d-flex flex-column justify-content-center position-relative w-50 mt-5"
          style={{ height: "350px" }}
        >
          <div className="h-100">
            <img
              src={Quotes}
              alt="quotes"
              className="position-absolute top-0 w-50"
              style={{ left: "-25%" }}
            />
          </div>
          <p
            className="color-white font-proximanova-light"
            style={{ fontSize: "20px" }}
          >
            {testimonials[currentTestimonial].quote}
          </p>
          <div className="mt-5">
            <h4
              className="color-grey font-proximanova-bold"
              style={{ fontSize: "20px" }}
            >
              {testimonials[currentTestimonial].author}
            </h4>
            <p
              className="color-grey font-proximanova-semibold"
              style={{ fontSize: "16px" }}
            >
              {testimonials[currentTestimonial].role}
            </p>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center mt-5">
        <div className="w-auto">
          <button
            className={`testimanials-button p-3 me-4 ${
              isPrevHovered ? "hovered" : ""
            }`}
            style={{
              borderColor: "var(--color-grey)",
              backgroundColor: "transparent",
            }}
            onClick={prevTestimonial}
            onMouseEnter={() => setIsPrevHovered(true)}
            onMouseLeave={() => setIsPrevHovered(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.47"
              height="23.181"
              viewBox="0 0 28.47 23.181"
              style={{ rotate: "180deg" }}
            >
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(0 0.283)"
              >
                <path
                  id="Line_1"
                  data-name="Line 1"
                  d="M28.265.4H0V-.4H28.265Z"
                  transform="translate(0 10.892)"
                  fill="#707070"
                />
                <path
                  id="Path_223"
                  data-name="Path 223"
                  d="M15.992,16.392H-.4V0H.4V15.592H15.992Z"
                  transform="translate(16.597 22.616) rotate(-135)"
                  fill="#707070"
                />
              </g>
            </svg>
          </button>
          <button
            className={`testimanials-button p-3 ${
              isNextHovered ? "hovered" : ""
            }`}
            style={{
              borderColor: "var(--color-grey)",
              backgroundColor: "transparent",
            }}
            onClick={nextTestimonial}
            onMouseEnter={() => setIsNextHovered(true)}
            onMouseLeave={() => setIsNextHovered(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.47"
              height="23.181"
              viewBox="0 0 28.47 23.181"
            >
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(0 0.283)"
              >
                <path
                  id="Line_1"
                  data-name="Line 1"
                  d="M28.265.4H0V-.4H28.265Z"
                  transform="translate(0 10.892)"
                  fill="#707070"
                />
                <path
                  id="Path_223"
                  data-name="Path 223"
                  d="M15.992,16.392H-.4V0H.4V15.592H15.992Z"
                  transform="translate(16.597 22.616) rotate(-135)"
                  fill="#707070"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

function TestimonialsMobile() {
  const [isPrevHovered, setIsPrevHovered] = useState(false);
  const [isNextHovered, setIsNextHovered] = useState(false);

  const testimonials = [
    {
      quote:
        "Topcab did an amazing job with our kitchen and wardrobe. The quality is top-notch and everything looks perfect. They really listened to what we wanted and delivered beautifully.",
      author: "Cheryl and Tony",
      role: "Home Owners",
    },
    {
      quote:
        "We were very impressed with the quality of work and the professionalism of the team. We would definitely recommend Topcab to anyone looking for a reliable and professional carpentry service.",
      author: "Tom Hanks",
      role: "Company CEO",
    },
    {
      quote:
        "We are very happy with the work done by Topcab. They were very professional and the quality of work was excellent. We would definitely recommend them to anyone looking for a reliable carpentry service.",
      author: "John and Mary",
      role: "Landlords",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <Container id="testimonials" style={{marginTop:"10rem", marginBottom:"5rem"}}>
      <Row className="d-flex align-items-center justify-content-center p-1">
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-start"
        >
          <p className="color-white font-proximanova-light ms-3">
            OUR CLIENTS SAY
          </p>
        </Col>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-start mt-3"
        >
          <div className="d-flex flex-column justify-content-center w-100">
            <div className="h-100">
              <img
                src={Quotes}
                alt="quotes"
                className="position-absolute top-5 w-50"
              />
            </div>
            <p className="color-white font-proximanova-light mt-5 pt-5 ms-3">
              {testimonials[currentTestimonial].quote}
            </p>
            <div className="mt-3 ms-3">
              <h4 className="color-grey font-proximanova-bold">
                {testimonials[currentTestimonial].author}
              </h4>
              <p className="color-grey font-proximanova-semibold">
                {testimonials[currentTestimonial].role}
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center mt-5"
        >
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <button
              className={`testimanials-button p-3 me-4 ${
                isPrevHovered ? "hovered" : ""
              }`}
              style={{
                borderColor: "var(--color-grey)",
                backgroundColor: "transparent",
              }}
              onClick={prevTestimonial}
              onMouseEnter={() => setIsPrevHovered(true)}
              onMouseLeave={() => setIsPrevHovered(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.47"
                height="23.181"
                viewBox="0 0 28.47 23.181"
                style={{ rotate: "180deg" }}
              >
                <g
                  id="Group_6"
                  data-name="Group 6"
                  transform="translate(0 0.283)"
                >
                  <path
                    id="Line_1"
                    data-name="Line 1"
                    d="M28.265.4H0V-.4H28.265Z"
                    transform="translate(0 10.892)"
                    fill="#707070"
                  />
                  <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M15.992,16.392H-.4V0H.4V15.592H15.992Z"
                    transform="translate(16.597 22.616) rotate(-135)"
                    fill="#707070"
                  />
                </g>
              </svg>
            </button>
            <button
              className={`testimanials-button p-3 ${
                isNextHovered ? "hovered" : ""
              }`}
              style={{
                borderColor: "var(--color-grey)",
                backgroundColor: "transparent",
              }}
              onClick={nextTestimonial}
              onMouseEnter={() => setIsNextHovered(true)}
              onMouseLeave={() => setIsNextHovered(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.47"
                height="23.181"
                viewBox="0 0 28.47 23.181"
              >
                <g
                  id="Group_6"
                  data-name="Group 6"
                  transform="translate(0 0.283)"
                >
                  <path
                    id="Line_1"
                    data-name="Line 1"
                    d="M28.265.4H0V-.4H28.265Z"
                    transform="translate(0 10.892)"
                    fill="#707070"
                  />
                  <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M15.992,16.392H-.4V0H.4V15.592H15.992Z"
                    transform="translate(16.597 22.616) rotate(-135)"
                    fill="#707070"
                  />
                </g>
              </svg>
            </button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Testimonials;
