import React, { useState, useEffect } from "react";
import AboutUsMain from "../../assets/images/aboutUs-main.png";
import Image2 from "../../assets/images/aboutUs2.png";
import Image3 from "../../assets/images/aboutUs3.png";
import useWindowSize from "../../hooks/useWindowSize";
import "./main.css";

function Main() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const AboutUsDesktop = () => {
    return (
      <div
        id="aboutUs-main"
        className="my-10 h-100"
        style={{ maxHeight: "100vh" }}
      >
        <div className="container d-flex" style={{ height: "100%" }}>
          <div className="col h-100 me-5">
            <div className="row border border-start-0 border-end-0 border-secondary p-5">
              <div className="row pb-4">
                <div className="container d-flex">
                  <div className="col">
                    <sup className="font-proximanova-light">01</sup>
                  </div>
                  <div className="col-11">
                    <h3 className="h1 color-white font-miracle">
                      OUR MISSION.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row flex-grow-1">
                <p className="font-proximanova-light color-grey">
                  To provide top-quality, customizable living solutions that
                  blend innovative designs with functionality, ensuring every
                  client’s space is both beautiful and practical, using a range
                  of durable materials.
                </p>
              </div>
            </div>
            <div className="row border border-start-0 border-end-0 border-top-0 border-secondary p-5">
              <div className="row pb-4">
                <div className="container d-flex">
                  <div className="col">
                    <sup className="font-proximanova-light">02</sup>
                  </div>
                  <div className="col-11">
                    <h3 className="h1 color-white font-miracle">OUR VISION.</h3>
                  </div>
                </div>
              </div>
              <div className="row flex-grow-1">
                <p className="font-proximanova-light color-grey">
                  To be the leading choice for bespoke home improvements,
                  renowned for transforming spaces into personalized, stylish,
                  and functional areas that clients love to live in.
                </p>
              </div>
            </div>
            <div className="row border border-start-0 border-end-0 border-top-0 border-secondary p-5">
              <div className="row pb-4">
                <div className="container d-flex">
                  <div className="col">
                    <sup className="font-proximanova-light">03</sup>
                  </div>
                  <div className="col-11">
                    <h3 className="h1 color-white font-miracle">OUR VALUES.</h3>
                  </div>
                </div>
              </div>
              <div className="row flex-grow-1">
                <p className="font-proximanova-light color-grey">
                  We value craftsmanship, customer satisfaction, and innovation.
                  We’re committed to delivering excellence in every project,
                  listening to our clients’ needs, and continuously exploring
                  new designs and materials.
                </p>
              </div>
            </div>
          </div>
          <div className="col about-us-carousel me-5">
            <img
              src={AboutUsMain}
              alt="About Us"
              className={`img-fluid ${activeIndex === 0 ? "active" : ""}`}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            />
            <img
              src={Image2}
              alt="About Us"
              className={`img-fluid ${activeIndex === 1 ? "active" : ""}`}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            />
            <img
              src={Image3}
              alt="About Us"
              className={`img-fluid ${activeIndex === 2 ? "active" : ""}`}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const AboutUsMobile = () => {
    return (
      <div
        id="aboutUs-main"
        className="h-100 px-3"
        style={{ marginTop: "10rem", marginBottom: "5rem" }}
      >
        <div className="container d-flex flex-column">
          <div className="row border border-start-0 border-end-0 border-secondary py-5">
            <div className="row pb-4">
              <div className="container d-flex">
                <div className="col">
                  <sup className="font-proximanova-light">01</sup>
                </div>
                <div className="col-11">
                  <h3 className="h1 color-white font-miracle">OUR MISSION.</h3>
                </div>
              </div>
            </div>
            <div className="row flex-grow-1">
              <p className="font-proximanova-light color-grey">
                To provide top-quality, customizable living solutions that blend
                innovative designs with functionality, ensuring every client’s
                space is both beautiful and practical, using a range of durable
                materials.
              </p>
            </div>
          </div>
          <div className="row border border-start-0 border-end-0 border-secondary py-5">
            <div className="row pb-4">
              <div className="container d-flex">
                <div className="col">
                  <sup className="font-proximanova-light">02</sup>
                </div>
                <div className="col-11">
                  <h3 className="h1 color-white font-miracle">OUR VISION.</h3>
                </div>
              </div>
            </div>
            <div className="row flex-grow-1">
              <p className="font-proximanova-light color-grey">
                To be the leading choice for bespoke home improvements, renowned
                for transforming spaces into personalized, stylish, and
                functional areas that clients love to live in.
              </p>
            </div>
          </div>
          <div className="row border border-start-0 border-end-0 border-secondary py-5">
            <div className="row pb-4">
              <div className="container d-flex">
                <div className="col">
                  <sup className="font-proximanova-light">03</sup>
                </div>
                <div className="col-11">
                  <h3 className="h1 color-white font-miracle">OUR VALUES.</h3>
                </div>
              </div>
            </div>
            <div className="row flex-grow-1">
              <p className="font-proximanova-light color-grey">
                We value craftsmanship, customer satisfaction, and innovation.
                We’re committed to delivering excellence in every project,
                listening to our clients’ needs, and continuously exploring new
                designs and materials.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return useWindowSize() ? <AboutUsMobile /> : <AboutUsDesktop />;
}
export default Main;
