import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bg from "../assets/images/bg.jpg";

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/notFound");
  }, [navigate]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <h1 className="display-1 font-miracle color-white">
        404 Not Found
      </h1>
    </div>
  );
}

export default NotFound;
