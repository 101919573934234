import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import Accordion from "react-bootstrap/Accordion";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomJoineryEasternSuburbs() {
  const areasWeServe = [
    {
      title: "surry hills",
      href: "/custom-joinery-surry-hills",
    },
    {
      title: "northern beaches",
      href: "/custom-joinery-northern-beaches",
    },
    { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    // { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    { title: "double bay", href: "/custom-joinery-double-bay" },
    { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Your Custom Joinery Experts In The Eastern Suburbs — Topcab
                Kitchens & Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                At <Link to="/">Topcab Kitchens & Joinery</Link>, we take great
                satisfaction in providing the thriving{" "}
                <span>Eastern Suburbs</span> with custom joinery solutions of
                the highest calibre. With years of expertise and a love for fine
                craftsmanship, we provide a variety of services tailored to each
                individual client's demands.{" "}
                <span>Topcab Kitchens & Joinery</span> is your go-to specialist
                whether you're looking to update your commercial space with
                professional <span>shop fittings</span>,{" "}
                <span>custom cabinets or custom joinery</span> in the{" "}
                <span>Eastern Suburbs</span>, or change your{" "}
                <span>bathroom</span> with a magnificent <span>vanity</span>.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105949.80504856004!2d151.22383795!3d-33.9171649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b18db5d79889%3A0xa061091923af585a!2sEastern%20Suburbs%2C%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1721884552761!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Range Of Services Provided By Us
          </h2>
          <h3> Bathroom Vanity Around The Eastern Suburbs </h3>{" "}
          <p>
            {" "}
            A <span>bathroom vanity</span> is a statement of elegance and style
            in addition to being a useful item. Our specialty at{" "}
            <span>Topcab Kitchens & Joinery</span> is creating a custom{" "}
            <span>bathroom vanity</span> that flawlessly combines style and
            functionality. In order to make sure that every aspect of your
            bathroom vanity reflects your unique style and improves the overall
            aesthetics of your space, our staff works closely with you to
            understand your vision and design preferences. We employ premium
            materials and cutting-edge manufacturing processes to create{" "}
            <span>bathroom vanities</span> that are as stunning and long-lasting
            as they are functional, whether they are in traditional or modern
            designs.
          </p>
          <h3> Custom Cabinets To Enhance Your Space </h3>{" "}
          <p>
            Having storage options is crucial to keeping your house neat and
            clutter-free. <span>Topcab Kitchens & Joinery</span> is an expert at
            building <span>custom cabinets in the Eastern Suburbs</span> that
            meet your demands for both space and functionality. Whether for your
            living room, office, bedroom or{" "}
            <Link to="/luxury-kitchens">high end kitchen</Link>, our{" "}
            <span>custom cabinets in the Eastern Suburbs</span> are made to
            match the current style of your house and offer the best possible
            storage. Our extensive selection of materials, finishes and designs
            ensure that your <span>custom cabinets</span> will be both
            aesthetically pleasing and a perfect fit for your interior decor.
          </p>
          <h3 className="h3"> Custom Joinery Solutions </h3>{" "}
          <p>
            We can do more for you than just install cabinets and vanities. To
            improve the aesthetic appeal and usability of your house or place of
            business, <span>Topcab Kitchens & Joinery</span> provides an
            extensive array of <span>custom joinery</span> in the Eastern
            Suburbs. Our <span>custom joinery</span> solutions, which range from
            built-in wardrobes and entertainment units to bespoke shelving and
            one-of-a-kind furniture pieces, are expertly made with close
            attention to detail. We take great satisfaction in our ability to
            create joinery that is customised to your exact specifications,
            making sure that each piece perfectly complements your living area
            and way of life.
          </p>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="">
              <img
                src="/zeal-digital/suburbs.png"
                className="bg-dark rounded-3"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">
                Shop Fittings For The Eastern Suburbs
              </h2>
              <p className="font-proximanova-light zeal-digital">
                First impressions count, particularly in a store setting.{" "}
                <span>Topcab Kitchens & Joinery</span> offers{" "}
                <span>professional shop fittings</span> around the Eastern
                suburbs to improve both the appearance and usability of your
                commercial space. Our specially-designed store fittings are made
                to fit your company's unique requirements, assisting you in
                creating a warm and well-organised retail space that draws in
                and keeps clients. We collaborate with you to design and install{" "}
                <span>store fittings</span> that maximise your available space
                and represent the identity of your business, from shelving and
                display units to counters and storage options.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">
            Find Out Why We&apos;re The Preferred Option
          </h2>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Expert Craftsmanship:</strong> Our team of highly-skilled
              artisans brings a wealth of experience and dedication to every
              project. With meticulous attention to detail, we ensure that each
              piece of joinery we create meets the highest standards of quality
              and precision.{" "}
            </li>
            <li>
              <strong>Custom Solutions:</strong> We provide custom joinery in <Link to="/custom-joinery-double-bay">
                Double
                Bay
              </Link> and <Link to="/custom-joinery-surry-hills">Surry Hills</Link> to match your unique requirements and tastes.
            </li>
            <li>
              <strong>High-Quality Materials:</strong> We feel that the materials
              utilised serve as the foundation for great joinery. That's why we
              only use the best materials for our projects. Our dedication to
              using high-quality wood and other materials ensures that our
              joinery is both aesthetically beautiful and functional.
            </li>
            <li>
              <strong>Professional Service:</strong> From initial consultation to
              final installation, we provide seamless, professional service,
              ensuring your complete satisfaction.
            </li>
            <li>
              <strong>Local Expertise:</strong> As a trusted local business, we
              understand our customers' unique needs and are dedicated to
              serving our community with integrity and excellence.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">Reach Us Anytime</h2>
              <p className="font-proximanova-light zeal-digital">
                Ready to transform your space with <span>custom joinery</span>{" "}
                solutions from <span>Topcab Kitchens & Joinery?</span> Contact
                us today to discuss your joinery project and discover how we can
                bring your vision to life. Whether you need a{" "}
                <span>bathroom vanity, custom cabinets or shop fittings,</span>{" "}
                our team is here to help. Call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to explore our range of services and speak with our experts.
                Experience the <span>Topcab Kitchens & Joinery</span> difference
                – where quality, craftsmanship and customer satisfaction combine
                to create exceptional custom joinery solutions.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Your Custom Joinery Experts In The Eastern Suburbs — Topcab
                Kitchens & Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                At <Link to="/">Topcab Kitchens & Joinery</Link>, we take great
                satisfaction in providing the thriving{" "}
                <span>Eastern Suburbs</span> with custom joinery solutions of
                the highest calibre. With years of expertise and a love for fine
                craftsmanship, we provide a variety of services tailored to each
                individual client's demands.{" "}
                <span>Topcab Kitchens & Joinery</span> is your go-to specialist
                whether you're looking to update your commercial space with
                professional <span>shop fittings</span>,{" "}
                <span>custom cabinets or custom joinery</span> in the{" "}
                <span>Eastern Suburbs</span>, or change your{" "}
                <span>bathroom</span> with a magnificent <span>vanity</span>.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105949.80504856004!2d151.22383795!3d-33.9171649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b18db5d79889%3A0xa061091923af585a!2sEastern%20Suburbs%2C%20NSW%2C%20Australia!5e0!3m2!1sen!2sin!4v1721884552761!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            Range Of Services Provided By Us
          </h2>
          <h3> Bathroom Vanity Around The Eastern Suburbs </h3>{" "}
          <p>
            {" "}
            A <span>bathroom vanity</span> is a statement of elegance and style
            in addition to being a useful item. Our specialty at{" "}
            <span>Topcab Kitchens & Joinery</span> is creating a custom{" "}
            <span>bathroom vanity</span> that flawlessly combines style and
            functionality. In order to make sure that every aspect of your
            bathroom vanity reflects your unique style and improves the overall
            aesthetics of your space, our staff works closely with you to
            understand your vision and design preferences. We employ premium
            materials and cutting-edge manufacturing processes to create{" "}
            <span>bathroom vanities</span> that are as stunning and long-lasting
            as they are functional, whether they are in traditional or modern
            designs.
          </p>
          <h3> Custom Cabinets To Enhance Your Space </h3>{" "}
          <p>
            Having storage options is crucial to keeping your house neat and
            clutter-free. <span>Topcab Kitchens & Joinery</span> is an expert at
            building <span>custom cabinets in the Eastern Suburbs</span> that
            meet your demands for both space and functionality. Whether for your
            living room, office, bedroom or{" "}
            <Link to="/luxury-kitchens">high end kitchen</Link>, our{" "}
            <span>custom cabinets in the Eastern Suburbs</span> are made to
            match the current style of your house and offer the best possible
            storage. Our extensive selection of materials, finishes and designs
            ensure that your <span>custom cabinets</span> will be both
            aesthetically pleasing and a perfect fit for your interior decor.
          </p>
          <h3 className="h3"> Custom Joinery Solutions </h3>{" "}
          <p>
            We can do more for you than just install cabinets and vanities. To
            improve the aesthetic appeal and usability of your house or place of
            business, <span>Topcab Kitchens & Joinery</span> provides an
            extensive array of <span>custom joinery</span> in the Eastern
            Suburbs. Our <span>custom joinery</span> solutions, which range from
            built-in wardrobes and entertainment units to bespoke shelving and
            one-of-a-kind furniture pieces, are expertly made with close
            attention to detail. We take great satisfaction in our ability to
            create joinery that is customised to your exact specifications,
            making sure that each piece perfectly complements your living area
            and way of life.
          </p>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="order-0 order-lg-1">
              <img
                src="/zeal-digital/suburbs.png"
                className="bg-dark rounded-3 d-block mx-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center order-1 order-lg-0">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Shop Fittings For The Eastern Suburbs
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                First impressions count, particularly in a store setting.{" "}
                <span>Topcab Kitchens & Joinery</span> offers{" "}
                <span>professional shop fittings</span> around the Eastern
                suburbs to improve both the appearance and usability of your
                commercial space. Our specially-designed store fittings are made
                to fit your company's unique requirements, assisting you in
                creating a warm and well-organised retail space that draws in
                and keeps clients. We collaborate with you to design and install{" "}
                <span>store fittings</span> that maximise your available space
                and represent the identity of your business, from shelving and
                display units to counters and storage options.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">
            Find Out Why We&apos;re The Preferred Option
          </h2>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
          <li>
              <strong>Expert Craftsmanship:</strong> Our team of highly-skilled
              artisans brings a wealth of experience and dedication to every
              project. With meticulous attention to detail, we ensure that each
              piece of joinery we create meets the highest standards of quality
              and precision.{" "}
            </li>
            <li>
              <strong>Custom Solutions:</strong> We provide custom joinery in <Link to="/custom-joinery-double-bay">
                Double
                Bay
              </Link> and <Link to="/custom-joinery-surry-hills">Surry Hills</Link> to match your unique requirements and tastes.
            </li>
            <li>
              <strong>High-Quality Materials:</strong> We feel that the materials
              utilised serve as the foundation for great joinery. That's why we
              only use the best materials for our projects. Our dedication to
              using high-quality wood and other materials ensures that our
              joinery is both aesthetically beautiful and functional.
            </li>
            <li>
              <strong>Professional Service:</strong> From initial consultation to
              final installation, we provide seamless, professional service,
              ensuring your complete satisfaction.
            </li>
            <li>
              <strong>Local Expertise:</strong> As a trusted local business, we
              understand our customers' unique needs and are dedicated to
              serving our community with integrity and excellence.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Reach Us Anytime
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Ready to transform your space with <span>custom joinery</span>{" "}
                solutions from <span>Topcab Kitchens & Joinery?</span> Contact
                us today to discuss your joinery project and discover how we can
                bring your vision to life. Whether you need a{" "}
                <span>bathroom vanity, custom cabinets or shop fittings,</span>{" "}
                our team is here to help. Call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to explore our range of services and speak with our experts.
                Experience the <span>Topcab Kitchens & Joinery</span> difference
                – where quality, craftsmanship and customer satisfaction combine
                to create exceptional custom joinery solutions.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
