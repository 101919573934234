import { Link } from "react-router-dom";
import useWindowSize from "../../src/hooks/useWindowSize";
import Form from "../components/contact-us/form";

export const pagesData = [
  {
    category: "Custom Joinery",
    buttons: [
      {
        title: "surry hills",
        href: "/custom-joinery-surry-hills",
      },
      {
        title: "eastern suburbs",
        href: "/custom-joinery-eastern-suburbs",
      },
      { title: "vaucluse", href: "/custom-joinery-vaucluse" },
      { title: "brookvale", href: "/custom-joinery-brookvale" },
      { title: "double bay", href: "/custom-joinery-double-bay" },
      {
        title: "northern beaches",
        href: "/custom-joinery-northern-beaches",
      },
    ],
  },
  {
    category: "Wardrobes",
    buttons: [
      { title: "maroubra", href: "/wardrobes-maroubra" },
      {
        title: "wetherill park",
        href: "/wardrobes-wetherill-park",
      },
      { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
      { title: "coogee", href: "/wardrobes-coogee" },
      { title: "rose bay", href: "/wardrobes-rose-bay" },
    ],
  },
  {
    category: "Joinery",
    buttons: [
      { title: "bondi", href: "/joinery-bondi" },
      { title: "dover heights", href: "/joinery-dover-heights" },
    ],
  },
  {
    category: "Kitchen Renovations",
    buttons: [
      {
        title: "woollahra",
        href: "/kitchens-renovations-woollahra",
      },
      {
        title: "randwick",
        href: "/kitchen-renovations-randwick",
      },
    ],
  },
  {
    category: "Cabinets",
    buttons: [
      // { title: "luxury kitchens", href: "/luxury-kitchens" },
      // { title: "tv units", href: "/tv-units" },
      { title: "mona vale", href: "/cabinets-mona-vale" },
    ],
  },
];
export default function ServiceArea() {
  const ServiceAreaDesktop = () => {
    return (
      <>
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container text-white d-grid align-items-center justify-content-center"
        >
          <div className="">
            <h1 className="text-center display-5 font-miracle">
              Service Areas
            </h1>
            <div className="d-flex justify-content-center ">
              Home &nbsp; &gt;&gt; &nbsp;{" "}
              <span className="text-secondary">Service Areas</span>{" "}
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-5 mb-5 text-capitalize">
            Areas we serve
          </h2>
          {pagesData?.map((elemOuter) => {
            return (
              <div key={elemOuter.category} className="mb-4">
                <h3 className="font-miracle text-center mb-2">
                  {" "}
                  {elemOuter.category}{" "}
                </h3>
                <div className="d-flex flex-wrap gap-4 justify-content-center">
                  {elemOuter?.buttons?.map((elemInner) => (
                    <Link
                      to={elemInner.href}
                      key={elemInner.title}
                      className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
                      style={{ fontSize: "14px" }}
                    >
                      {elemInner.title}
                    </Link>
                  ))}
                </div>
              </div>
            );
          })}
        </section>
        <Form />
      </>
    );
  };

  const ServiceAreaMobile = () => {
    return (
      <>
        <section
          style={{ paddingTop: "10rem", paddingBottom: "2rem" }}
          className="container text-white d-grid align-items-center justify-content-center"
        >
          <div className="">
            <h1 className="text-center display-5 font-miracle">
              Service Areas
            </h1>
            <div className="d-flex justify-content-center ">
              Home &nbsp; &gt;&gt; &nbsp;{" "}
              <span className="text-secondary">Service Areas</span>{" "}
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-5 mb-5 text-capitalize">
            Areas we serve
          </h2>
          {pagesData?.map((elemOuter) => {
            return (
              <div key={elemOuter.category} className="mb-4">
                <h3 className="font-miracle text-center mb-2">
                  {" "}
                  {elemOuter.category}{" "}
                </h3>
                <div className="mobile-grid-service-area justify-items-center gap-4 d-grid">
                  {elemOuter?.buttons?.map((elemInner) => (
                    <Link
                      to={elemInner.href}
                      key={elemInner.title}
                      className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
                    >
                      {elemInner.title}
                    </Link>
                  ))}
                </div>
              </div>
            );
          })}
        </section>
        <Form />
      </>
    );
  };

  return useWindowSize() ? <ServiceAreaMobile /> : <ServiceAreaDesktop />;
}
