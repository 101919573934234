import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function CustomJoinerySurryHills() {
  const areasWeServe = [
    // {
    //   title: "surry hills",
    //   href: "/custom-joinery-surry-hills",
    // },
    {
      title: "northern beaches",
      href: "/custom-joinery-northern-beaches",
    },
    { title: "vaucluse", href: "/custom-joinery-vaucluse" },
    { title: "eastern suburbs", href: "/custom-joinery-eastern-suburbs" },
    { title: "double bay", href: "/custom-joinery-double-bay" },
    { title: "brookvale", href: "/custom-joinery-brookvale" },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Topcab Kitchens & Joinery: Premier Joinery Services Around Surry
                Hills
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                Topcab Kitchens & Joinery is your reliable source for top
                joinery solutions in Surry Hills. With a dedication to great
                craftsmanship and exceptional customer service, we provide a
                variety of personalised services tailored to your specific
                requirements. From custom bathroom vanities to elegant shop
                fittings and efficient closets,{" "}
                <Link to="https://topcabjoinery.com.au/">
                  Topcab Kitchens & Joinery
                </Link>{" "}
                is your one-stop shop for all your joinery needs in Surry Hills.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13248.808715028777!2d151.1997324929347!3d-33.884446508016644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae21f935bc5b%3A0x5017d681632cc90!2sSurry%20Hills%20NSW%202010%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012157444!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">Our Joinery Services</h2>
          <h3> Custom Bathroom Vanity In Surry Hills </h3>{" "}
          <p>
            At Topcab Kitchens & Joinery, we specialise in designing and
            creating stunning bathroom vanities that add a touch of luxury and
            functionality to your space. Our expert team works closely with you
            to understand your vision and transform it into a reality. Whether
            you prefer a classic, modern or contemporary design, we ensure every
            bathroom vanity in Surry Hills is crafted with precision and
            high-quality materials.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Custom Designs</strong>: Tailored to your style and space requirements.
              </li>
              <li><strong>Premium Materials</strong>: Durable and stylish finishes.</li>
              <li><strong>Expert Craftsmanship</strong>: Attention to detail in every piece.</li>
            </ul>
          </div>
          <h3> Shop Fittings In Surry Hills </h3>{" "}
          <p>
            Topcab Kitchens & Joinery's bespoke shop fittings can add value to
            your retail area. We realise how important it is to provide a
            welcoming and practical environment for your consumers. Our shop
            fittings in Surry Hills are intended to improve the aesthetic appeal
            of your establishment while maximising space and function. From
            display units to personalised counters, our solutions are ideal for
            creating an unforgettable shopping experience.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Customisable Solutions</strong>: Designed to fit your brand and space.
              </li>
              <li>
                <strong>Quality Construction</strong>: High-quality materials are used to ensure
                long-term durability.
              </li>
              <li>
                <strong>Functional Design</strong>: Optimised for maximum utility and customer
                engagement.
              </li>
            </ul>
          </div>
          <h3> Wardrobes For Your Home In Surry Hills </h3>{" "}
          <p>
            Our custom-built wardrobes will help you maximise your storage
            space. Topcab Kitchens & Joinery provides a variety of wardrobes in
            Surry Hills that are tailored to your unique requirements, including
            walk in closets, built-in wardrobes and freestanding units. Our
            designs are not only utilitarian, but they also offer a stylish
            touch to your bedroom or dressing room.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Tailored to Your Needs</strong>: Custom configurations to suit your
                storage requirements.
              </li>
              <li>
                <strong>High-Quality Finishes</strong>: Choose from a variety of materials and
                finishes.
              </li>
              <li>
                <strong>Efficient Use of Space</strong>: Designed to maximise storage while
                maintaining aesthetics.
              </li>
            </ul>
          </div>
        </section>

        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="">
              <img
                src="/zeal-digital/suburbs.png"
                className="bg-dark rounded-3"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">Our Process</h2>
              <ul className="zeal-digital">
                <li>
                  <strong>Consultation:</strong> We start with a thorough consultation to
                  determine your needs and preferences.
                </li>
                <li>
                  <strong>Design:</strong> Our design team provides thorough plans and 3D
                  renderings to help you envisage your project.
                </li>
                <li>
                  <strong>Fabrication:</strong> We create custom joinery in Surry Hills, as well
                  as the suburbs of{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                    Double Bay
                  </Link>{" "}
                  and{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-brookvale">
                    Brookvale
                  </Link>
                  , using high-quality materials and modern techniques.
                </li>
                <li>
                  <strong>Installation:</strong> Our professional installers will assure a
                  perfect fit and finish, bringing your idea to reality.
                </li>
                <li>
                  <strong>Aftercare:</strong> We provide continuous assistance and maintenance to
                  ensure that your joinery remains in perfect shape.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>
        <section
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="text-white container zeal-digital"
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
          Why Are We The Right Choice For Your Joinery Needs?
          </h2>
        </div>
        <ul>
              <li>
                <h3>Expertise And Experience </h3>
                <p className="font-proximanova-light">Topcab Kitchens & Joinery has years of experience in the joinery sector and has established ourselves as industry leaders in Surry Hills and across NSW. Our professional artisans and designers are dedicated to providing extraordinary outcomes that exceed your expectations. We keep up with the newest trends and techniques to ensure that our solutions are current and original.</p>
              </li>
              <li>
                <h3>Personalised Service</h3>
                <p className="font-proximanova-light">At Topcab Kitchens & Joinery, we take a customer-centric approach. We take the time to understand your individual requirements and preferences, resulting in bespoke solutions that reflect your style and taste. Our team collaborates closely with you from concept to finish, delivering a smooth and enjoyable experience.</p>
              </li>
              <li>
                <h3>Quality Assurance</h3>
                <p className="font-proximanova-light">Quality is at the core of everything we do. We use only the finest materials and employ rigorous quality control measures to ensure every piece we create meets the highest standards. Our dedication to excellence ensures that your bathroom vanity, shop fittings and wardrobes in Surry Hills are not only beautiful but also built to last.</p>
              </li>
              <li>
                <h3>Competitive Pricing</h3>
                <p className="font-proximanova-light">We provide competitive pricing without sacrificing on quality. Our straightforward pricing system ensures that you receive the best value for your money. Whether you need a single bathroom vanity or whole shop fittings in Surry Hills, we have affordable options for you.</p>
              </li>
        </ul>
      </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">Get In Touch With Us</h2>
              <p className="font-proximanova-light zeal-digital">
                Ready to transform your space with Topcab Kitchens & Joinery?
                Contact us today to discuss your project and discover how we can
                help you achieve your vision. Visit our website or call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to schedule a consultation. Experience the difference of bespoke
                joinery with Topcab Kitchens & Joinery!
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Topcab Kitchens & Joinery: Premier Joinery Services Around Surry
                Hills
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                Topcab Kitchens & Joinery is your reliable source for top
                joinery solutions in Surry Hills. With a dedication to great
                craftsmanship and exceptional customer service, we provide a
                variety of personalised services tailored to your specific
                requirements. From custom bathroom vanities to elegant shop
                fittings and efficient closets,{" "}
                <Link to="https://topcabjoinery.com.au/">
                  Topcab Kitchens & Joinery
                </Link>{" "}
                is your one-stop shop for all your joinery needs in Surry Hills.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13248.808715028777!2d151.1997324929347!3d-33.884446508016644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae21f935bc5b%3A0x5017d681632cc90!2sSurry%20Hills%20NSW%202010%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012157444!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">Our Joinery Services</h2>
          <h3> Custom Bathroom Vanity In Surry Hills </h3>{" "}
          <p>
            At Topcab Kitchens & Joinery, we specialise in designing and
            creating stunning bathroom vanities that add a touch of luxury and
            functionality to your space. Our expert team works closely with you
            to understand your vision and transform it into a reality. Whether
            you prefer a classic, modern or contemporary design, we ensure every
            bathroom vanity in Surry Hills is crafted with precision and
            high-quality materials.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Custom Designs</strong>: Tailored to your style and space requirements.
              </li>
              <li><strong>Premium Materials</strong>: Durable and stylish finishes.</li>
              <li><strong>Expert Craftsmanship</strong>: Attention to detail in every piece.</li>
            </ul>
          </div>
          <h3> Shop Fittings In Surry Hills </h3>{" "}
          <p>
            Topcab Kitchens & Joinery's bespoke shop fittings can add value to
            your retail area. We realise how important it is to provide a
            welcoming and practical environment for your consumers. Our shop
            fittings in Surry Hills are intended to improve the aesthetic appeal
            of your establishment while maximising space and function. From
            display units to personalised counters, our solutions are ideal for
            creating an unforgettable shopping experience.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Customisable Solutions</strong>: Designed to fit your brand and space.
              </li>
              <li>
                <strong>Quality Construction</strong>: High-quality materials are used to ensure
                long-term durability.
              </li>
              <li>
                <strong>Functional Design</strong>: Optimised for maximum utility and customer
                engagement.
              </li>
            </ul>
          </div>
          <h3> Wardrobes For Your Home In Surry Hills </h3>{" "}
          <p>
            Our custom-built wardrobes will help you maximise your storage
            space. Topcab Kitchens & Joinery provides a variety of wardrobes in
            Surry Hills that are tailored to your unique requirements, including
            walk in closets, built-in wardrobes and freestanding units. Our
            designs are not only utilitarian, but they also offer a stylish
            touch to your bedroom or dressing room.
          </p>
          <div className="ms-4">
            Key Features:
            <ul>
              <li>
                <strong>Tailored to Your Needs</strong>: Custom configurations to suit your
                storage requirements.
              </li>
              <li>
                <strong>High-Quality Finishes</strong>: Choose from a variety of materials and
                finishes.
              </li>
              <li>
                <strong>Efficient Use of Space</strong>: Designed to maximise storage while
                maintaining aesthetics.
              </li>
            </ul>
          </div>
        </section>
        <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="text-white container zeal-digital"
      >
        <div className="text-center pb-0">
          <h2 className="font-miracle display-5 mb-3">
          Why Are We The Right Choice For Your Joinery Needs?
          </h2>
        </div>
        <ul>
              <li>
                <h3>Expertise And Experience </h3>
                <p className="font-proximanova-light">Topcab Kitchens & Joinery has years of experience in the joinery sector and has established ourselves as industry leaders in Surry Hills and across NSW. Our professional artisans and designers are dedicated to providing extraordinary outcomes that exceed your expectations. We keep up with the newest trends and techniques to ensure that our solutions are current and original.</p>
              </li>
              <li>
                <h3>Personalised Service</h3>
                <p className="font-proximanova-light">At Topcab Kitchens & Joinery, we take a customer-centric approach. We take the time to understand your individual requirements and preferences, resulting in bespoke solutions that reflect your style and taste. Our team collaborates closely with you from concept to finish, delivering a smooth and enjoyable experience.</p>
              </li>
              <li>
                <h3>Quality Assurance</h3>
                <p className="font-proximanova-light">Quality is at the core of everything we do. We use only the finest materials and employ rigorous quality control measures to ensure every piece we create meets the highest standards. Our dedication to excellence ensures that your bathroom vanity, shop fittings and wardrobes in Surry Hills are not only beautiful but also built to last.</p>
              </li>
              <li>
                <h3>Competitive Pricing</h3>
                <p className="font-proximanova-light">We provide competitive pricing without sacrificing on quality. Our straightforward pricing system ensures that you receive the best value for your money. Whether you need a single bathroom vanity or whole shop fittings in Surry Hills, we have affordable options for you.</p>
              </li>
        </ul>
      </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="order-0 order-lg-1">
              <img
                src="/zeal-digital/suburbs.png"
                className="bg-dark rounded-3 d-block mx-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-stretch gap-2 justify-content-center order-1 order-lg-0">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Our Process
              </h2>
              <ul className="zeal-digital">
                <li>
                  <strong>Consultation: </strong>We start with a thorough consultation to
                  determine your needs and preferences.
                </li>
                <li>
                  <strong>Design: </strong>Our design team provides thorough plans and 3D
                  renderings to help you envisage your project.
                </li>
                <li>
                  <strong>Fabrication: </strong>We create custom joinery in Surry Hills, as well
                  as the suburbs of{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-double-bay">
                    Double Bay
                  </Link>{" "}
                  and{" "}
                  <Link to="https://topcabjoinery.com.au/custom-joinery-brookvale">
                    Brookvale
                  </Link>
                  , using high-quality materials and modern techniques.
                </li>
                <li>
                  <strong>Installation: </strong>Our professional installers will assure a
                  perfect fit and finish, bringing your idea to reality.
                </li>
                <li>
                  <strong>Aftercare: </strong>We provide continuous assistance and maintenance to
                  ensure that your joinery remains in perfect shape.
                </li>
              </ul>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>

        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Get In Touch With Us
              </h2>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Ready to transform your space with Topcab Kitchens & Joinery?
                Contact us today to discuss your project and discover how we can
                help you achieve your vision. Visit our website or call us at{" "}
                <a href="tel:+610296100019">+61 02 96 100 019</a> or email us at{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>{" "}
                to schedule a consultation. Experience the difference of bespoke
                joinery with Topcab Kitchens & Joinery!
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/reach-us-2.png"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
