import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      className="container color-black1"
      style={{ marginTop: "15rem", marginBottom: "5rem" }}
    >
      <h1 className="text-center mb-4">Terms and Conditions</h1>
      <p>
        <strong>Last updated on 15 May 2024</strong>
      </p>
      <p>
        <strong>TOPCAB Kitchens & Joinery Pty Ltd</strong>
        <br />
        ABN 93 643 711 228
      </p>

      <h2>Introduction</h2>
      <p>
        These terms and conditions (Terms) apply when you use this website{" "}
        <a href="https://www.topcabjoinery.com.au/">
          https://www.topcabjoinery.com.au/
        </a>{" "}
        (Website). You agree to be bound by these Terms which form a binding
        contractual agreement between you and us TOPCAB Kitchens & Joinery Pty
        Ltd ABN 93 643 711 228 (our, we or us). If you don’t agree to these
        Terms you must refrain from using the Website. We may change these Terms
        at any time by updating this page of the Website and your continued use
        of the Website following such an update will represent an agreement by
        you to be bound by the Terms as amended.
      </p>

      <h2>Disclaimer</h2>
      <p>
        <strong>General Information</strong>
      </p>
      <p>
        Any information or material displayed on the Website is provided to you
        as general information only. The information is not adapted to your
        specific circumstances and it may not meet your specific needs. The
        information on the Website is not professional advice of any type –
        including legal or financial advice – and it shouldn’t be relied on as
        such. If you need this kind of advice we suggest you see an independent
        professional.
      </p>

      <h2>Testimonials</h2>
      <p>
        You acknowledge and agree that the testimonials, reviews, and opinions
        presented on the Website represent individual experiences. These
        experiences do not guarantee specific outcomes and might not encompass
        the full spectrum of individual experiences.
      </p>

      <h2>Access and Use of the Website</h2>
      <p>
        You must only use the Website in accordance with these Terms and any
        applicable laws and must ensure that your employees, sub-contractors,
        and any other agents who use or access the Website comply with the Terms
        and any applicable laws.
      </p>

      <h2>Your Obligations</h2>
      <p>You must not:</p>
      <ul>
        <li>
          copy, mirror, reproduce, translate, adapt, vary, modify, sell,
          decipher or decompile any part or aspect of the Website without our
          express consent;
        </li>
        <li>
          use the Website for any purpose other than the purposes of browsing,
          selecting, or purchasing goods;
        </li>
        <li>
          use or attempt to use the Website in a manner that is illegal or
          fraudulent or facilitates illegal or fraudulent activity;
        </li>
        <li>
          use or attempt to use the Website in a manner that may interfere with,
          disrupt, or create undue burden on the Website or the servers or
          networks that host the Website;
        </li>
        <li>
          use the Website with the assistance of any automated scripting tool or
          software;
        </li>
        <li>
          act in a way that may diminish or adversely impact our reputation
          including by linking to the Website on any other website; and
        </li>
        <li>
          attempt to breach the security of the Website or otherwise interfere
          with the normal functions of the Website including by:
          <ul>
            <li>gaining unauthorised access to Website accounts or data;</li>
            <li>
              scanning, probing, or testing the Website for security
              vulnerabilities;
            </li>
            <li>
              overloading, flooding, mailbombing, crashing, or submitting a
              virus to the Website; or
            </li>
            <li>
              instigate or participate in a denial-of-service attack against the
              Website.
            </li>
          </ul>
        </li>
      </ul>

      <h2>Information on the Website</h2>
      <p>
        While we will use our best endeavours to ensure the Website is as
        up-to-date and accurate as possible, you acknowledge and agree that from
        time to time you may encounter the following issues:
      </p>
      <ul>
        <li>the Website may have errors or defects;</li>
        <li>the Website may not be accessible at times;</li>
        <li>
          messages sent through the Website may not be delivered promptly or
          delivered at all;
        </li>
        <li>
          information you receive or supply through the Website may not be
          secure or confidential; or
        </li>
        <li>
          any information provided through the Website may not be accurate or
          true.
        </li>
      </ul>
      <p>
        We reserve the right to change any information or functionality on the
        Website by updating the Website at any time without notice including
        product descriptions, prices, and other Website Content.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        We retain ownership of the Website and all materials on the Website
        (including text, graphics, logos, design, icons, images, sound and video
        recordings, pricing, downloads, and software) (Website Content) and
        reserve all rights in any intellectual property rights owned or licensed
        by it not expressly granted to you. You may make a temporary electronic
        copy of all or part of the Website for the sole purpose of viewing it.
        You must not otherwise reproduce, transmit, adapt, distribute, sell,
        modify, or publish the Website or any Website Content without prior
        written consent from us or as permitted by law.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        The Website may contain links to other websites that are not our
        responsibility. We have no control over the content of the linked
        websites and we are not responsible for it. Inclusion of any linked
        website on the Website does not imply our approval or endorsement of the
        linked website.
      </p>

      <h2>Security</h2>
      <p>
        We do not accept responsibility for loss or damage to computer systems,
        mobile phones, or other electronic devices arising in connection with
        use of the Website. You should take your own precautions to ensure that
        the process that you employ for accessing the Website does not expose
        you to risk of viruses, malicious computer code, or other forms of
        interference.
      </p>

      <h2>Reporting Misuse</h2>
      <p>
        If you become aware of misuse of the Website by any person, any errors
        in the material on the Website or any difficulty in accessing or using
        the Website, please contact us immediately using the contact details or
        form provided on our Website.
      </p>

      <h2>Privacy</h2>
      <p>
        You agree to be bound by our Privacy Policy which can be found{" "}
        <a href="https://www.topcabjoinery.com.au/privacy-policy">here</a>.
      </p>

      <h2>Liability</h2>
      <p>
        We make no warranties or representations about this Website or any of
        its content and will not be responsible to you or any third party for
        any direct or consequential loss suffered in connection with the use of
        this Website. To the maximum extent permitted by law, we each exclude
        each other from any liability that may arise due to your use of our
        Website and/or the information or materials contained on it.
      </p>

      <h2>General</h2>

      <h3>Governing Law and Jurisdiction</h3>
      <p>
        This agreement is governed by the law applying in New South Wales,
        Australia. Each party irrevocably submits to the exclusive jurisdiction
        of the courts of New South Wales, Australia, and courts of appeal from
        them in respect of any proceedings arising out of or in connection with
        this agreement. Each party irrevocably waives any objection to the venue
        of any legal process on the basis that the process has been brought in
        an inconvenient forum.
      </p>

      <h3>Waiver</h3>
      <p>
        No party to this agreement may rely on the words or conduct of any other
        party as a waiver of any right unless the waiver is in writing and
        signed by the party granting the waiver.
      </p>

      <h3>Severance</h3>
      <p>
        Any term of this agreement which is wholly or partially void or
        unenforceable is severed to the extent that it is void or unenforceable.
        The validity and enforceability of the remainder of this agreement is
        not limited or otherwise affected.
      </p>

      <h3>Joint and Several Liability</h3>
      <p>
        An obligation or a liability assumed by or a right conferred on two or
        more persons binds or benefits them jointly and severally.
      </p>

      <h3>Assignment</h3>
      <p>
        A party cannot assign, novate, or otherwise transfer any of its rights
        or obligations under this agreement without the prior written consent of
        the other party.
      </p>

      <h3>Entire Agreement</h3>
      <p>
        This agreement embodies the entire agreement between the parties and
        supersedes any prior negotiation, conduct, arrangement, understanding,
        or agreement express or implied in relation to the subject matter of
        this agreement.
      </p>

      <h3>Interpretation</h3>
      <p>In this agreement, the following rules of interpretation apply:</p>
      <ul>
        <li>
          (singular and plural) words in the singular include the plural (and
          vice versa);
        </li>
        <li>
          (gender) words indicating a gender include the corresponding words of
          any other gender;
        </li>
        <li>
          (defined terms) if a word or phrase is given a defined meaning, any
          other part of speech or grammatical form of that word or phrase has a
          corresponding meaning;
        </li>
        <li>
          (person) a reference to “person” or “you” includes an individual, the
          estate of an individual, a corporation, an authority, an association,
          consortium or joint venture (whether incorporated or unincorporated),
          a partnership, a trust, and any other entity;
        </li>
        <li>
          (party) a reference to a party includes that party’s executors,
          administrators, successors, and permitted assigns including persons
          taking by way of novation and in the case of a trustee includes any
          substituted or additional trustee;
        </li>
        <li>
          (this agreement) a reference to a party, clause, paragraph, schedule,
          exhibit, attachment, or annexure is a reference to a party, clause,
          paragraph, schedule, exhibit, attachment, or annexure to or of this
          agreement and a reference to this agreement includes all schedules,
          exhibits, attachments, and annexures to it;
        </li>
        <li>
          (document) a reference to a document (including this agreement) is to
          that document as varied, novated, ratified, or replaced from time to
          time;
        </li>
        <li>
          (headings) headings and words in bold type are for convenience only
          and do not affect interpretation;
        </li>
        <li>
          (includes) the word “includes” and similar words in any form is not a
          word of limitation;
        </li>
        <li>
          (adverse interpretation) no provision of this agreement will be
          interpreted adversely to a party because that party was responsible
          for the preparation of this agreement or that provision; and
        </li>
        <li>
          (currency) a reference to $ or “dollar” is to Australian currency
          unless otherwise agreed in writing.
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
