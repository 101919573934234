import React from "react";
import Hero from "../components/heros/services/hero";
import Insta from "../components/services/insta";

const Services = () => {
  return (
    <>
      <Hero />
      <Insta/>
    </>
  );
};

export default Services;