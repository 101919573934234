import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function WardrobesRoseBay() {
  const areasWeServe = [
    { title: "maroubra", href: "/wardrobes-maroubra" },
    {
      title: "wetherill park",
      href: "/wardrobes-wetherill-park",
    },
    { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
    { title: "coogee", href: "/wardrobes-coogee" },
    // { title: "rose bay", href: "/wardrobes-rose-bay" },
  ];

  const craftsmenData = [
    {
      title: "Use Of Premium Quality Materials",
      desc: "The materials used for crafting your wardrobe are what define its quality. For your utmost satisfaction, we ensure that we use only premium materials sourced from our trustworthy vendors, renowned for their exceptional quality. Whether it's solid or engineered wood, we select the suitable wood to meet our stringent standards.",
    },
    {
      title: "Skillful Handwork",
      desc: "Topcab Kitchens & Joinery has a vast team of skilled and proficient craftsmen with many years of experience in detailed joinery. Our team members have immense knowledge about woodcraft and pay attention to every small detail during the entire wardrobe-building process. We ensure consistency and accuracy throughout the wardrobe-building process to achieve a flawless result.",
    },
    {
      title: "Attention To Detail",
      desc: "Detailing is one of the critical factors when crafting a quality wardrobe. To provide high-grade quality wardrobes around Rose Bay, our craftsmen pay close attention to every detail, from precise measurements to seamless joints, smooth finishes and alignment. We aim to provide wardrobes that look stylish and function smoothly in your home.",
    },
  ];
  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
        className="container min-vh-100 d-grid justify-content-center "
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Your Premier Choice for Wardrobes In Rose Bay - Topcab Kitchens &
              Joinery
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Are you ready to enhance your bedroom with a new functional and
              beautiful wardrobe?{" "}
              <Link to="https://topcabjoinery.com.au/">Topcab Joinery</Link> is
              here to help. Our team of skilled experts doesn't settle for
              ordinary - we create unique, meticulously-crafted wardrobes that
              stand out. When you choose us in Rose Bay, you choose a stylish,
              custom wardrobe maker, backed by our unwavering dedication to
              quality craftsmanship.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26500.825066012516!2d151.24508388691456!3d-33.87411740474047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ac30c23d2dfb%3A0x5017d681632c8f0!2sRose%20Bay%20NSW%202029%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012660675!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          Why Partner With Us For Quality Wardrobes In Rose Bay
        </h2>
        <p>
          Following are some compelling reasons for partnering with us to build
          a wardrobe for your home near Rose Bay.
        </p>
        <h3> Unwavering Dedication to Quality </h3>{" "}
        <p>
          Topcab Kitchens & Joinery has a team of skilled craftsmen dedicated to
          immaculate craftsmanship. Our team members ensure complete
          satisfaction by using the best quality materials to build the wardrobe
          that suits your liking and preferences.
        </p>
        <h3>Collaborative Design Process </h3>{" "}
        <p>
          As the top joinery providers, our main aim is to provide you with the
          best quality furniture according to your liking and requirements. To
          build a high-quality and durable wardrobe, our team members will work
          closely with you to understand your requirements, how much space is
          available and other factors and meet your expectations.
        </p>
        <h3 className="h3"> Maximising space </h3>{" "}
        <p>
          Whether you need a walk-in closet or a wall alcove wardrobe, you can
          rely on us to utilise every inch of the available space to craft the
          best wardrobe for your Rose Bay home.
        </p>
        <h3 className="h3"> Countless design possibilities </h3>{" "}
        <p>
          Our artisans offer a wide range of design options. Whether you are
          looking for something sleek, modern or classic styles, we offer a vast
          range of materials, finishes and configurations to elevate the space
          of your home.
        </p>
        <h3 className="h3"> Functional features </h3>{" "}
        <p>
          Are you searching for wardrobes in Rose Bay that have increased
          functionality? Topcab Kitchens & Joinery can help you create the most
          stylish and functional wardrobe, including pull-out drawers, tie and
          belt organisers, shoe racks, lighting features or anything else
          according to your requirements.
        </p>
        <h3 className="h3"> Hassle-free installation </h3>{" "}
        <p>
          Besides creating the best wardrobe for your home, our team will also
          provide a smooth and hassle-free installation without disrupting your
          home.
        </p>
      </section>

      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="text-white container d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="order-0">
            <img
              src="/zeal-digital/wardrobe-rose-bay-1.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start order-1">
            <h2 className="display-6 font-miracle">
              Our Comprehensive Wardrobe Solutions In Rose Bay
            </h2>
            <p className="font-proximanova-light zeal-digital">
              Every Rose Bay resident has different wardrobe needs, and Topcab
              Kitchens & Joinery knows this. We provide a range of wardrobe
              options to fit every design and size of room: Walk-in Wardrobes:
              We offer a custom-designed walk-in closet that can transform your
              spare room or turn it into a luxurious haven for your clothing.
            </p>
            <ul className="font-proximanova-light d-flex flex-column gap-2">
              <li>
              <strong>Walk-in Wardrobes</strong>: We offer a custom-designed walk-in closet that can transform your spare room or turn it into a luxurious haven for your clothing.
              </li>
              <li>
                <strong>Built-in Wardrobes</strong>: Our craftsmen will build built-in wardrobes
                that blend with the bedroom walls to maximise space and create
                an overall look that matches the rest of the room's aesthetics.
              </li>
              <li>
                <strong>Sliding door wardrobes</strong>: We also offer sleek and practical
                sliding doors wardrobes, an excellent choice for smaller
                bedrooms as they maximise the space.
              </li>
            </ul>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>

      <section
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="text-white container zeal-digital"
      >
        <div className="text-center pb-5">
          <h2 className="font-miracle display-5 mb-3">
            How Do We Ensure The Quality Of Our Wardrobes?
          </h2>
          <p className="font-proximanova-light">
            We consider several key points to ensure the quality of our
            wardrobes in Rose Bay and in the areas of{" "}
            <Link to="https://topcabjoinery.com.au/joinery-dover-heights">
              Dover Heights
            </Link>{" "}
            and{" "}
            <Link to="https://topcabjoinery.com.au/wardrobes-bellevue-hill">
              Bellevue Hill
            </Link>
            . Here's how we ensure the quality of our wardrobes:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h3> {elem.title} </h3> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        className="text-white container d-grid align-items-center justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">
              Get In Touch With Us Today
            </h2>
            <p className="font-proximanova-light">
              If you're looking for a wardrobe that combines superior quality,
              exquisite craftsmanship and personalised design, contact Topcab
              Kitchens & Joinery today. Let our experienced team create a
              bespoke wardrobe solution that enhances your home's functionality
              and aesthetic appeal. Discover why discerning homeowners in Rose
              Bay trust Topcab Kitchens & Joinery for their custom wardrobes,
              cabinetry and joinery needs. Your dream wardrobe awaits!
            </p>
            <p className="font-proximanova-light zeal-digital">
              If you are searching for a wardrobe in Rose Bay that combines
              unmatched craftsmanship and supreme quality, then choose Topcab
              Kitchens & Joinery. Contact us for more information regarding our
              joinery at <a href="tel:+610296100019">+61 02 96 100 019</a> or{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>
              .
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/wardrobe-rose-bay-2.jpg"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Areas we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
