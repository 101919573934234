import React, { useEffect, useState, useRef } from "react";
import { useApiUrl } from "../../hooks/useApiUrl";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import "./main.css";
import "./insta.css";
import InstaModal from "../commons/modal/instaModal";
import InstaModalMobile from "../commons/modal/instaModalMobile";
import useWindowSize from "../../hooks/useWindowSize";
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Main() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [selectedSectionPosts, setSelectedSectionPosts] = useState([]);
  const sliderRef = useRef(null);
  const isMobile = useWindowSize();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const apiUrls = useApiUrl();
  const servicesUrl = apiUrls.getServices;

  const openModal = async (post) => {
    setSelectedPost(post);
    try {
      const fetchedComments = await getComments(post.id);
      setComments(fetchedComments);
      setIsOpen(true);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedPost(null);
    setComments([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (inView) {
      document.querySelector(".app").classList.remove("black");
      document.querySelector("#root").classList.add("white-bg");
    } else {
      document.querySelector("#root").classList.add("black-bg");
      document.querySelector("#root").classList.remove("white-bg");
    }
  }, [inView]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(servicesUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch services data");
        }
        const servicesData = await response.json();
        setServices(servicesData);

        const url = window.location.href;
        const serviceName = url.substring(url.lastIndexOf("#") + 1);
        const initialService = servicesData.find(
          (service) => service.urlID === serviceName
        );
        setSelectedService(initialService || servicesData[0]);

        if (initialService) {
          const mainDiv = document.getElementById("main");
          if (mainDiv) {
            setTimeout(() => {
              mainDiv.scrollIntoView({ behavior: "smooth" });
            }, 500);
            if (sliderRef.current) {
              sliderRef.current.slickGoTo(initialService.id);
            }
          }
        } else {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(1);
          }
        }
      } catch (error) {
        console.error("Error fetching services data:", error);
        setError(error.message);
      }
    };

    fetchServices();
  }, [servicesUrl]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingImages(true);
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,thumbnail_url,media_type,timestamp,username,children{media_url}&limit=999&access_token=${process.env.REACT_APP_INSTA_ACCESS_TOKEN}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Instagram posts");
        }
        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        console.error("Error fetching Instagram posts:", error);
        setError("Failed to fetch Instagram posts");
      } finally {
        setIsLoadingImages(false);
      }
    };
    fetchData();
  }, [selectedService]);

  const getComments = async (postId) => {
    try {
      const response = await fetch(
        `http://graph.facebook.com/${postId}/comments&access_token=${process.env.REACT_APP_INSTA_ACCESS_TOKEN}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch comments");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error fetching comments:", error);
    }
  };

  const handleServiceClick = (service) => {
    if (service.id === selectedService.id) return;
    navigate(`#${service.urlID}`);
    setSelectedService(service);
    setSelectedSectionPosts(selectedSectionPosts);
    setIsLoadingImages(true);
    const mainDiv = document.getElementById("main");
    if (mainDiv) {
      mainDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleServiceClickMobile = (service, index) => {
    if (service.id === selectedService.id) return;
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index + 1);
      navigate(`#${service.urlID}`);
      setSelectedService(service);
      setSelectedSectionPosts(selectedSectionPosts);
      console.log(selectedSectionPosts);
      setIsLoadingImages(true);
      const mainDiv = document.getElementById("main");
      if (mainDiv) {
        mainDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (selectedService?.instaHashtag && Array.isArray(posts)) {
      const filteredPosts = posts.filter((post) =>
        post.caption
          ?.toLowerCase()
          .includes(selectedService.instaHashtag.trim().toLowerCase() ?? "")
      );
      setSelectedSectionPosts(filteredPosts);
    }
  }, [posts, selectedService]);

  const renderVideoIcon = () => {
    return (
      <div
        className={`${
          isMobile
            ? "ProGalleryInfoItem1555825819__bgIcon__mobile"
            : "ProGalleryInfoItem1555825819__bgIcon"
        }`}
      >
        <div
          className={`${
            isMobile
              ? "ProGalleryInfoItem1555825819__warpIcon__mobile"
              : "ProGalleryInfoItem1555825819__warpIcon"
          }`}
        >
          <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
            <path d="M4 9h5.5l-3-5h-1A1.5 1.5 0 0 0 4 5.5V9Zm11.5 0H11L8 4h4.5l3 5ZM17 9l-3-5h4.5A1.5 1.5 0 0 1 20 5.5V9h-3Z"></path>
            <path
              fill-rule="evenodd"
              d="M20 10H4v8.5A1.5 1.5 0 0 0 5.5 20h13a1.5 1.5 0 0 0 1.5-1.5V10Zm-9 2.236v3.528c0 .185.185.298.328.2l2.571-1.764a.248.248 0 0 0 0-.4l-2.571-1.764c-.143-.098-.328.015-.328.2Z"
            ></path>
          </svg>
        </div>
      </div>
    );
  };

  const renderCarouselIcon = () => {
    return (
      <div
        className={`${
          isMobile
            ? "ProGalleryInfoItem1555825819__bgIcon__mobile"
            : "ProGalleryInfoItem1555825819__bgIcon"
        }`}
      >
        <div
          className={`${
            isMobile
              ? "ProGalleryInfoItem1555825819__warpIcon__mobile"
              : "ProGalleryInfoItem1555825819__warpIcon"
          }`}
        >
          <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
            <path
              fill-rule="evenodd"
              d="M15.5,7 C16.327,7 17,7.673 17,8.5 L17,8.5 L17,18.5 C17,19.327 16.327,20 15.5,20 L15.5,20 L5.5,20 C4.673,20 4,19.327 4,18.5 L4,18.5 L4,8.5 C4,7.673 4.673,7 5.5,7 L5.5,7 Z M18.5,4 C19.327,4 20,4.673 20,5.5 L20,5.5 L20,17 L19,17 L19,5.5 C19,5.225 18.776,5 18.5,5 L18.5,5 L7,5 L7,4 Z"
            ></path>
          </svg>
        </div>
      </div>
    );
  };

  const renderInstaDesktop = () => {
    return (
      <div id="main" ref={ref}>
        <div
          className="container"
          style={{ maxHeight: "100vh", maxWidth: "90%" }}
        >
          <h1 id="services-title" className="title">
            services
          </h1>
          <div
            className="container d-flex"
            style={{ maxHeight: "100%", maxWidth: "90%" }}
          >
            <div className="left">
              <div className="services__menu">
                <ul>
                  {services.map((service) => (
                    <li
                      id={service.name}
                      className={
                        service.id === selectedService.id
                          ? "services__menu__item__selected link-offset-3"
                          : "services__menu__item"
                      }
                      key={service.id}
                      onClick={() => handleServiceClick(service)}
                    >
                      {service.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="right w-100">
              <div className="container">
                <div className="description">
                  <p className="p mb-5">{selectedService.description}</p>
                </div>
                {isLoadingImages ? (
                  <div className="d-flex justify-content-center align-items-center border border-top-0 border-bottom-0 border-start-0 border-end-0 h-100 w-100">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="images">
                    {error && (
                      <p style={{ color: "red", textTransform: "uppercase" }}>
                        Error: {error}
                      </p>
                    )}
                    {selectedSectionPosts.map((post) => (
                      <div
                        key={post.id}
                        className="image-container d-flex justify-content-center align-items-center bg-black"
                        style={{
                          maxHeight: "400px",
                          maxWidth: "400px",
                          cursor: "pointer",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        onClick={() => openModal(post)}
                      >
                        {post.media_type === "CAROUSEL_ALBUM" ||
                        post.media_type === "IMAGE" ? (
                          <div
                            style={{
                              maxWidth: "100%",
                              height: "100%",
                              width: "100%",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={post.media_url}
                              alt={post.caption}
                              className="img-fluid"
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                        ) : post.media_type === "VIDEO" ? (
                          <div
                            style={{
                              maxHeight: "100%",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <video
                              autoPlay
                              loop
                              muted
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                              }}
                              poster={post.thumbnail_url}
                            >
                              <source src={post.media_url} type="video/mp4" />
                              <p className="color-black">
                                Your browser does not support the video tag.
                              </p>
                            </video>
                          </div>
                        ) : null}
                        {post.media_type === "VIDEO" && renderVideoIcon()}
                        {post.media_type === "CAROUSEL_ALBUM" &&
                          renderCarouselIcon()}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <InstaModal
              isOpen={isOpen}
              onRequestClose={closeModal}
              selectedPost={selectedPost}
              closeModal={closeModal}
              comments={comments}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInstaMobile = () => {
    const settings = {
      dots: false,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "5rem",
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
    };

    return (
      <Container id="main" ref={ref} className="my-5">
        <Row>
          <Col>
            <h1
              id="services-title"
              className="font-miracle text-center color-black1 text-uppercase display-1"
            >
              services
            </h1>
            <hr style={{ color: "black" }} />
          </Col>
        </Row>
        <Row className="my-4">
          <Col style={{ width: "100%" }}>
            <div className="slider-container d-flex flex-row align-items-center justify-content-center h-50 w-100">
              <Slider {...settings} className="w-100" ref={sliderRef}>
                {services.map((service, index) => (
                  <div
                    className="d-flex align-items-center justify-content-center nav-item text-uppercase font-proximanova-light mx-5"
                    style={{
                      cursor: "pointer",
                      minWidth: "100px",
                      textAlign: "center",
                      flex: "1 1 auto",
                      maxWidth: "auto",
                    }}
                    key={service.id}
                    onClick={() => handleServiceClickMobile(service, index)}
                  >
                    <a
                      id={service.name}
                      className={
                        service.id === selectedService.id
                          ? "services__menu__item__selected link-offset-3"
                          : "services__menu__item"
                      }
                      key={service.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        textAlign: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {service.name}
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="description">
              <p className="p mt-3 mb-5">{selectedService.description}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            {isLoadingImages ? (
              <div className="d-flex justify-content-center align-items-center border border-top-0 border-bottom-0 border-start-0 border-end-0 h-100 w-100">
                <div class="spinner-border black" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="images gap-1">
                {error && (
                  <p style={{ color: "red", textTransform: "uppercase" }}>
                    Error: {error}
                  </p>
                )}
                {selectedSectionPosts.map((post) => (
                  <div
                    key={post.id}
                    className="image-container d-flex justify-content-center align-items-center bg-black "
                    style={{
                      maxHeight: "400px",
                      maxWidth: "400px",
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                      width: "100%",
                      height: "200px",
                    }}
                    onClick={() => openModal(post)}
                  >
                    {post.media_type === "CAROUSEL_ALBUM" ||
                    post.media_type === "IMAGE" ? (
                      <div
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          width: "100%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={post.media_url}
                          alt={post.caption}
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    ) : post.media_type === "VIDEO" ? (
                      <div
                        style={{
                          maxHeight: "100%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <video
                          muted
                          autoPlay
                          playsInline
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "auto",
                            height: "auto",
                          }}
                          poster={post.thumbnail_url}
                        >
                          <source src={post.media_url} type="video/mp4" />
                          <p className="color-black">
                            Your browser does not support the video tag.
                          </p>
                        </video>
                      </div>
                    ) : null}
                    {post.media_type === "VIDEO" && renderVideoIcon()}
                    {post.media_type === "CAROUSEL_ALBUM" &&
                      renderCarouselIcon()}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Row>
        <InstaModalMobile
          isOpen={isOpen}
          onRequestClose={closeModal}
          selectedPost={selectedPost}
          closeModal={closeModal}
        />
      </Container>
    );
  };

  return useWindowSize() ? renderInstaMobile() : renderInstaDesktop();
}

export default Main;
