import React from "react";
import FormComponent from "../commons/form/formComponent";
import { Container, Row, Col } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";

function Form() {
  const FormDesktop = () => {
    return (
      <div id="aboutUs-form" className="py-5">
        <div
          className="container d-flex border border-secondary border-start-0 border-end-0"
          style={{ height: "100%" }}
        >
          <div className="col-3 border border-secondary border-start-0 border-top-0 border-bottom-0">
            <div className="row">
              <ul className="list-unstyled pt-5">
                <li className="pb-4">
                  <a
                    href="https://www.google.com/maps/place/Topcab+Kitchens+%26+Joinery/@-33.8514164,150.8943304,17z/data=!4m6!3m5!1s0x40f16717b81f4043:0x5eb1d8c8e897bd91!8m2!3d-33.851543!4d150.893584!16s%2Fg%2F11sfs58htf?entry=ttu"
                    className="p font-proximanova-light color-white text-decoration-un link-underline link-underline-opacity-0"
                  >
                    Unit 4 / 1201 The Horsley Drive, Wetherill Park, 2164, NSW
                  </a>
                </li>
                <li className="pb-4">
                  <a
                    href="mailto:info@topcabjoinery.com.au"
                    className="p font-proximanova-light color-white text-decoration-un link-underline link-underline-opacity-0"
                  >
                    info@topcabjoinery.com.au
                  </a>
                </li>
                <li>
                  <a
                    href="tel:0296100019"
                    className="p font-proximanova-light color-white link-underline link-underline-opacity-0"
                  >
                    +61 02 96 100 019
                  </a>
                </li>
                <li>
                  <div style={{ padding: "16px 16px 0px 0px", opacity: 0.7 }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.916201304179!2d150.893584!3d-33.851543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40f16717b81f4043%3A0x5eb1d8c8e897bd91!2sTopcab%20Kitchens%20%26%20Joinery!5e0!3m2!1sen!2sau!4v1717579548164!5m2!1sen!2sau"
                      width="600"
                      height="450"
                      rel="nofollow"
                      style={{ border: "0px solid black", maxWidth: "100%" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-9">
            <div className="container p-5">
              <div className="row ms-5">
                <div className="col">
                  <h2 className="h1 font-miracle color-grey display-1">
                    Hello,
                  </h2>
                  <h2 className="h1 font-proximanova-light color-grey ms-5 display-1">
                    TOPCAB JOINERY!
                  </h2>
                </div>
              </div>
              <div className="row">
                <FormComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FormMobile = () => {
    return (
      <Container
        id="aboutUs-form"
        className="py-5 border border-secondary border-start-0 border-end-0 border-bottom-0"
      >
        <Row className="mb-5">
          <ul className="list-unstyled pt-2">
            <li className="pb-3">
              <a
                href="https://www.google.com/maps/place/Topcab+Kitchens+%26+Joinery/@-33.8514164,150.8943304,17z/data=!4m6!3m5!1s0x40f16717b81f4043:0x5eb1d8c8e897bd91!8m2!3d-33.851543!4d150.893584!16s%2Fg%2F11sfs58htf?entry=ttu"
                className="p font-proximanova-light color-white text-decoration-un link-underline link-underline-opacity-0 d-block w-75"
              >
                Unit 4 / 1201 The Horsley Drive, Wetherill Park, 2164, NSW
              </a>
            </li>
            <li className="pb-3">
              <a
                href="mailto:info@topcabjoinery.com.au"
                className="p font-proximanova-light color-white text-decoration-un link-underline link-underline-opacity-0"
              >
                info@topcabjoinery.com.au
              </a>
            </li>
            <li>
              <a
                href="tel:0296100019"
                className="p font-proximanova-light color-white link-underline link-underline-opacity-0"
              >
                +61 02 96 100 019
              </a>
            </li>
          </ul>
        </Row>
        <Row>
          <Col>
            <h2 className="h1 font-miracle color-grey display-1">Hello,</h2>
            <h2 className="h1 font-proximanova-light color-grey display-3">
              TOPCAB JOINERY!
            </h2>
          </Col>
        </Row>
        <Row className="px-3">
          <FormComponent />
        </Row>
      </Container>
    );
  };

  return useWindowSize() ? <FormMobile /> : <FormDesktop />;
}

export default Form;
