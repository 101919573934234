import React from "react";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import "./footer.css";
import FacebookLogoWhite from "../../../assets/images/logos/facebookHeaderWhite.png";
import InstagramLogoWhite from "../../../assets/images/logos/instaHeaderWhite.png";
import CustomBtn from "../buttons/customBtn";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = () => {
  return useWindowSize() ? <FooterMobile /> : <FooterDesktop />;
};

const handleContactUs = () => {
  window.location.href = "/contact-us#contact-us";
};

const FooterDesktop = () => {
  const pathname = useLocation().pathname;

  const isSelected = (path) => {
    let selectionColor;
    selectionColor = "white-border-bottom";
    const regex = new RegExp(`^${path}(\/|$)`);
    return regex.test(pathname) ? `nav__selected ${selectionColor}` : "";
  };
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer__row">
              <div className="temp1">
                <span className="title1">let's create</span>
              </div>
              <div className="temp2">
                <span className="smalltext1">
                  We are ready with expertise, ideas and advise. Contact us to
                  start your project.
                </span>
              </div>
            </div>
            <div className="footer__row">
              <span className="title2">something special.</span>
            </div>
            <div className="footer__row pe-5">
              <CustomBtn
                text="contact us"
                initColor="white"
                onHoverBgColor="black"
                onHoverTxtColor="var(--color-grey)"
                width={"300px"}
                onClick={handleContactUs}
              />
            </div>
          </div>
          <div className="row">
            <div className="footer__left">
              <div className="row footer__top">
                <span>get in touch:</span>
              </div>
              <div className="address_in_footer">
                <span>
                  <a
                    href="https://www.google.com/maps/place/Topcab+Kitchens+%26+Joinery/@-33.8514164,150.8943304,17z/data=!4m6!3m5!1s0x40f16717b81f4043:0x5eb1d8c8e897bd91!8m2!3d-33.851543!4d150.893584!16s%2Fg%2F11sfs58htf?entry=ttu"
                    className="font-proximanova-light"
                    rel=" nofollow"
                  >
                    Unit 4 / 1201 The Horsley Drive, <br /> Wetherill Park,
                    2164, NSW
                  </a>
                </span>
              </div>
              <div className="footer__middle">
                <div>
                  <span>
                    <a href="tel:0296100019" className="font-proximanova-light">
                      +61 02 96 100 019
                    </a>
                  </span>
                  <span>
                    <a
                      href="mailto:info@topcabjoinery.com.au"
                      className="font-proximanova-light"
                    >
                      info@topcabjoinery.com.au
                    </a>
                  </span>
                </div>
                <div>
                  <nav className="nav__container">
                    <ul>
                      <li
                        className={`${
                          isSelected("/home") || isSelected("/")
                        } white`}
                      >
                        <a href="/">home</a>
                      </li>
                      <li className={`${isSelected("/portfolio")} white`}>
                        <a href="/portfolio">portfolio</a>
                      </li>
                      <li className={`${isSelected("/services")} white`}>
                        <a href="/services">services</a>
                      </li>
                      <li className={`${isSelected("/about-us")} white`}>
                        <a href="/about-us">about us</a>
                      </li>
                      <li className={`${isSelected("/contact-us")} white`}>
                        <a href="/contact-us">contact us</a>
                      </li>
                      <li className="">
                        <a
                          href="https://www.instagram.com/topcabjoinery/"
                          target="_blank"
                          rel="noopener noreferrer  nofollow"
                        >
                          <img src={InstagramLogoWhite} alt="Instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/TopcabJoinery/"
                          target="_blank"
                          rel="noopener noreferrer  nofollow"
                        >
                          <img src={FacebookLogoWhite} alt="Facebook" />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="footer__bottom mt-5">
                <div>
                  <span>
                    <a
                      href="/privacy-policy"
                      className="p color-grey"
                      style={{ textDecoration: "none" }}
                    >
                      Privacy Policy
                    </a>
                  </span>
                  <span>
                    <a
                      href="/terms-and-conditions"
                      className="p color-grey"
                      style={{ textDecoration: "none" }}
                    >
                      Terms & Conditions
                    </a>
                  </span>
                  <span>
                    <a
                      href="/service-areas"
                      className="p color-grey"
                      style={{ textDecoration: "none", paddingLeft: "15px" }}
                    >
                      Service Areas
                    </a>
                  </span>
                </div>
                <div>
                  <span>&copy; 2024 Topcab joinery. All rights reserved.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__right">
          <button
            id="go-up"
            className="customBtn"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.368"
              height="19.368"
              viewBox="0 0 19.368 19.368"
            >
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(0.283 19.368) rotate(-90)"
              >
                <path
                  id="Path_312"
                  data-name="Path 312"
                  transform="translate(17.745 9.056)"
                  fill="#fff"
                />
                <path
                  id="Path_223"
                  data-name="Path 223"
                  d="M13.3,13.7H-.4V0H.4V12.9H13.3Z"
                  transform="translate(9.401 18.802) rotate(-135)"
                  fill="#fff"
                />
              </g>
            </svg>
          </button>
        </div>
      </footer>
    </>
  );
};

const FooterMobile = () => {
  return (
    <>
      <footer className="d-flex justify-content-center align-items-center px-4 pt-2">
        <Container className="pt-5">
          <Row style={{ marginBottom: "-0.5rem" }}>
            <Col className="d-flex justify-content-start">
              <span className="font-proximanova-light text-uppercase display-6">
                let's create
              </span>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col className="d-flex justify-content-end">
              <span className="font-miracle text-uppercase display-6">
                something special.
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <p
                className="p font-proximanova-light"
                style={{ width: "85%", maxWidth: "400px" }}
              >
                We are ready with expertise, ideas and advise. Contact us to
                start your project.
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="d-flex justify-content-center">
              <CustomBtn
                text="contact us"
                initColor="white"
                onHoverBgColor="black"
                onHoverTxtColor="var(--color-grey)"
                width={"auto"}
                height={"3rem"}
                style={{ width: "200px", height: "56px" }}
                onClick={handleContactUs}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex justify-content-start">
              <p className="row text-uppercase font-proximanova-semibold">
                get in touch:
              </p>
            </Col>
          </Row>
          <Row className="address_in_footer mt-2">
            <Col className="d-flex justify-content-start ps-0">
              <a
                href="https://www.google.com/maps/place/Topcab+Kitchens+%26+Joinery/@-33.8514164,150.8943304,17z/data=!4m6!3m5!1s0x40f16717b81f4043:0x5eb1d8c8e897bd91!8m2!3d-33.851543!4d150.893584!16s%2Fg%2F11sfs58htf?entry=ttu"
                className="font-proximanova-light"
                rel=" nofollow"
              >
                Unit 4 / 1201 The Horsley Drive, <br /> Wetherill Park, 2164,
                NSW
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start ps-0">
              <a
                href="tel:0296100019"
                className="font-proximanova-light color-white text-decoration-none"
              >
                +61 02 96 100 019
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start ps-0">
              <a
                href="mailto:info@topcabjoinery.com.au"
                className="font-proximanova-light color-white text-decoration-none"
              >
                info@topcabjoinery.com.au
              </a>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="d-flex justify-content-between ps-0">
              <ul style={{ listStyle: "none" }} className="d-flex gap-2 ps-0">
                <li>
                  <a
                    href="https://www.instagram.com/topcabjoinery/"
                    target="_blank"
                    rel="noopener noreferrer  nofollow"
                  >
                    <img src={InstagramLogoWhite} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/TopcabJoinery/"
                    target="_blank"
                    rel="noopener noreferrer  nofollow"
                  >
                    <img src={FacebookLogoWhite} alt="Facebook" />
                  </a>
                </li>
              </ul>
              <button
                id="go-up"
                className="customBtn"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.368"
                  height="19.368"
                  viewBox="0 0 19.368 19.368"
                >
                  <g
                    id="Group_6"
                    data-name="Group 6"
                    transform="translate(0.283 19.368) rotate(-90)"
                  >
                    <path
                      id="Path_312"
                      data-name="Path 312"
                      transform="translate(17.745 9.056)"
                      fill="#fff"
                    />
                    <path
                      id="Path_223"
                      data-name="Path 223"
                      d="M13.3,13.7H-.4V0H.4V12.9H13.3Z"
                      transform="translate(9.401 18.802) rotate(-135)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col className="d-flex justify-content-start px-0">
                  <ul
                    className="d-flex gap-4 ps-0"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a
                        href="/privacy-policy"
                        className="p color-grey text-decoration-none "
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/terms-and-conditions"
                        className="p color-grey text-decoration-none"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="/service-areas"
                        className="p color-grey text-decoration-none "
                      >
                        Service Areas
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start ps-0">
                  <Row>
                    <p
                      className="p font-proximanova-light color-grey w-100"
                      style={{ fontSize: "14px" }}
                    >
                      &copy; 2024 Topcab joinery. All rights reserved.
                    </p>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
