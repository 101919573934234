import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import PartnersJSON from "../../assets/partners/partners.json";
import useWindowSize from "../../hooks/useWindowSize";
// import { Container, Row, Col } from "react-bootstrap";

function Partners() {
  return PartnersDesktop();
}

function PartnersDesktop() {
  const [partners, setPartners] = useState([]);
  const { REACT_APP_ASSETS_PARTNERS_URL } = process.env;
  useEffect(() => {
    setPartners(PartnersJSON);
  }, []);
  return (
    <div className="h-100 w-100 py-5">
      <Marquee speed={125}>
        {partners.map((partner) => (
          <img
            key={partner.id}
            src={REACT_APP_ASSETS_PARTNERS_URL + partner.url}
            alt={partner.name}
            className="mx-5 img-fluid"
          />
        ))}
      </Marquee>
    </div>
  );
}

// function PartnersMobile() {
//   const [partners, setPartners] = useState([]);

//   useEffect(() => {
//     setPartners(PartnersJSON);
//   }, []);

//   return (
//     <Container
//       className="h-100 w-100 d-flex justify-content-center align-items-center"
//       style={{ marginTop: "7rem", marginBottom: "7rem" }}
//     >
//       <Row className="d-flex justify-content-center align-items-center">
//         {partners.map((partner, index) => (
//           <Col
//             key={partner.id}
//             xs={4}
//             sm={4}
//             md={4}
//             className="d-flex justify-content-center align-items-center"
//           >
//             <img
//               src={partner.url}
//               alt={partner.name}
//               className="m-5"
//               style={{ maxWidth: "100%", height: "auto" }}
//             />
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// }

export default Partners;
