import React from "react";
import Hero from "../components/heros/about-us/hero";
import Partners from "../components/about-us/partners";
import Testimonials from "../components/about-us/testimonials";
import Main from "../components/about-us/main";
import usePageSEO from "../hooks/usePageSEO";

const AboutUs = () => {
  usePageSEO({
    title: "About Us | Topcab Joinery",
  });
  return (
    <>
      <Hero />
      <Main />
      <Partners />
      <Testimonials />
    </>
  );
};

export default AboutUs;
