import React, { useEffect } from "react";
import "./hero.css";
import "../../home/aboutUs.css";
import Image1 from "../../../assets/images/aboutUs1.png";
import useWindowSize from "../../../hooks/useWindowSize";
import { Container, Row, Col } from "react-bootstrap";

function Hero() {
  useEffect(() => {
    const animatedElements = document.querySelectorAll(
      ".animated-text, .animated-row"
    );

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.5 }
    );

    animatedElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      animatedElements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const AboutUsMobile = () => {
    return (
      <Container
        fluid
        id="aboutUs-hero"
        className="bg-black"
        style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
      >
        <Col className="d-flex flex-column gap-2">
          <Row>
            <Col>
              <h1 className="h1 font-miracle color-white text-uppercase text-center display-1">
                about us
              </h1>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={5}>
              <Row className="d-flex align-items-center justify-content-center">
                <h3 className="font-miracle color-white text-center p-1 animated-text">
                  20+
                </h3>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <h3
                  className="font-miracle color-white text-center p-4 animated-text"
                  style={{ maxWidth: "100px" }}
                >
                  Since 2020
                </h3>
              </Row>
            </Col>
            <Col>
              <Row className="d-flex align-items-center justify-content-start">
                <p
                  className="font-proximanova-light color-grey p animated-text"
                  style={{ width: "90%" }}
                >
                  Completed projects per year
                </p>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <p className="font-proximanova-light color-grey p animated-text">
                  We quickly became known for our custom craftsmanship and
                  reliable service in the kitchen and joinery industry.
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={Image1} alt="services" className="img-fluid " />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={5}></Col>
            <Col className="d-flex justify-content-center align-items-center">
            <h1 style={{opacity:0, lineHeight: 0}}>Top Cab Joinery</h1>
              <h2 className=" font-proximanova-light color-grey text-uppercase animated-row">
                we created more than
              </h2>
            </Col>
          </Row>
          <Row className="mt-3 ms-1">
            <Col
              className="d-flex justify-content-center align-items-start"
              style={{ marginLeft: "2rem" }}
            >
              <h2 className="font-miracle color-grey text-uppercase animated-row">
                <span className="color-white">50+</span> unique projects.
              </h2>
            </Col>
            <Col xs={2}></Col>
          </Row>
          <Row className="mt-3">
            <Col xs={5}></Col>
            <Col className="d-flex justify-content-center align-items-center">
              <p className="font-proximanova-light color-grey animated-row">
                Each a testament to our dedication to exceptional kitchens and
                intricate joinery artistry.
              </p>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  };

  const AboutUsDesktop = () => {
    return (
      <div id="aboutUs-hero">
        <div className="container">
          <div className="col d-flex flex-column justify-content-between h-auto">
            <div className="row d-flex justify-content-end me-5 animated-text">
              <h1 className="font-miracle color-white text-uppercase w-auto display-3">
                about us
              </h1>
            </div>
            <div className="row d-flex align-items-end justify-content-end hero__small__div">
              <div className="d-flex flex-column justify-content-center">
                <h3 className="font-miracle color-white animated-text">20+</h3>
                <p
                  className="hero__s__t font-proximanova-light color-grey animated-text"
                  style={{ maxWidth: "80%" }}
                >
                  Completed projects per year
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-end justify-content-end hero__small__div">
              <div className="d-flex flex-column justify-content-center">
                <h3 className="font-miracle color-white animated-text">
                  ​Since 2020
                </h3>
                <p className="hero__s__t font-proximanova-light color-grey animated-text">
                  We quickly became known for our custom craftsmanship and
                  reliable service in the kitchen and joinery industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row h-100">
              <img src={Image1} alt="services" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="container flex-column text-center">
          <div className="row">
            <div className="col justify-self-end">
              <h2 className="text-end mt-5 font-proximanova-light color-grey animated-row display-2 text-uppercase">
                we created more than
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2
                className="text-start font-miracle color-grey animated-row display-2 text-uppercase"
                style={{ paddingLeft: "10rem" }}
              >
                <span className="color-white">50+</span> unique projects.
              </h2>
            </div>
          </div>
        </div>
        <div className="container d-flex justify-content-center align-items-end">
          <div className="row mt-3">
            <p
              className="font-proximanova-light color-grey align-self-center animated-row"
              style={{ paddingLeft: "17rem" }}
            >
              Each a testament to our dedication to exceptional kitchens and
              intricate joinery artistry.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return useWindowSize() ? AboutUsMobile() : AboutUsDesktop();
}

export default Hero;
