import { Link } from "react-router-dom";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function KitchensRenovationsWoollahra() {
  const areasWeServe = [
    // {
    //   title: "woollahra",
    //   href: "/kitchens-renovations-woollahra",
    // },
    {
      title: "randwick",
      href: "/kitchen-renovations-randwick",
    },
  ];
  const craftsmenData = [
    {
      title: "Quality Craftsmanship",
      desc: "We take pride in our work, which incorporates high-quality materials and cutting-edge procedures. Our trained artisans pay close attention to every detail to ensure that each piece meets the highest quality requirements. If you require bespoke cabinets, kitchen renovations or other joinery services, you can count on us to deliver exceptional results. Our custom makers near Woollahra are very skilled and experienced so you can be sure of exquisite products, built to last.",
    },
    {
      title: "Personalised Service",
      desc: "From the initial concept to the finished result, we work together with you to actualise your vision. Our cabinet makers in Woollahra take the time to understand your needs and preferences, ensuring individualised service at all levels. We believe in open communication and collaboration to ensure your involvement and happiness with the end outcomes.",
    },
    {
      title: "Experience And Expertise",
      desc: "With years of experience, our staff bring unparalleled knowledge to each project. We have completed a wide range of projects, from tiny residential to large commercial installations. Our significant knowledge enables us to efficiently handle any project while delivering high-quality outcomes every time.",
    },
    {
      title: "Attention To Detail",
      desc: "We feel that paying attention to detail makes a big difference. From the planning process to the final installation, we concentrate on each and every part of the project. Our dedication to quality and accuracy ensures that every detail is impeccable, resulting in an end product that exceeds your expectations.",
    },
    {
      title: "Customer Satisfaction",
      desc: "Our ultimate goal is to exceed your expectations and ensure you are thrilled with the final result. We take pride in our work and aim for customer satisfaction with every project. Our dedication to quality, craftsmanship and customer service has earned us a reputation as a leading cabinet makers and kitchen company in Woollahra.",
    },
  ];

  return (
    <section className="zeal-components">
      <section
        style={{ paddingTop: "15rem", paddingBottom: "5rem" }}
        className="container min-vh-100 d-grid justify-content-center"
      >
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch  text-center text-lg-start">
            <h1 className="display-6 font-miracle text-white ">
              Topcab Kitchens & Joinery: Premier Cabinet Makers And Kitchen
              Companies Near Woollahra
            </h1>
            <p className="font-proximanova-light text-white zeal-digital">
              Welcome to <Link to="/">Topcab Kitchens & Joinery</Link>, your
              trusted partner for exquisite cabinetry and kitchen solutions in
              Woollahra. With years of experience and a dedication to
              excellence, we bring your vision to life with precision,
              craftsmanship and unmatched attention to detail.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13248.609416909925!2d151.233850592936!3d-33.885729858276754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12add9dcc19089%3A0x5017d681632d0d0!2sWoollahra%20NSW%202025%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012372011!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="suburb-map"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
                border: 0,
                marginLeft: "auto",
              }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="container text-white font-proximanova-light zeal-digital">
        <h2 className="font-miracle mb-5 display-6">
          Leading Kitchen Companies In Woollahra
        </h2>
        <p>
          Topcab Kitchens & Joinery is not just a name among kitchen companies
          in Woollahra; we are a symbol of quality and innovation. Our expert
          designers and craftsmen collaborate closely with you to create
          kitchens that are not only beautiful but also highly functional. We
          believe in crafting kitchens that reflect your personality and
          lifestyle.
        </p>
        <h3>Full Kitchen Renovations </h3>{" "}
        <p>
          Our comprehensive restoration services will convert your old cooking
          space into a new culinary haven. We manage the full kitchen renovation
          process, from initial design to final installation. Our team works
          directly with you to create a{" "}
          <Link to="/luxury-kitchen">luxury kitchen</Link> that meets your
          specific needs and preferences. We can help you achieve your dream
          kitchen by redesigning the layout, installing new cabinets and adding
          modern appliances.
        </p>
        <h3>Custom Kitchen Design </h3>{" "}
        <p>
          With our custom kitchen design services, you can transform your
          cooking room into a room that is appropriate for your lifestyle and
          tastes. Our skilled designers will work with you to build a plan that
          maximises space and functionality. We take into account your cooking
          habits, storage requirements and aesthetic choices when designing a
          cooking space that is both functional and appealing.
        </p>
        <h3 className="h3"> Cabinet Refacing And Refinishing</h3>{" "}
        <p>
          Refresh your kitchen without undergoing a full renovation with our
          cabinet refacing and refinishing services. Our cabinet makers near
          Woollahra can update your existing cabinets with new doors, hardware
          and finishes, giving your kitchen a contemporary look. This
          cost-effective approach offers a fresh appearance without the expense
          and hassle of a complete remodel.
        </p>
        <h3 className="h3"> Installation Services </h3>{" "}
        <p>
          Our professional installers ensure that your new kitchen is installed
          perfectly. Throughout the installation process, we pay great attention
          to every detail to ensure that each component is safely and precisely
          put. Our dedication to great craftsmanship ensures that your kitchen
          will be resilient and long-lasting.
        </p>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="order-0 d-grid align-items-center justify-content-center">
            <img
              src="/zeal-digital/kitchen.jpg"
              className="bg-dark rounded-3 object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
          <div
            className="order-1 d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start"
            style={{ fontSize: "14px" }}
          >
            <h2 className="font-miracle mb-3">
              Exceptional Cabinet Makers Around Woollahra
            </h2>
            <p className="font-proximanova-light my-0">
              At Topcab Kitchens & Joinery, we understand that cabinetry is not
              just about storage; it's about enhancing the aesthetics and
              functionality of your space. Our team of skilled cabinet makers
              near Woollahra is dedicated to creating custom cabinets that
              perfectly fit your needs and style preferences. Whether
              you&apos;re looking for bespoke custom cabinets, elegant bathroom
              vanity or stylish built-in wardrobes in Woollahra, we deliver
              quality and sophistication in every piece.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
        </div>
      </section>
      <section className="text-white container zeal-digital uni-padding-y">
        <div className="text-center">
          <h2 className="font-miracle display-5 mb-3">
            Why Select Topcab Kitchens & Joinery For Your Kitchen And Cabinets
            In Woollahra?
          </h2>
          <p className="font-proximanova-light">
            Choosing among the right kitchen companies in Woollahra or in{" "}
            <Link to="https://topcabjoinery.com.au/kitchen-renovations-randwick">
              Randwick
            </Link>{" "}
            is crucial. At Topcab Kitchens & Joinery, we are dedicated to
            providing exceptional quality and service. Here are a few reasons
            why you should choose our joinery services:
          </p>
        </div>
        <ul>
          {craftsmenData.map((elem) => {
            return (
              <li key={elem.title}>
                <h3> {elem.title} </h3> {elem.desc}
              </li>
            );
          })}
        </ul>
      </section>
      <section className="text-white container d-grid align-items-center justify-content-center uni-padding-y">
        <div className="hero-section-grid">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch text-center text-lg-start">
            <h2 className="display-6 font-miracle">Contact Us</h2>
            <p className="font-proximanova-light zeal-digital">
              Ready to transform your home with one of the best kitchen
              companies in Woollahra? Contact Topcab Kitchens & Joinery today
              for a consultation and let us bring your dream space to life. Call
              us at <a href="tel:+610296100019">+61 02 96 100 019</a> or email
              at{" "}
              <a href="mailto:info@topcabjoinery.com.au">
                info@topcabjoinery.com.au
              </a>{" "}
              to get in touch with our kitchen and cabinet makers around
              Woollahra.
            </p>
            <a
              href="tel:+610296100019"
              className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
            >
              Call Now
            </a>
          </div>
          <div className="d-grid justify-content-end align-items-center">
            <img
              src="/zeal-digital/reach-us-2.png"
              className="bg-dark rounded-3 ms-auto object-fit-cover"
              alt="hero"
              style={{
                width: "clamp(200px, 100%, 500px)",
                aspectRatio: "5/4",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container text-white font-proximanova-light"
      >
        <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
          Area we serve
        </h2>
        <div className="d-grid d-lg-flex flex-wrap gap-4 justify-content-lg-center">
          {areasWeServe?.map((elem) => (
            <Link
              to={elem.href}
              className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
            >
              {elem.title}
            </Link>
          ))}
        </div>
      </section>
      <Form />
    </section>
  );
}
