import React from "react";
import AboutUs from "../components/home/aboutUs";
import Partners from "../components/about-us/partners";
import Testimonials from "../components/about-us/testimonials";
import Hero from "../components/heros/home/hero";
import Services from "../components/home/services/services";
import Form from "../components/contact-us/form";
// import "../components/home/aboutUs.css";

const Home = ({ projects }) => {
  return (
    <>
      <Hero featuredProjects={projects} />
      <AboutUs />
      <Services />
      <Partners />
      <Testimonials />
      {/* <div className="w-100 bg-white" style={{ opacity: 0.8 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.916201304179!2d150.893584!3d-33.851543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40f16717b81f4043%3A0x5eb1d8c8e897bd91!2sTopcab%20Kitchens%20%26%20Joinery!5e0!3m2!1sen!2sau!4v1717579548164!5m2!1sen!2sau"
          width="100%"
          height="270"
          rel=" nofollow"
          style={{ border: "0px solid black", maxWidth: "100%" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div> */}

      <Form />
    </>
  );
};

export default Home;
