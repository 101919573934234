import React from "react";

function FormField({ type, name, label, id, value, onChange, options = [] }) {
  const optionStyles = {
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    fontSize: "16px",
  };

  return (
    <div className="col">
      <div className="row">
        <label htmlFor={name} className="font-proximanova-semibold color-grey">
          {label}*
        </label>
      </div>

      <div className="row">
        {type === "select" ? (
          <select
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className="bg-transparent border border-top-0 border-start-0 border-end-0 border-secondary font-proximanova-light color-white w-100 p-2"
            style={{ outline: "none", fontSize: "16px" }}
            required
          >
            <option value="" disabled style={optionStyles}></option>
            {options.map((option, index) => (
              <option key={index} value={option} style={optionStyles}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <input
            id={id}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            className="bg-transparent border border-top-0 border-start-0 border-end-0 border-secondary font-proximanova-light color-white w-100 p-2"
            style={{ outline: "none", fontSize: "16px" }}
            required
          />
        )}
      </div>
    </div>
  );
}

export default FormField;
