import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./customBtn.css";
import useWindowSize from "../../../hooks/useWindowSize";

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  width: ${(props) => props.width};
  height: ${(props) => props.height || "56px"};
  border: 1px solid var(--color-grey);
  background-color: ${(props) => props.initColor};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => props.onHoverBgColor};
    color: ${(props) => props.onHoverTxtColor};
    border: 1px solid ${(props) => props.onHoverBgColor};

    svg {
      path {
        d: path(
          "M0.5 9.35772H100.9956L94.2001 2.29941L96.4134 0L107 11L96.4134 22L94.2001 19.7006L100.9956 12.6423H0.5V9.35772Z"
        );
        d: "M0.5 9.35772H100.9956L94.2001 2.29941L96.4134 0L107 11L96.4134 22L94.2001 19.7006L100.9956 12.6423H0.5V9.35772Z";
        fill: ${(props) => props.onHoverTxtColor};
      }
    }
  }
`;

const ButtonText = styled.span`
  color: grey;
  text-transform: uppercase;
  font-family: var(--font-proximanova-semibold);
  font-size: 16px;
  line-height: 19px;
  width: 50%;
  text-align: flex-start;

  &:hover {
    color: ${(props) => props.onHoverTxtColor};
  }
`;

const CustomButton = ({
  text,
  initColor,
  onHoverBgColor,
  onHoverTxtColor,
  width,
  height,
  onClick,
}) => {
  const [hover, setHover] = useState(false);

  const isMobile = useWindowSize();

  return (
    <ButtonWrapper
      initColor={initColor}
      onHoverBgColor={onHoverBgColor}
      onHoverTxtColor={onHoverTxtColor}
      width={width}
      height={height}
      onClick={onClick}
    >
      <ButtonText style={{ color: "var(--color-grey)" }}>{text}</ButtonText>
      <svg id="right">
        <path
          d="M0.5 9.35772H70.9956L64.2001 2.29941L66.4134 0L77 11L66.4134 22L64.2001 19.7006L70.9956 12.6423H0.5V9.35772Z"
          fill="var(--color-grey)"
        ></path>
      </svg>
    </ButtonWrapper>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  initColor: PropTypes.string.isRequired,
  onHoverBgColor: PropTypes.string.isRequired,
  onHoverTxtColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CustomButton;
