import React from "react";
import "./header.css";
import Navbar from "../navbar/nav";
import Logo from "../brandLogo/logo";

const Header = ({ color }) => {
  return (
    <header className="header">
      <Logo color={color} />
      <Navbar color={color} />
    </header>
  );
};

export default Header;
