import React from "react";
import "./hero.css";
import useWindowSize from "../../../hooks/useWindowSize";
import { Container, Col, Row } from "react-bootstrap";

function Hero() {
  return useWindowSize() ? <HeroMobile /> : <HeroDesktop />;
}

function HeroDesktop() {
  return (
    <div id="service-hero" className="d-flex bg-black">
      <div className="container h-auto">
        <div className="col d-flex justify-content-end h-100">
          <div className="row d-flex justify-content-end align-items-center">
            <p className="p font-proximanova-light color-grey text-wrap align-self-end w-auto me-4">
              We offer custom kitchens, wardrobes, and all types of joinery. Our
              team works to make your space beautiful and functional, using
              quality materials to fit your style. Explore our various projects
              below.
            </p>
          </div>
        </div>
        <div className="col-8">
          <div className="row">
            <img
              src="http://assets.topcabjoinery.com.au/services/banner-image.png"
              alt="services"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="banner">
        <h2 className="font-proximanova-light color-grey h1 text-uppercase">
          your space.
        </h2>
        <h2 className="font-miracle color-grey h1 text-uppercase">
          reimagined.
        </h2>
      </div>
    </div>
  );
}

function HeroMobile() {
  return (
    <Container id="service-hero" className="d-flex ">
      <Col className="d-flex flex-column gap-5">
        <Row>
          <div className="position-absolute" style={{ marginTop: "-3rem" }}>
            <h2 className="font-proximanova-light color-grey h1 text-uppercase display-3">
              your space.
            </h2>
            <h2 className="font-miracle color-grey h1 text-uppercase display-3 text-end me-5">
              reimagined.
            </h2>
          </div>
          <img
            src="http://assets.topcabjoinery.com.au/services/banner-image.png"
            alt="services"
            className="img-fluid"
          />
        </Row>
        <Row>
          <p className="p font-proximanova-light color-grey text-wrap align-self-end">
            We offer custom kitchens, wardrobes, and all types of joinery. Our
            team works to make your space beautiful and functional, using
            quality materials to fit your style. Explore our various projects
            below.
          </p>
        </Row>
      </Col>
    </Container>
  );
}

export default Hero;
