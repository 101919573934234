import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import "./logo.css";
import logoBlack from "../../../assets/images/logos/logo-black.svg";
import LogoWhite from "../../../assets/images/logos/logo-white.svg";
import LogoMobileBlack from "../../../assets/images/logos/logo-mobile-black.svg";
import LogoMobileWhite from "../../../assets/images/logos/logo-mobile-white.svg";

function Logo({ color }) {
  const isMobile = useWindowSize();
  return (
    <div className="overlay">
      {isMobile ? (
        <img
          src={color === "white" ? LogoMobileWhite : LogoMobileBlack}
          className="img-fluid mobile-logo"
          alt="Logo"
          onClick={() => {
            window.location.href = "/";
          }}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <img
          src={color === "white" ? LogoWhite : logoBlack}
          className="img-fluid"
          alt="Logo"
          onClick={() => {
            window.location.href = "/";
          }}
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
}

export default Logo;
