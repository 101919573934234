import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useApiUrl } from "./hooks/useApiUrl";
import Home from "./pages/home";
import Portfolio from "./pages/portfolio";
import ContactUs from "./pages/contactus";
import AboutUs from "./pages/aboutus";
import Project from "./pages/project";
import Header from "./components/commons/header/header";
import Footer from "./components/commons/footer/footer";
import Preloader from "./components/commons/preloader/preloader";
import PrivacyPolicy from "../src/pages/policy";
import TermsAndConditions from "../src/pages/terms";
import NotFound from "../src/pages/notFound";
import Insta from "../src/pages/insta";
import LandingPage from "./pages/landingPage";
import Error from "./components/commons/errorMessage/errorMessage";
import "./App.css";
import usePageSEO from "./hooks/usePageSEO";
import ThankYou from "./pages/thankYou";
import OGTags from "./components/commons/SEO/OGTags";
import ServiceArea from "./pages/serviceArea";
// Zeal digital code starts here
import TvUnits from "./pages/zeal-digital/service-pages/tvUnit";
import CustomJoineryEasternSuburbs from "./pages/zeal-digital/sub-urb-pages/customJoineryEasternSuburbs";
import CustomJoineryBrookvale from "./pages/zeal-digital/sub-urb-pages/customJoineryBrookvale";
import CustomJoineryDoubleBay from "./pages/zeal-digital/sub-urb-pages/customJoineryDoubleBay";
import CustomJoineryNorthernBeaches from "./pages/zeal-digital/sub-urb-pages/customJoineryNorthernBeaches";
import CustomJoinerySurryHills from "./pages/zeal-digital/sub-urb-pages/customJoinerySurryHills";
import CustomJoineryVaucluse from "./pages/zeal-digital/sub-urb-pages/customJoineryVaucluse";
import DetailedJoinery from "./pages/zeal-digital/service-pages/detailedJoinery";
import LuxuryKitchens from "./pages/zeal-digital/service-pages/luxuryKitchens";
import WardrobesRoseBay from "./pages/zeal-digital/sub-urb-pages/wardrobesRoseBay";
import WardrobesCoogee from "./pages/zeal-digital/sub-urb-pages/wardrobesCoogee";
import WardrobesBellevueHill from "./pages/zeal-digital/sub-urb-pages/wardrobesBellevueHill";
import WardrobesMaroubra from "./pages/zeal-digital/sub-urb-pages/wardrobesMaroubra";
import WardrobesWetherillPark from "./pages/zeal-digital/sub-urb-pages/wardrobesWetherillPark";
import CabinetsMonaVale from "./pages/zeal-digital/sub-urb-pages/cabinetsMonaVale";
import KitchensRenovationsWoollahra from "./pages/zeal-digital/sub-urb-pages/kitchensRenovationsWoollahra";
import KitchenRenovationsRankwick from "./pages/zeal-digital/sub-urb-pages/kitchensRenovationsRandwick";
import JoineryBondi from "./pages/zeal-digital/sub-urb-pages/joineryBondi";
import JoineryDoverHeights from "./pages/zeal-digital/sub-urb-pages/joineryDoverHeights";
// Zeal digital code ends here

function App() {
  const location = useLocation();
  const path = location.pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [portfolioProjects, setPortfolioProjects] = useState([]);
  const [error, setError] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const apiUrls = useApiUrl();
  const featuredProjectsUrl = apiUrls.getFeaturedProjects;
  const currentProjectsUrl = apiUrls.getCurrentProjects;
  usePageSEO();

  let headerColor = "";
  if (
    path === "/services" ||
    path === "/contact-us" ||
    path === "/about-us" ||
    path === "/" ||
    path === "/home" ||
    path === "/notFound" ||
    path === "/thank-you" ||
    path === "/insta" ||
    // Zeal digital code starts here
    path === "/tv-units" ||
    path === "/detailed-joinery" ||
    path === "/luxury-kitchens" ||
    path === "/service-areas" ||
    path === "/custom-joinery-eastern-suburbs" ||
    path === "/custom-joinery-brookvale" ||
    path === "/custom-joinery-double-bay" ||
    path === "/custom-joinery-northern-beaches" ||
    path === "/custom-joinery-surry-hills" ||
    path === "/custom-joinery-vaucluse" ||
    path === "/wardrobes-coogee" ||
    path === "/wardrobes-bellevue-hill" ||
    path === "/wardrobes-maroubra" ||
    path === "/wardrobes-wetherill-park" ||
    path === "/wardrobes-rose-bay" ||
    path === "/cabinets-mona-vale" ||
    path === "/kitchens-renovations-woollahra" ||
    path === "/kitchen-renovations-randwick" ||
    path === "/joinery-bondi" ||
    path === "/joinery-dover-heights"
    // Zeal digital code ends here
  ) {
    headerColor = "white";
  } else {
    headerColor = "black";
  }

  const getBackgroundColor = () => {
    if (
      path === "/services" ||
      path === "/contact-us" ||
      path === "/about-us" ||
      path === "/" ||
      path === "/home" ||
      path === "/insta" ||
      // Zeal digital code starts here
      path === "/tv-units" ||
      path === "/detailed-joinery" ||
      path === "/luxury-kitchens" ||
      path === "/service-areas" ||
      path === "/custom-joinery-eastern-suburbs" ||
      path === "/custom-joinery-brookvale" ||
      path === "/custom-joinery-double-bay" ||
      path === "/custom-joinery-northern-beaches" ||
      path === "/custom-joinery-surry-hills" ||
      path === "/custom-joinery-vaucluse" ||
      path === "/wardrobes-coogee" ||
      path === "/wardrobes-bellevue-hill" ||
      path === "/wardrobes-maroubra" ||
      path === "/wardrobes-wetherill-park" ||
      path === "/wardrobes-rose-bay" ||
      path === "/cabinets-mona-vale" ||
      path === "/kitchens-renovations-woollahra" ||
      path === "/kitchen-renovations-randwick" ||
      path === "/joinery-bondi" ||
      path === "/joinery-dover-heights"
      // Zeal digital code ends here
    ) {
      return "black";
    } else {
      return "white";
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const featuredResponse = await fetch(featuredProjectsUrl);
        if (!featuredResponse.ok) {
          throw new Error("Failed to fetch featured projects");
        }
        const featuredData = await featuredResponse.json();
        setFeaturedProjects(featuredData);

        const portfolioResponse = await fetch(currentProjectsUrl);
        if (!portfolioResponse.ok) {
          throw new Error("Failed to fetch portfolio projects");
        }
        const portfolioData = await portfolioResponse.json();
        setPortfolioProjects(portfolioData);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching projects:", error);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [featuredProjectsUrl, currentProjectsUrl]);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem("hasVisited");
    if (hasVisited) {
      setShowLandingPage(false);
      setIsLoading(true);
    } else {
      sessionStorage.setItem("hasVisited", "true");
    }
  }, []);

  return (
    <div className={`app ${getBackgroundColor()}`}>
      <OGTags />
      {showLandingPage && (path === "/" || path === "/home") && <LandingPage />}
      {error && <Error message={error} onClose={() => setError(null)} />}
      <Header color={headerColor} />
      {isLoading && <Preloader />}
      <Routes>
        <Route path="/" element={<Home projects={featuredProjects} />} />
        <Route path="/home" element={<Home projects={featuredProjects} />} />
        <Route
          path="/portfolio"
          element={<Portfolio portfolioProjects={portfolioProjects} />}
        />
        <Route path="/portfolio/:id" element={<Project />} />
        <Route path="/services" element={<Insta />} />
        <Route path="/insta" element={<NotFound />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="*" element={<NotFound />} />

        {/* Zeal digital code starts here */}
        <Route path="/tv-units" element={<TvUnits />} />
        <Route path="/detailed-joinery" element={<DetailedJoinery />} />
        <Route path="/luxury-kitchens" element={<LuxuryKitchens />} />
        <Route path="/service-areas" element={<ServiceArea />} />
        <Route
          path="/custom-joinery-eastern-suburbs"
          element={<CustomJoineryEasternSuburbs />}
        />
        <Route
          path="/custom-joinery-brookvale"
          element={<CustomJoineryBrookvale />}
        />
        <Route
          path="/custom-joinery-double-bay"
          element={<CustomJoineryDoubleBay />}
        />
        <Route
          path="/custom-joinery-northern-beaches"
          element={<CustomJoineryNorthernBeaches />}
        />
        <Route
          path="/custom-joinery-surry-hills"
          element={<CustomJoinerySurryHills />}
        />
        <Route
          path="/custom-joinery-vaucluse"
          element={<CustomJoineryVaucluse />}
        />
        <Route path="/wardrobes-rose-bay" element={<WardrobesRoseBay />} />
        <Route path="/wardrobes-coogee" element={<WardrobesCoogee />} />
        <Route
          path="/wardrobes-bellevue-hill"
          element={<WardrobesBellevueHill />}
        />
        <Route path="/wardrobes-maroubra" element={<WardrobesMaroubra />} />
        <Route
          path="/wardrobes-wetherill-park"
          element={<WardrobesWetherillPark />}
        />
        <Route path="/cabinets-mona-vale" element={<CabinetsMonaVale />} />
        <Route
          path="/kitchens-renovations-woollahra"
          element={<KitchensRenovationsWoollahra />}
        />
        <Route
          path="/kitchen-renovations-randwick"
          element={<KitchenRenovationsRankwick />}
        />
        <Route path="/joinery-bondi" element={<JoineryBondi />} />
        <Route
          path="/joinery-dover-heights"
          element={<JoineryDoverHeights />}
        />
        {/* Zeal digital code ends here */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
