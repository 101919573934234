import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import Form from "../../../components/contact-us/form";
import "../../../../src/components/zeal-digital/zeal-digital.css";

export default function WardrobesCoogee() {
  const areasWeServe = [
    { title: "maroubra", href: "/wardrobes-maroubra" },
    {
      title: "wetherill park",
      href: "/wardrobes-wetherill-park",
    },
    { title: "bellevue hill", href: "/wardrobes-bellevue-hill" },
    // { title: "coogee", href: "/wardrobes-coogee" },
    { title: "rose bay", href: "/wardrobes-rose-bay" },
  ];

  const craftsmenData = [
    {
      title: "Material selection",
      desc: "We ensure the quality of your wardrobe by using only the best quality materials, such as solid wood, plywood or high-density fiberboard.",
    },
    {
      title: "Precise Manufacturing",
      desc: "Our artisans use contemporary tools and equipment to ensure exact cuts and measures. The outcome is wardrobes that fit together flawlessly and operate without a hitch.",
    },
    {
      title: "Finishing and Coating",
      desc: "We also use premium finishes and coatings that preserve the wardrobes from fading, stains and scratches while improving their beauty.",
    },
  ];

  const Desktop = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "15rem", paddingBottom: "10rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h1 className="display-6 font-miracle text-white ">
                Stylish Wardrobes to Elevate Your Lifestyle In Coogee: Top
                Kitchens & Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital">
                Do you want to give your bedroom a makeover by getting a stylish
                and functional wardrobe? If yes, then you are at the right
                place! Introducing <Link to="/">Topcab Joinery</Link>, your
                go-to source for fine custom wardrobes in Coogee. Our team
                comprises experts specialising in crafting custom wardrobes that
                combine style, usefulness, quality and construction. We are
                dedicated to providing outstanding results that exceed your
                expectations, whether you're remodelling your house or trying to
                make the most of your storage space.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13243.341756461621!2d151.24465129296843!3d-33.91963466515247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b19c638293e1%3A0x5017d681632b2e0!2sCoogee%20NSW%202034%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012635931!5m2!1sen!2sin"
                width="100%"
                height="450"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                  marginLeft: "auto",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            What Material Do We Use To Craft Quality Wardrobes In Coogee?
          </h2>
          <p>Topcab Kitchens & Joinery is dedicated to creating premium quality wardrobes in Coogee using superior materials that are sustainable. We make sure the wardrobes have aesthetic appeal and are durable. Our materials for crafting wardrobes and other furniture are sourced from our reputed suppliers, who are well-known for their excellence in craftsmanship.</p>
          <p>
          Below are some common materials we use for making wardrobes:
          </p>
          <h3>Solid wood </h3>{" "}
          <p>
          Our high-quality materials for making wardrobes include solid wood. It's suitable for building frames, shelves, wardrobe doors and drawer fronts, which add warmth and character to your storage solution. Solid wood is one of the right choices for building your wardrobe as it offers a timeless appeal with its rich colours and patterns and is very durable.
          </p>
          <h3>Engineered Wood Products </h3>{" "}
          <p>
            Engineered wood is another quality material for building your
            wardrobe and other furniture like <Link to="/tv-units">luxury TV units</Link>, which ensures
            longevity and structural integrity. Our engineered timber is sourced
            from reputed suppliers and is designed to be more resistant and
            stable than solid wood. This is a more affordable alternative to
            solid wood, offering quality and durability.
          </p>
          <h3 className="h3"> Top-quality hardware and accessories </h3>{" "}
          <p>
            Topcab Kitchens & Joinery takes quality down to the smallest detail.
            To provide the best quality wardrobes around Coogee, we focus not
            only on the quality of the wood but also on the hardware and
            accessories. We build the wardrobe according to your requirements
            using premium-quality drawer runners, handles and hinges.
          </p>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3 text-center">
            Types Of Wardrobes We Offer In Coogee
          </h2>
          <p>
            Every family has preferences and requirements for any product or
            service, including a wardrobe. Topcab Kitchens & Joinery provides
            custom wardrobes around Coogee to suit any style and space.
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              <strong>Built-in Wardrobes</strong>: Our custom-designed built-in wardrobes offered
              in Coogee and in the neighbouring cities of Maroubra and Bondi are
              perfect for making the most of the space in living rooms or
              bedrooms while seamlessly blending with the aesthetics of your
              home. We maximise storage space while maintaining a unified and
              stylish look.
            </li>
            <li>
              <strong>Walk-in Closets</strong>: Enhance your storage space with a beautiful
              wardrobe that fits your unique style. Our artisans make the most
              of every available space to offer plenty of storage for clothes,
              accessories and other items. Integrated lighting, movable shelves
              and hanging areas complete the design.
            </li>
            <li>
              <strong>Sliding Door Wardrobes</strong>: Our sliding door wardrobes in Coogee blend
              style and utility, making them perfect for modern homes. They come
              in various finishes and have a smooth-gliding structure designed
              to maximise floor space and provide easy access to belongings.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3">
              How Do We Ensure The Quality Of Our Wardrobes?
            </h2>
            <p className="font-proximanova-light">
              As one of the top <Link to="/detailed-joinery">detailed joinery</Link> companies in and around Sydney,
              we ensure that each wardrobe and other furniture piece we offer is
              of the highest quality and matches our standards. The following
              are how we ensure that we provide high-quality and durable
              wardrobes in Coogee.
            </p>
          </div>
          <ul>
            {craftsmenData.map((elem) => {
              return (
                <li key={elem.title}>
                  <h3> {elem.title} </h3> {elem.desc}
                </li>
              );
            })}
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center">
              <h2 className="display-6 font-miracle">
                Get In Touch With Us Today
              </h2>
              <p className="font-proximanova-light">
                Are you ready to give your Coogee bedroom a makeover with a
                custom Topcab Kitchens & Joinery wardrobe? Contact us for a
                consultation. Our team of professionals will assist you in
                designing the wardrobe in Coogee that fits your desires and walk
                you through the entire process.
              </p>
              <p className="font-proximanova-light zeal-digital">
                Call us at <a href="tel:+610296100019">+61 02 96 100 019</a> or{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>
                to get started.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-end align-items-center">
              <img
                src="/zeal-digital/wardrobe-coogee.jpg"
                className="bg-dark rounded-3 ms-auto"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-flex flex-wrap gap-4 justify-content-center">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  const Mobile = () => {
    return (
      <section className="zeal-components">
        <section
          style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
          className="container min-vh-100 d-grid align-items-center justify-content-center "
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 align-items-center align-items-lg-stretch justify-content-center">
              <h1 className="display-6 font-miracle text-white text-center text-lg-start">
                Stylish Wardrobes to Elevate Your Lifestyle In Coogee: Top
                Kitchens & Joinery
              </h1>
              <p className="font-proximanova-light text-white zeal-digital text-center text-lg-start">
                Do you want to give your bedroom a makeover by getting a stylish
                and functional wardrobe? If yes, then you are at the right
                place! Introducing <Link to="/">Topcab Joinery</Link>, your
                go-to source for fine custom wardrobes in Coogee. Our team
                comprises experts specialising in crafting custom wardrobes that
                combine style, usefulness, quality and construction. We are
                dedicated to providing outstanding results that exceed your
                expectations, whether you're remodelling your house or trying to
                make the most of your storage space.
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid justify-content-center align-items-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13243.341756461621!2d151.24465129296843!3d-33.91963466515247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b19c638293e1%3A0x5017d681632b2e0!2sCoogee%20NSW%202034%2C%20Australia!5e0!3m2!1sen!2sin!4v1723012635931!5m2!1sen!2sin"
                width="100%"
                height="250"
                title="suburb-map"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                  border: 0,
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="container text-white font-proximanova-light zeal-digital">
          <h2 className="font-miracle mb-5 display-6">
            What Material Do We Use To Craft Quality Wardrobes In Coogee?
          </h2>
          <p>
          Topcab Kitchens & Joinery is dedicated to creating premium quality wardrobes in Coogee using superior materials that are sustainable. We make sure the wardrobes have aesthetic appeal and are durable. Our materials for crafting wardrobes and other furniture are sourced from our reputed suppliers, who are well-known for their excellence in craftsmanship.
          </p>
          <p>Below are some common materials we use for making wardrobes:</p>
          <h3>Solid wood </h3>{" "}
          <p>
          Our high-quality materials for making wardrobes include solid wood. It's suitable for building frames, shelves, wardrobe doors and drawer fronts, which add warmth and character to your storage solution. Solid wood is one of the right choices for building your wardrobe as it offers a timeless appeal with its rich colours and patterns and is very durable.
          </p>
          <h3>Engineered Wood Products </h3>{" "}
          <p>
            Engineered wood is another quality material for building your
            wardrobe and other furniture like <Link to="/tv-units">luxury TV units</Link>, which ensures
            longevity and structural integrity. Our engineered timber is sourced
            from reputed suppliers and is designed to be more resistant and
            stable than solid wood. This is a more affordable alternative to
            solid wood, offering quality and durability.
          </p>
          <h3 className="h3"> Top-quality hardware and accessories </h3>{" "}
          <p>
            Topcab Kitchens & Joinery takes quality down to the smallest detail.
            To provide the best quality wardrobes around Coogee, we focus not
            only on the quality of the wood but also on the hardware and
            accessories. We build the wardrobe according to your requirements
            using premium-quality drawer runners, handles and hinges.
          </p>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <h2 className="font-miracle mb-3">
            Types Of Wardrobes We Offer In Coogee
          </h2>
          <p>
            Every family has preferences and requirements for any product or
            service, including a wardrobe. Topcab Kitchens & Joinery provides
            custom wardrobes around Coogee to suit any style and space.
          </p>
          <ul className="font-proximanova-light d-flex flex-column gap-2">
            <li>
              Built-in Wardrobes: Our custom-designed built-in wardrobes offered
              in Coogee and in the neighbouring cities of Maroubra and Bondi are
              perfect for making the most of the space in living rooms or
              bedrooms while seamlessly blending with the aesthetics of your
              home. We maximise storage space while maintaining a unified and
              stylish look.
            </li>
            <li>
              Walk-in Closets: Enhance your storage space with a beautiful
              wardrobe that fits your unique style. Our artisans make the most
              of every available space to offer plenty of storage for clothes,
              accessories and other items. Integrated lighting, movable shelves
              and hanging areas complete the design.
            </li>
            <li>
              Sliding Door Wardrobes: Our sliding door wardrobes in Coogee blend
              style and utility, making them perfect for modern homes. They come
              in various finishes and have a smooth-gliding structure designed
              to maximise floor space and provide easy access to belongings.
            </li>
          </ul>
        </section>
        <section
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="text-white container zeal-digital"
        >
          <div className="text-center pb-5">
            <h2 className="font-miracle display-5 mb-3">
              How Do We Ensure The Quality Of Our Wardrobes?
            </h2>
            <p className="font-proximanova-light">
              As one of the top detailed joinery companies in and around Sydney,
              we ensure that each wardrobe and other furniture piece we offer is
              of the highest quality and matches our standards. The following
              are how we ensure that we provide high-quality and durable
              wardrobes in Coogee.
            </p>
          </div>
          <ul>
            {craftsmenData.map((elem) => {
              return (
                <li key={elem.title}>
                  <h3> {elem.title} </h3> {elem.desc}
                </li>
              );
            })}
          </ul>
        </section>
        <section
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          className="text-white container d-grid align-items-center justify-content-center"
        >
          <div className="hero-section-grid">
            <div className="d-flex flex-column gap-2 justify-content-center align-items-center align-items-lg-stretch">
              <h2 className="display-6 font-miracle text-center text-lg-start">
                Get In Touch With Us Today
              </h2>
              <p className="font-proximanova-light">
                Are you ready to give your Coogee bedroom a makeover with a
                custom Topcab Kitchens & Joinery wardrobe? Contact us for a
                consultation. Our team of professionals will assist you in
                designing the wardrobe in Coogee that fits your desires and walk
                you through the entire process.
              </p>
              <p className="font-proximanova-light zeal-digital text-center text-lg-start">
                Call us at <a href="tel:+610296100019">+61 02 96 100 019</a> or{" "}
                <a href="mailto:info@topcabjoinery.com.au">
                  info@topcabjoinery.com.au
                </a>
                .
              </p>
              <a
                href="tel:+610296100019"
                className="text-center call-us-button text-capitalize text-decoration-none px-4 py-3"
              >
                Call Now
              </a>
            </div>
            <div className="d-grid ">
              <img
                src="/zeal-digital/wardrobe-coogee.jpg"
                className="bg-dark rounded-3 mx-auto d-block"
                alt="hero"
                style={{
                  width: "clamp(200px, 100%, 500px)",
                  aspectRatio: "5/4",
                }}
              />
            </div>
          </div>
        </section>
        <section
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          className="container text-white font-proximanova-light"
        >
          <h2 className="font-miracle text-center display-6 mb-4 text-capitalize">
            Areas we serve
          </h2>
          <div className="d-grid gap-4 mobile-grid-service-area">
            {areasWeServe?.map((elem) => (
              <Link
                to={elem.href}
                className="text-center service-area-button text-capitalize text-decoration-none px-4 py-2"
              >
                {elem.title}
              </Link>
            ))}
          </div>
        </section>
        <Form />
      </section>
    );
  };

  return useWindowSize() ? <Mobile /> : <Desktop />;
}
